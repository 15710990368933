import Handlebars from 'handlebars';
import $ from 'jquery';

import { Log, Strings } from '@biteinc/common';
import { StringHelper, TimeHelper } from '@biteinc/core-react';
import { CardEntryMethod, OrderClientEventName } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { GcnFulfillmentHelper } from '../../../../helpers';
import GcnHardwareManager from '../../gcn_hardware_manager';
import GcnHelper from '../../gcn_helper';
import GcnPrintJob from '../../gcn_print_job';
import Analytics from '../../utils/analytics';
import { GCNEmailReceiptView } from '../gcn_email_receipt_view';
import { GCNGuestSurveyView } from '../gcn_guest_survey_view';
import { GCNReceiptTextPhoneNumberView } from '../gcn_receipt_text_phone_number_view';
import { GCNSimpleLoyaltySignupView } from '../gcn_simple_loyalty_signup_view';
import { GCNFullScreenFlowStepView } from './gcn_full_screen_flow_step_view';

const kTimeoutAfterAction = 8000;
export const GCNPaymentCompletedStepView = GCNFullScreenFlowStepView.extend({
  className: 'full-screen-flow-step-view payment-completed',
  stepType: GCNFullScreenFlowStepView.StepType.PaymentCompleted,

  initialize(...args) {
    GCNFullScreenFlowStepView.prototype.initialize.apply(this, args);

    Analytics.trackEvent({
      eventName: Analytics.EventName.CheckoutComplete,
      eventData: {
        cartSize: gcn.orderManager.getOrderSize(),
        cartSubTotal: gcn.orderManager.getSubTotal(),
      },
    });
    gcn.orderManager.eventRepo.track(OrderClientEventName.CheckoutEnd);
    gcn.orderManager.commitEvents();
  },

  onShowTransitionEnd() {
    this.$checkImage.css('transform', 'scale(1.0) rotate(0deg)');
    this.$successTitle.css('opacity', '1.0');
    this.$successTitle.css('transform', 'translateX(0px)');
    this.$successImage.css('opacity', '1.0');
    this.$fulfillmentInstructions.css('opacity', '1.0');
    this.$successSubtitle.css('opacity', '1.0');
    this.$successTitle.css('transform', 'translateX(0px)');

    if (this.surveyView) {
      this.$surveyEl.css('transform', 'translateY(0px)');
      this.$surveyEl.css('opacity', '1.0');
      this.surveyView.animateIn();
    }
  },

  __renderHeader(title, subtitle) {
    this.$stepBody.append(`
      <div class="success-header">
        <div class="payment-success-title-container">
          <div class="payment-check-image"></div>
          <div class="payment-success-title font-body">${title}</div>
        </div>
        ${subtitle ? `<div class="payment-success-subtitle font-body">${subtitle}</div>` : ''}
      </div>
    `);
  },

  __renderRefreshButton() {
    if (gcn.screenReaderIsActive) {
      // Since we cannot switch focus to a native android view at the moment, we will need to hide
      // the refresh button and ask the user to replug their headphones to start a new order.
      this.$stepPanel.append(
        `<div style="text-align: center; padding-bottom: 50px">Please disconnect and reconnect your headphones to begin a new order.</div>`,
      );
      return;
    }

    const hasSessionTokenCards = !!gcn.orderManager.getStoredValueCards().find((card) => {
      return card.cardEntryMethod === CardEntryMethod.ProvidedExternally;
    });
    if (hasSessionTokenCards) {
      // sessionToken will likely not be valid at end of checkout flow, so do not refresh
      return;
    }
    this.$stepPanel.append(
      `<div class="refresh-button" data-testid="new-order-button">${localizeStr(
        Strings.START_NEW_ORDER,
      )}</div>`,
    );
    const $refreshButton = this.$stepPanel.find('.refresh-button');
    $refreshButton.onButtonTapOrHold('pcsvRefresh', () => {
      if (!this.parent || this.parent.hasGoneHome) {
        return;
      }
      Analytics.track(Analytics.EventName.CheckoutCompleteStartNewOrderTapped);
      // Remove any timeouts for goHome()
      this._cancelTimeout();
      this.parent.goHome();
    });
  },

  __renderTopContent() {},

  // Guard all calls to the parent, incase the view is destructing when these calls are made.
  _startTimeout(delayInMs) {
    this.parent?.startTimeout(delayInMs);
  },

  _restartTimeout() {
    this.parent?.restartTimeout();
  },

  _cancelTimeout() {
    this.parent?.cancelTimeout();
  },

  _submitSurveyResult(surveyValue) {
    this.parent?.submitSurveyResult(surveyValue);
  },

  _printButtonOnTap($printButton, order) {
    const self = this;
    if ($printButton.hasClass('disabled')) {
      return;
    }
    self._cancelTimeout();
    gcn.menuView.showSpinner(localizeStr(Strings.PRINTING_RECEIPT));

    const receiptBase64Images = GcnHelper.getReceiptBase64Images(gcn.location);

    const printPayload = {
      printJobs: [
        GcnPrintJob.receiptPrintJob(gcn.location, gcn.menu, order, { ...receiptBase64Images }),
      ],
    };
    gcn.requestToPrintReceipt(printPayload, (response) => {
      self._startTimeout(kTimeoutAfterAction);
      gcn.menuView.dismissSpinner();
      if (response.error) {
        gcn.menuView.showSimpleAlert(localizeStr(Strings.PRINT_RECEIPT_ERROR));
      } else {
        $printButton.addClass('disabled');
        $printButton.find('.text').htmlOrText(localizeStr(Strings.RECEIPT_PRINTED));
      }
    });
  },

  _showLoyaltySignup() {
    const loyaltySignUpView = new GCNSimpleLoyaltySignupView();
    gcn.menuView.showStablePopup(loyaltySignUpView, 'simple-loyalty-signup-view');
    this.listenTo(loyaltySignUpView, BackboneEvents.GCNNumberEntryView.ValueUpdated, () => {
      this._restartTimeout();
    });
    this.listenTo(
      loyaltySignUpView,
      BackboneEvents.GCNSimpleLoyaltySignupView.SmsInvitationWasSent,
      () => {
        gcn.menuView.dismissStablePopup();
        gcn.menuView.showSimpleAlert(localizeStr(Strings.SENT_LOYALTY_SIGNUP_SMS));
        this._restartTimeout();
      },
    );
  },

  renderLanguageChange() {
    GCNFullScreenFlowStepView.prototype.renderLanguageChange.apply(this, []);

    this.onShowTransitionEnd();
  },

  render(...args) {
    GCNFullScreenFlowStepView.prototype.render.apply(this, args);

    const fulfillmentInstructions = [];
    if (this.options.cardMethodWasDip) {
      fulfillmentInstructions.push(localizeStr(Strings.REMOVE_CARD));
    } else if (gcn.location.isDeliKioskDemo()) {
      fulfillmentInstructions.push(localizeStr(Strings.ORDER_WAS_PLACED));
    } else if (gcn.orderManager.payingAtCashier()) {
      fulfillmentInstructions.push(localizeStr(Strings.PAY_AT_CASHIER));
    }

    const order = gcn.orderManager.getOrder();
    const orderNumber = GcnFulfillmentHelper.getOrderNumber(gcn.location, order);

    let title = localizeStr(Strings.PAYMENT_SUCCESSFUL);
    if (!gcn.location.takesPayment() || gcn.orderManager.payingAtCashier()) {
      title = localizeStr(Strings.ORDER_SENT);
    }
    const subtitle = localizeStr(Strings.PAYMENT_SUCCESSFUL_SUBTITLE);

    this.__renderHeader(title, subtitle);
    this.__renderTopContent();
    this.$stepBody.append(
      `<div class="image-container">
        <div class="success-image"></div>
      </div>`,
    );

    // get fulfillment strings and labels
    const diningOption = gcn.location.getDiningOption(order.get('fulfillmentMethod'));
    const vehicleDescription = GcnFulfillmentHelper.getVehicleDescription(
      order.get('guestVehicle'),
    );
    const fulfillmentInfo = GcnFulfillmentHelper.getFulfillmentInfo(
      gcn.location,
      order,
      orderNumber,
      diningOption,
    );

    const { customerIdentifierLabel, customerIdentifierValue } = fulfillmentInfo;
    fulfillmentInstructions.push(...fulfillmentInfo.fulfillmentInstructions);

    const confirmationTemplate = diningOption?.successScreenFulfillmentTemplate;
    if (GcnHardwareManager.hasReceiptPrinter()) {
      fulfillmentInstructions.push(localizeStr(Strings.PRINTED_RECEIPT_COMMAND));
    }
    const { fullName, firstName, lastName } = StringHelper.getFirstAndLastNameFromFullName(
      order.get('guestName'),
    );

    const confirmationHtml = confirmationTemplate
      ? Handlebars.compile(confirmationTemplate)({
          GUEST_NAME: fullName,
          GUEST_FIRST_NAME: firstName,
          GUEST_LAST_NAME: lastName,
          GUEST_PHONE_NUMBER: order.get('guestPhoneNumber'),
          LOCATION_NAME: gcn.location.get('name'),
          LOCATION_PHONE_NUMBER: StringHelper.toFormattedPhoneNumber(
            gcn.location.get('phoneNumber') || '',
          ),
          ORDER_NUMBER: orderNumber,
          ORG_NAME: gcn.location.get('orgName'),
          TABLE_NUMBER: order.get('tableNumber'),
          VEHICLE_DESCRIPTION: vehicleDescription,
          OUTPOST_NAME: order.get('outpost')?.name,
          KIOSK_NAME: order.get('kioskName'),
        })
      : fulfillmentInstructions.join('<br />');
    this.$stepBody.append(
      `<div class="fulfillment-instructions-text font-body">${confirmationHtml}</div>`,
    );

    const pickupAtIso = gcn.orderManager.getPickupAtIso();
    if (pickupAtIso) {
      const orderTimeDescription = TimeHelper.getFriendlyFutureTimeDescription(pickupAtIso);
      const orderTimeNoticeText = `${localizeStr(
        Strings.FUTURE_ORDER_SET_FOR,
      )} ${orderTimeDescription}`;
      this.$stepBody.append(
        `<div class="future-order-time font-body">${orderTimeNoticeText}</div>`,
      );
    }
    if (customerIdentifierValue) {
      this.$stepBody.append(
        `<div class="fulfillment" role="heading" aria-level="1">
          <div class="details">${customerIdentifierLabel}:</div>
          <div class="number">${customerIdentifierValue}</div>
        </div>`,
      );
      this.__renderRefreshButton();
    }

    Log.info('getNextStepsImage', gcn.menu.getNextStepsImage());
    const nextStepsImage = gcn.menu.getNextStepsImage();
    if (nextStepsImage) {
      gcn.requestImageByUrl(nextStepsImage.url, (err, imgPath) => {
        if (err) {
          Log.error('Error loading nextStepsImage: ', err);
          return;
        }
        const $image = $('<div class="custom-image"></div>');
        $image.css('background-image', `url(${imgPath})`);
        this.$stepBody.append($image);
      });
    }

    this.$checkImage = this.$stepBody.find('.payment-check-image');
    this.$successTitle = this.$stepBody.find('.payment-success-title');
    this.$successSubtitle = this.$stepBody.find('.payment-success-subtitle');
    this.$successImage = this.$stepBody.find('.success-image');
    const successImage = gcn.menu.settings.get('paymentSuccessImage');
    if (successImage?.length) {
      gcn.requestImageByUrl(successImage[0].url, (err, imgPath) => {
        this.$successImage.css('background-image', `url(${imgPath})`);
      });
    } else {
      this.$successImage.addClass('empty');
    }
    this.$fulfillmentInstructions = this.$stepBody.find('.fulfillment-instructions-text');

    this.$checkImage.css('transform', 'scale(0.0) rotate(90deg)');
    this.$successTitle.css('opacity', '0.0');
    this.$successTitle.css('transform', 'translateX(-50px)');
    this.$successImage.css('opacity', '0.0');
    this.$fulfillmentInstructions.css('opacity', '0.0');
    this.$successSubtitle.css('opacity', '0.0');

    this.$('.button.prev').remove();
    this.$('.button.next').remove();

    const emailReceiptsEnabled = gcn.location.get('emailReceiptsEnabled');
    const textReceiptsEnabled = gcn.location.get('textReceiptsEnabled');
    const printReceiptEnabled =
      (gcn.location.isPrintingOptional() || gcn.location.showReprintButton()) &&
      GcnHardwareManager.hasReceiptPrinter();
    const hasExtraReceiptOptions =
      emailReceiptsEnabled || printReceiptEnabled || textReceiptsEnabled;
    if (gcn.menu.settings.get('showGuestSurvey')) {
      this.$buttonBar.addClass('showing-guest-survey');
      this.surveyView = new GCNGuestSurveyView({
        callback: (surveyValue) => {
          this._submitSurveyResult(surveyValue);
          if (!hasExtraReceiptOptions) {
            this._startTimeout(1500);
          }
        },
      });
      this.$surveyEl = this.surveyView.render().$el;
      this.$buttonBar.append(this.$surveyEl);
      this.$surveyEl.css(
        'transition',
        'transform 0.35s ease-in-out 0.45s, opacity 0.35s ease-in-out 0.45s',
      );
      this.$surveyEl.css('transform', 'translateY(32px)');
      this.$surveyEl.css('opacity', '0');
    }

    let defaultTimeoutValue = 20000;
    if (hasExtraReceiptOptions) {
      this.$buttonBar.addClass('showing-receipt-options');
      defaultTimeoutValue = 30000;
      const $receiptButtonsContainer = $('<div class="receipt-buttons-container"></div>');
      this.$buttonBar.prepend($receiptButtonsContainer);

      if (printReceiptEnabled) {
        const $printButton = $(`
          <div class="receipt-option print button">
            <div class="icon"></div>
            <div class="text">${localizeStr(Strings.PRINT_RECEIPT)}</div>
          </div>
        `);
        $receiptButtonsContainer.append($printButton);
        $printButton.onButtonTapOrHold('pcsvPrint', () => {
          Analytics.track(Analytics.EventName.CheckoutCompletePrintReceiptTapped);
          this._printButtonOnTap($printButton, order);
        });
      }

      if (emailReceiptsEnabled && !gcn.screenReaderIsActive) {
        const $emailButton = $(`
          <div class="receipt-option email button">
            <div class="icon"></div>
            <div class="text">${localizeStr(Strings.EMAIL_RECEIPT)}</div>
          </div>
        `);
        $receiptButtonsContainer.append($emailButton);
        $emailButton.onButtonTapOrHold('pcsvEmail', () => {
          const guestEmail = gcn.orderManager.getGuestEmail();
          this._cancelTimeout();
          gcn.menuView.showModalPopup(
            new GCNEmailReceiptView({
              email: guestEmail,
              callback: () => {
                gcn.menuView.dismissModalPopup();
                this._startTimeout(kTimeoutAfterAction);
              },
            }),
          );
        });
      }

      if (textReceiptsEnabled) {
        const $textButton = $(`
          <div class="receipt-option text button">
            <div class="icon"></div>
            <div class="text">${localizeStr(Strings.TEXT_RECEIPT)}</div>
          </div>
        `);
        $receiptButtonsContainer.append($textButton);
        $textButton.onButtonTapOrHold('pcsvText', () => {
          // const guestPhoneNumber = gcn.orderManager.getGuestPhoneNumber();
          this._cancelTimeout();
          gcn.menuView.showModalPopup(
            new GCNReceiptTextPhoneNumberView({
              callback: () => {
                this._startTimeout(kTimeoutAfterAction);
              },
            }),
          );
        });
      }
    }

    // Prefer configured value.
    defaultTimeoutValue = gcn.menu.settings.get('checkoutCompleteTimeout') || defaultTimeoutValue;

    if (this.options.canTimeout) {
      this._startTimeout(defaultTimeoutValue);
    }

    if (gcn.loyaltyManager.canShowLoyaltySignupOnPaymentCompleted()) {
      const $loyaltySignupBanner = $('<div class="loyalty-signup-banner"></div>');
      const simpleLoyaltySignupBannerImage = gcn.menu.settings.get(
        'simpleLoyaltySignupBannerImage',
      );

      if (simpleLoyaltySignupBannerImage?.length) {
        gcn.requestImageByUrl(simpleLoyaltySignupBannerImage[0].url, (err, imgPath) => {
          if (err) {
            Log.error('Error loading simpleLoyaltySignupBannerImage: ', err);
            return;
          }
          $loyaltySignupBanner.css('background-image', `url(${imgPath})`);
        });
      } else {
        // Change the display value of the banner to help center the label
        $loyaltySignupBanner.addClass('loyalty-signup-banner-fallback');
        $loyaltySignupBanner.append(
          '<div class="loyalty-signup-banner-fallback-label">Signup for Loyalty</div>',
        );
      }

      $loyaltySignupBanner.onButtonTapOrHold('pcsvLoyalty', () => {
        this._showLoyaltySignup();
      });

      this.$stepBody.append($loyaltySignupBanner);
    }

    if (gcn.loyaltyManager.canForceShowLoyaltySignupOnPaymentCompleted()) {
      this._showLoyaltySignup();
    }

    if (gcn.screenReaderIsActive) {
      gcn.showNativeToast(
        `${title}. Thank you for ordering. Your ${customerIdentifierLabel} is ${customerIdentifierValue}. ${this.$fulfillmentInstructions.text()}. Your ${customerIdentifierLabel} is ${customerIdentifierValue}. Please disconnect and reconnect your headphones to begin a new order.`,
      );
      this.$buttonBar.attr('aria-hidden', true);
      this.$buttonBar.css('visibility', 'hidden');
    }

    return this;
  },
});
