import * as Sentry from '@sentry/browser';
import _ from 'underscore';

import { ErrorCode, I9nSchemaBySystem, Log, Strings } from '@biteinc/common';
import type { Order, Transaction } from '@biteinc/core-react';
import { StringHelper, TimeHelper } from '@biteinc/core-react';
import type {
  CardEntryMethod,
  FulfillmentMethod,
  IntegrationSystem,
  RewardType,
} from '@biteinc/enums';
import {
  CardSchemeIdHelper,
  LoyaltyAuthEntryMethod,
  OrderSkipReason,
  TransactionType,
} from '@biteinc/enums';

import type { GcnMenuItem } from '~/types/gcn_menu_item';
import type { TimeoutConfig } from '~/types/request';

import type { GcnOrderedItem } from '../../types/gcn_ordered_item';
import type {
  FetchItemAndModGroupRecommendationsResponse,
  FetchRecommendationsResponse,
  GuestIdentifiers,
  Recommendation,
  RecommendationDisplayLocationDescription,
  RecommendationEngine,
  RecommendationEvent,
  RecommendationEventBody,
  RecommendationEventGuestAddToCartParams,
} from '../../types/recommendation';
import GcnHelper from './gcn_helper';
import type { ApiError, Callback, MaitredRequestMaker } from './gcn_maitred_request_manager';
import { ApiService, GcnMaitredRequestManager, RequestData } from './gcn_maitred_request_manager';
import { localizeStr } from './localization/localization';
import {
  getFirstLoadRecommendationsPayload,
  getItemRecommendationsPayload,
  getModGroupPluralRecommendationsPayload,
  getModGroupRecommendationsPayload,
  getPreCheckoutRecommendationsPayload,
  getRecommendationEventDisplayRecommendationsPayload,
  getRecommendationEventGuestAddToCartPayload,
  getRecommendationEventOrderReferencePayload,
  getSideCartViewRecommendationsPayload,
} from './recommendation_payload_builder';
import LocalStorage from './utils/local_storage';
import { sleep } from './utils/promises';

// keep in sync with orders_request_interfaces
type LoyaltyAuthCredentials = {
  authEntryMethod: LoyaltyAuthEntryMethod;
} & (
  | {
      email: string;
      password: string;
    }
  | {
      email: string;
      phone: string;
    }
  | {
      phone: string;
    }
  | {
      cardNumber: string;
    }
  | {
      username: string;
      password: string;
    }
);

// keep in sync with orders_request_interfaces
export type LoyaltyAuthBody = {
  loyaltyAuthCredentials: LoyaltyAuthCredentials;
};

// keep in sync with payments_request_interfaces
export type AuthData = {
  cardNumber?: string;
  giftCardPin?: string;
  cardEntryMethod?: CardEntryMethod;
  sessionToken?: string;
  terminalId?: string;
  posOrderId?: string;
};

// keep in sync with payments_request_interfaces
type StoredValueInquireBody = {
  authData: AuthData;
};

// keep in sync with payments_request_interfaces
type StoredValuePaymentBody = {
  orderId: string;
  // Worldpay API GC may have no auth data
  authData: AuthData | null;
  amount: number;
  clientId: string;
};

// keep in sync with payments_request_interfaces
type RefundPaymentBody = {
  amount: number;
  clientId: string;
  expectedRemainingAmount: number;
  accessCode?: string;
};

// keep in sync with orders_request_interfaces
type AddCouponBody = {
  couponCode: string;
};

// keep in sync with orders_request_interfaces
type RemoveCouponBody = {};

// keep in sync with shared_types/loyalty_reward
type LoyaltyReward = {
  name: string;
  description?: string;
  type: number;
  i9nId: string;
  amount?: number;
  i9nData?: any;
  canAutoApply?: boolean;
  index?: number;
};

// keep in sync with shared_types/loyalty_reward
type LoyaltyRewardV2 = {
  name: string;
  description?: string;
  type: RewardType;
  amount?: number;
  canAutoApply?: boolean;
  rewardI9nId?: string; // i9nId of the loyalty reward
  posIntegrationId?: any; // ObjectId pos integration the reward applies to
  integrationId?: any; // ObjectId loyalty integration the reward came from
  pointsUsed?: number; // used by: incentivio, paytronix
  pointsCode?: string | number; // used by: paytronix
  itemI9nId?: string; // item i9nId, used for item discounts
};

type LoyaltyDirectRewardV2 = LoyaltyRewardV2 & {
  posDiscountId: string;
};

// keep in sync with shared_types/loyalty_reward
export type LoyaltyAuthData = {
  authToken?: string;
  authEntryMethod?: LoyaltyAuthEntryMethod;
  tokenData?: string;
  tokenDataEntryMethod?: LoyaltyAuthEntryMethod;
  redemptionCode?: string;
  redemptionCodeEntryMethod?: LoyaltyAuthEntryMethod;
  customerId?: string;
  firstName?: string;
  lastName?: string;
};

// keep in sync with shared_types/comp_card_auth_data
type CompCardAuthData = Readonly<
  {
    cardEntryMethod: CardEntryMethod;
  } & (
    | {
        system: IntegrationSystem.PaytronixCompCard;
        cardNumber: string;
        cardPin?: string;
      }
    | {
        system: Exclude<IntegrationSystem.CompCard, IntegrationSystem.PaytronixCompCard>;
      }
  )
>;

// keep in sync with orders_request_interfaces
type LoyaltyAvailableRewardsBody = {
  loyaltyAuthData: LoyaltyAuthData;
};

// keep in sync with orders_request_interfaces
type LoyaltyAddRewardBodyV2 = {
  loyaltyAuthData: LoyaltyAuthData;
  reward: LoyaltyRewardV2 | LoyaltyDirectRewardV2;
};

// keep in sync with orders_request_interfaces
type CompCardApplyBody = {
  authData: CompCardAuthData;
};

// keep in sync with orders_request_interfaces
type LoyaltyRemoveRewardBody = {
  loyaltyAuthData: LoyaltyAuthData;
  rewardI9nId: string;
  transactionId?: string;
};

// keep in sync with recommendation_types
export type RecommendationEventPayload<Body extends RecommendationEventBody> = {
  event: RecommendationEvent<Body>;
};

// keep in sync with recommendations_request_interfaces

type MaitredApiError = Error & {
  code: number;
};

export type CloseOrderResponse = {
  order: Order;
  successfulTransactions: Transaction[];
  queuePath?: string;
};

export type ValidateOrderResponse = {
  queuePath: string;
};

export type ValidateOrderStatusResponse = {
  order: Order;
};

export type CreateOrderResponse = {
  order: Order;
};

export type KioskApiPaymentResponse = {
  order: Order;
  transaction: Transaction;
  queuePath?: string;
};
export type KioskApiPaymentCancelResponse = {
  success: true;
};

export type PreReadCardResponse = {
  success: boolean;
  queuePath?: string;
};

type Slot = Readonly<{
  timestamp: string;
  available: boolean;
}>;

export type GetOrderResponse = {
  order: Order;
  successfulTransactions: Transaction[];
};

export type KdsJob = {
  kds: {
    ipAddress: string;
    port: string;
  };
  payload: string;
};

function getCreateOrderCopy(orderJson: any): any {
  return {
    integrationById: {},
    dataForVendors: [],
    ...orderJson,
  };
}

function getValidateOrderCopy(orderJson: any): any {
  const taxTotal = Math.round(orderJson.subTotal * 0.1);
  return {
    integrationById: {},
    dataForVendors: [],
    ...orderJson,
    taxTotal,
    total:
      orderJson.subTotal +
      taxTotal +
      (orderJson.discountTotal || 0) +
      (orderJson.serviceChargeTotal || 0) +
      (orderJson.tipTotal || 0),
    wasValidated: Date.now(),
    validationWasSkipped: OrderSkipReason.OfflineDemoMode,
  };
}

export default class GcnMaitredClient implements RecommendationEngine {
  private requestManager: GcnMaitredRequestManager;

  private storedValuePaymentTimedOutTransactionClientId: string | undefined;

  private ecommPaymentTimedOutTransactionClientId: string | undefined;

  private kioskApiPaymentTimedOutTransactionClientId: string | undefined;

  private storedValuePaymentTimedOutPayloadStr: string | undefined;

  private ecommPaymentTimedOutPayloadStr: string | undefined;

  private kioskApiPaymentTimedOutPayloadStr: string | undefined;

  private preReadCardRequestClientId: string | undefined;

  private preReadCardSuccessful: boolean;

  allowPayAtCashier(): boolean {
    if (!gcn.location.get('allowPayAtCashier')) {
      return false;
    }

    if (!gcn.location.get('enablePreRead')) {
      return true;
    }

    return !this.cardWasPreRead();
  }

  cardWasPreRead(): boolean {
    return this.preReadCardSuccessful;
  }

  setRequestMaker(requestMaker: MaitredRequestMaker): void {
    this.requestManager = new GcnMaitredRequestManager(requestMaker);
  }

  clear(): void {
    if (this.requestManager) {
      this.requestManager.removeAllRequests();
    }

    this.preReadCardRequestClientId = undefined;
    this.preReadCardSuccessful = false;
    this.resetPaymentTimedOutProperties();
  }

  resetPaymentTimedOutProperties(): void {
    this.storedValuePaymentTimedOutTransactionClientId = undefined;
    this.ecommPaymentTimedOutTransactionClientId = undefined;
    this.kioskApiPaymentTimedOutTransactionClientId = undefined;

    this.storedValuePaymentTimedOutPayloadStr = undefined;
    this.ecommPaymentTimedOutPayloadStr = undefined;
    this.kioskApiPaymentTimedOutPayloadStr = undefined;
  }

  // **********
  // GIFT CARDS
  // **********
  async giftCardInquire<T extends AuthData>(
    authData: T,
  ): Promise<{ authData: T; balance: number }> {
    if (window.offlineDemoMode || !this.requestManager) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            authData,
            balance: 10000,
          });
        }, 1000);
      });
    }

    const posOrderId = gcn.orderManager.getPosOrderId();
    const authDataWithPosOrderId: T & { posOrderId?: string } = {
      ...authData,
      ...(posOrderId && { posOrderId }),
    };
    if (!gcn.location.useOrdersApiV2()) {
      const request = RequestData.newPostRequest(
        ApiService.Maitred,
        '/giftCards/inquire',
        authDataWithPosOrderId,
      );
      return this.requestManager.makeRequestAsync(request);
    }

    return this.storedValueInquireRequest(authDataWithPosOrderId);
  }

  async giftCardCapture(authData: AuthData | null, amount: number): Promise<any> {
    if (window.offlineDemoMode || !this.requestManager) {
      setTimeout(() => {
        const stubTransaction = {
          amount,
          maskPan: authData?.cardNumber,
          last4: authData?.cardNumber!.slice(-4),
        };

        return {
          ...gcn.orderManager.getCreateOrderPayload(),
          transaction: stubTransaction,
          successfulTransactions: [stubTransaction],
        };
      }, 1000);
      return;
    }

    const posOrderId = gcn.orderManager.getPosOrderId();
    const authDataWithPosOrderId: AuthData = {
      ...(authData || {}),
      ...(posOrderId && { posOrderId }),
    };

    if (!gcn.location.useOrdersApiV2()) {
      const orderPayload = gcn.orderManager.getOrderPayload();
      const request = RequestData.newPostRequest(ApiService.Maitred, '/giftCards/capture', {
        ...orderPayload,
        amount,
        authData: Object.keys(authDataWithPosOrderId).length ? authDataWithPosOrderId : null,
      });
      request.setTimeout(30 * TimeHelper.SECOND);
      return this.requestManager.makeRequestAsync(request);
    }

    // idempotency on request level
    const clientId = StringHelper.newMongoId();
    try {
      return await this.createStoredValuePaymentRequest(amount, authDataWithPosOrderId, clientId);
    } catch (err) {
      if (err.code === ErrorCode.PaymentRemainingAmountOverpay) {
        // handle case where creating a payment succeeds but gcn never gets the response
        // idempotency on the request level won't work because UI is used to capture again
        try {
          return await gcn.maitred.getOrderRequest(gcn.orderManager.getOrderId());
        } catch (_err) {
          // prefer original error
          throw err;
        }
      }

      throw err;
    }
  }

  async giftCardCaptureInQueue(authData: AuthData | null, amount: number): Promise<any> {
    if (window.offlineDemoMode || !this.requestManager) {
      setTimeout(() => {
        const stubTransaction = {
          amount,
          maskPan: authData?.cardNumber,
          last4: authData?.cardNumber!.slice(-4),
        };

        return {
          ...gcn.orderManager.getCreateOrderPayload(),
          transaction: stubTransaction,
          successfulTransactions: [stubTransaction],
        };
      }, TimeHelper.SECOND);
      return;
    }

    const posOrderId = gcn.orderManager.getPosOrderId();
    const authDataWithPosOrderId: AuthData = {
      ...(authData || {}),
      ...(posOrderId && { posOrderId }),
    };

    if (!gcn.location.useOrdersApiV2()) {
      const orderPayload = gcn.orderManager.getOrderPayload();
      const request = RequestData.newPostRequest(ApiService.Maitred, '/giftCards/capture', {
        ...orderPayload,
        amount,
        authData: Object.keys(authDataWithPosOrderId).length ? authDataWithPosOrderId : null,
      });
      request.setTimeout(30 * TimeHelper.SECOND);
      return this.requestManager.makeRequestAsync(request);
    }

    // idempotency on request level
    const clientId = StringHelper.newMongoId();
    try {
      return await this.createStoredValuePaymentQueuedRequest(
        amount,
        authDataWithPosOrderId,
        clientId,
      );
    } catch (err) {
      if (err.code === ErrorCode.PaymentRemainingAmountOverpay) {
        // handle case where creating a payment succeeds but gcn never gets the response
        // idempotency on the request level won't work because UI is used to capture again
        try {
          return await gcn.maitred.getOrderRequest(gcn.orderManager.getOrderId());
        } catch (_err) {
          // prefer original error
          throw err;
        }
      }

      throw err;
    }
  }

  giftCardRefund(
    amount: number,
    orderPayload: any,
    rewardI9nId: string,
    transactionId: string,
    clientId: string,
    callback: Callback,
  ): void {
    if (!gcn.location.useOrdersApiV2()) {
      const request = RequestData.newPostRequest(ApiService.Maitred, '/giftCards/refund', {
        ...orderPayload,
        rewardI9nId,
        transactionId,
      });
      this.requestManager.makeRequest(request, callback);
      return;
    }

    // all gcn gift card refunds are for the full amount
    const expectedRemainingAmount = amount;
    this.refundRequest(
      transactionId,
      amount,
      expectedRemainingAmount,
      clientId,
      undefined,
      callback,
    );
  }

  // ***********
  //   LOYALTY
  // ***********
  private shouldFakeRewards(): boolean {
    return window.offlineDemoMode || !this.requestManager || gcn.location.showRewardsDemo();
  }

  authLoyalty(authCredentials: LoyaltyAuthCredentials, callback: Callback): void {
    if (this.shouldFakeRewards()) {
      Log.debug('auth with No Bridge', authCredentials);
      // callback(/* 'Test error message' */);
      callback(undefined, {
        loyaltyAuthData: {
          authToken: '__authTokenNoBridge__',
          authEntryMethod: LoyaltyAuthEntryMethod.UnknownManuallyEntered,
        },
      });
    } else {
      if (!gcn.location.useOrdersApiV2()) {
        const request = RequestData.newPostRequest(ApiService.LoyaltyApiV1, '/loyalty/auth', {
          authCredentials,
        });
        this.requestManager.makeRequest(request, (err, res) => {
          if (err) {
            callback(err);
            return;
          }
          const { authData } = res;
          callback(undefined, {
            loyaltyAuthData: authData,
          });
        });
        return;
      }
      this.loyaltyAuthRequest(authCredentials, callback);
    }
  }

  getRewards(orderPayload: any, callback: Callback): void {
    if (this.shouldFakeRewards()) {
      Log.debug('getRewards with no bridge');
      setTimeout(() => {
        // Might test with something like:
        // callback('User not authed.', null);
        callback(undefined, {
          guestName: 'George',
          balance: 1000,
          statusDescription: '1000 points',
          pointsBalance: '1000',
          rewards: [
            {
              name: '$2 off $7',
              amount: 200,
              type: 1, // fixed
              i9nId: 'olo2off7',
            },
            {
              name: '10% off',
              amount: 300,
              type: 0, // percent
              i9nId: 'XMAS10',
            },
          ],
          order: getValidateOrderCopy(orderPayload.order),
        });
      }, 1000);
    } else {
      if (!gcn.location.useOrdersApiV2()) {
        const request = RequestData.newPostRequest(
          ApiService.LoyaltyApiV1,
          '/loyalty/availableRewards',
          orderPayload,
        );
        this.requestManager.makeRequest(request, callback);
        return;
      }
      this.loyaltyAvailableRewardsRequest(callback);
    }
  }

  applyReward(reward: any, orderPayload: any, callback: Callback): void {
    if (this.shouldFakeRewards()) {
      setTimeout(() => {
        const orderCopy = getValidateOrderCopy(orderPayload.order);
        callback(undefined, { order: orderCopy });
      }, 1000);
    } else {
      if (!gcn.location.useOrdersApiV2()) {
        const request = RequestData.newPostRequest(ApiService.LoyaltyApiV1, '/loyalty/rewards', {
          ...orderPayload,
          rewards: [reward.toJSON()],
        });
        request.setTimeout(30 * TimeHelper.SECOND);
        this.requestManager.makeRequest(request, callback);
        return;
      }
      this.loyaltyAddRewardRequest(reward.toJSON(), callback);
    }
  }

  removeReward(rewardI9nId: string, transaction: any, orderPayload: any, callback: Callback): void {
    if (this.requestManager) {
      if (!gcn.location.useOrdersApiV2()) {
        const request = RequestData.newDeleteRequest(ApiService.LoyaltyApiV1, '/loyalty/rewards', {
          ...orderPayload,
          rewardI9nId,
          ...(transaction && { transactionId: transaction.id }),
        });
        request.setTimeout(30 * TimeHelper.SECOND);
        this.requestManager.makeRequest(request, callback);
        return;
      }
      this.loyaltyRemoveRewardRequest(rewardI9nId, transaction?.id, callback);
    } else {
      setTimeout(() => {
        const orderCopy = getValidateOrderCopy(orderPayload.order);
        callback(undefined, { order: orderCopy });
      }, 1000);
    }
  }

  applyCompCard(compCardAuthData: CompCardAuthData, orderPayload: any): Promise<GetOrderResponse> {
    if (this.shouldFakeRewards()) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const orderCopy = getValidateOrderCopy(orderPayload.order);
          resolve({
            order: orderCopy,
            successfulTransactions: [],
          });
        }, 1000);
      });
    }

    return this.applyCompCardRequest(compCardAuthData);
  }

  removeCompCard(orderPayload: any): Promise<GetOrderResponse> {
    if (this.shouldFakeRewards()) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const orderCopy = getValidateOrderCopy(orderPayload.order);
          resolve({
            order: orderCopy,
            successfulTransactions: [],
          });
        }, 1000);
      });
    }

    return this.removeCompCardRequest();
  }

  sendSimpleSignUpMessage(guestPhoneNumber: string, callback: Callback): void {
    if (!gcn.location.useOrdersApiV2()) {
      const request = RequestData.newPostRequest(ApiService.LoyaltyApiV1, '/loyalty/signup', {
        guestPhoneNumber,
      });
      this.requestManager.makeRequest(request, callback);
      return;
    }
    const url = '/api/v2/orders/loyalty/signup';
    const request = RequestData.newPostRequest(ApiService.LoyaltyApiV2, url, {
      guestPhoneNumber,
    });
    this.requestManager.makeRequest(request, callback);
  }

  sendOrderReceiptText(order: any, guestPhoneNumber: string, callback: Callback): void {
    const url = `/api/v2/orders/${order.id}/send-text-receipt`;
    const request = RequestData.newPostRequest(ApiService.Maitred, url, {
      guestPhoneNumber,
    });
    this.requestManager.makeRequest(request, (err, data) => {
      this.captureOrderContextOnKioskException(request, err);
      callback(err, data);
    });
  }

  // ***********
  //   COUPONS
  // ***********
  applyCoupon(couponCode: string, orderPayload: any, callback: Callback): void {
    if (!gcn.location.useOrdersApiV2()) {
      if (this.requestManager) {
        const request = RequestData.newPostRequest(ApiService.LoyaltyApiV1, '/loyalty/coupon', {
          ...orderPayload,
          couponCode,
        });
        request.setTimeout(30 * TimeHelper.SECOND);
        this.requestManager.makeRequest(request, callback);
      } else {
        setTimeout(() => {
          const orderCopy = getValidateOrderCopy(orderPayload.order);
          // Might test with something like:
          // callback('Coupon does not exist.', null);
          callback(undefined, { order: orderCopy });
        }, 1000);
      }
    } else {
      this.addCouponRequest(couponCode, callback);
    }
  }

  removeCoupon(orderPayload: any, callback: Callback): void {
    if (!gcn.location.useOrdersApiV2()) {
      if (this.requestManager) {
        const request = RequestData.newDeleteRequest(
          ApiService.LoyaltyApiV1,
          '/loyalty/coupon',
          orderPayload,
        );
        request.setTimeout(30 * TimeHelper.SECOND);
        this.requestManager.makeRequest(request, callback);
      } else {
        setTimeout(() => {
          const orderCopy = getValidateOrderCopy(orderPayload.order);
          // Might test with something like:
          // callback('Coupon could not be removed.', null);
          callback(undefined, { order: orderCopy });
        }, 1000);
      }
    } else {
      this.removeCouponRequest(callback);
    }
  }

  // **********
  //    MISC
  // **********
  forgetGuest(guestId: string, callback: Callback): void {
    if (this.requestManager) {
      const request = RequestData.newPostRequest(
        ApiService.Maitred,
        `/api/v2/guests/${guestId}/opt-out`,
      );
      this.requestManager.makeRequest(request, callback);
    }
  }

  cancelOrder(orderId: string, accessCode: string, callback: Callback): void {
    const url = `/api/v2/orders/${orderId}/cancel`;
    const request = RequestData.newPutRequest(ApiService.OrdersApiV2, url, {
      accessCode,
    });
    this.requestManager.makeRequest(request, callback);
  }

  refundTransaction(
    transaction: any,
    amount: number,
    accessCode: string,
    callback: Callback,
  ): void {
    const path = `/orders/${transaction.get('orderId')}/transactions/${transaction.id}/refund`;
    const request = RequestData.newPostRequest(ApiService.OrdersApiV1, path, {
      accessCode,
      amount,
    });
    this.requestManager.makeQueuedRequest(request, callback);
  }

  searchForOrders(searchTerm: string, accessCode: string, callback: Callback): void {
    const queryParamString = GcnHelper.queryString({
      query: searchTerm,
      accessCode,
    });
    const request = RequestData.newGetRequest(
      ApiService.OrdersApiV1,
      `/orders/search${queryParamString}`,
    );
    this.requestManager.makeRequest(request, callback);
  }

  sendEmailReceipt(
    order: any,
    email: string,
    /** Undefined if marketing consent checkboxes are not displayed for this location */
    guestConsentedToMarketing: boolean | undefined,
    callback: Callback,
  ): void {
    if (this.requestManager) {
      const basePath = order.id
        ? `/orders/${order.id}`
        : `/orderByClientId/${order.get('clientId')}`;
      const request = RequestData.newPostRequest(
        ApiService.OrdersApiV1,
        `${basePath}/emailReceipt`,
        {
          email,
          // check if it's a boolean instead of truthy because user could revoke consent
          ...(typeof guestConsentedToMarketing === 'boolean' && {
            guestConsentedToMarketing,
          }),
        },
      );
      this.requestManager.makeRequest(request, callback);
    } else {
      setTimeout(callback, 2000);
    }
  }

  submitGuestSurvey(order: any, surveyValue: number): void {
    // best effort
    if (this.requestManager) {
      const basePath = order.id
        ? `/orders/${order.id}`
        : `/orderByClientId/${order.get('clientId')}`;
      const request = RequestData.newPostRequest(
        ApiService.OrdersApiV1,
        `${basePath}/guestSurvey`,
        {
          surveyValue,
        },
      );
      this.requestManager.makeRequest(request);
    }
  }

  submitGuestSurveyWithId(orderId: string, surveyValue: number, callback?: Callback): void {
    // best effort
    if (this.requestManager) {
      const request = RequestData.newPostRequest(
        ApiService.OrdersApiV1,
        `/orders/${orderId}/guestSurvey`,
        {
          surveyValue,
        },
      );
      this.requestManager.makeRequest(request, callback);
    }
  }

  async updateOrder(orderPayload: object): Promise<void> {
    // best effort
    if (window.offlineDemoMode || !this.requestManager) {
      Log.debug('Updating order', orderPayload);
      return;
    }

    if (!gcn.location.useOrdersApiV2()) {
      const orderId = gcn.orderManager.getOrderId();
      if (this.requestManager) {
        const request = RequestData.newPutRequest(
          ApiService.OrdersApiV1,
          `/orders/${orderId}`,
          orderPayload,
        );
        await this.requestManager.makeRequestAsync(request);
      }
      return;
    }
    await this.updateOrderRequest({
      order: orderPayload,
    });
  }

  private captureOrderContextOnKioskException(
    request: RequestData,
    err: ApiError | undefined,
  ): void {
    if (
      err &&
      [ErrorCode.OrderNotMostRecentOnKiosk, ErrorCode.RequestDataValidation].includes(err.code)
    ) {
      Sentry.captureException(err, {
        extra: {
          request: request.toJSON(),
          order: gcn.orderManager.getOrder()?.toJSON() ?? null,
          transactions:
            gcn.orderManager.getTransactions()?.map((transaction) => {
              return transaction.toJSON();
            }) ?? [],
          loyaltyTransaction: gcn.loyaltyManager.getLoyaltyTransaction()?.toJSON() ?? null,
        },
      });
    }
  }

  private processOrderOperationResponse(
    err: ApiError | undefined,
    data: any,
    callback: Callback,
  ): void {
    if (err) {
      if (err.code === ErrorCode.NetworkRequestTimedOut && gcn.location.useOrdersApiV2()) {
        gcn.menuView.setSpinnerMessage(localizeStr(Strings.TAKING_LONGER_THAN_USUAL));

        this.getOrderRequestAsync(gcn.orderManager.getOrderId(), (getOrderErr, getOrderData) => {
          if (getOrderErr || getOrderData.order.lockExpiresAt) {
            gcn.checkoutFlowView!.exitCheckoutFlowDueToTimeout();
            return;
          }

          callback(undefined, getOrderData);
        });
        return;
      }

      callback(err);
      return;
    }

    callback(undefined, data);
  }

  // **********
  // VALIDATION
  // **********
  /**
   * @param timeout V1 only
   */
  createOrder(orderPayload: any, timeout: number, callback: Callback<CreateOrderResponse>): void {
    if (window.offlineDemoMode || !this.requestManager) {
      let orderCopy: any;
      if (gcn.location.useOrdersApiV2()) {
        Log.debug('will create order');
        orderCopy = getCreateOrderCopy(orderPayload.order);
      } else {
        Log.debug('will create and validate order');
        orderCopy = getValidateOrderCopy(orderPayload.order);
      }

      setTimeout(() => {
        // Might test with something like:
        // { message: 'Custom Fail Msg', code: ErrorCode.NetworkError }
        callback(undefined, { order: orderCopy });
      }, 500);
      return;
    }

    if (!gcn.location.useOrdersApiV2()) {
      Log.debug('will create and validate order');
      const request = RequestData.newPostRequest(
        ApiService.OrdersApiV1,
        '/orders/validate',
        orderPayload,
      );
      request.setTimeout(timeout);
      this.requestManager.makeRequest(request, callback);
    } else {
      Log.debug('will create order');
      this.createOrderRequest(callback);
    }
  }

  private async validateOrderGetQueuePathRequest(
    orderId: string,
  ): Promise<{ request: RequestData; validateOrderResponse: ValidateOrderResponse }> {
    const url = `/api/v2/orders/${orderId}/validate`;
    const request = RequestData.newPutRequest(ApiService.OrdersApiV2, url, {});
    try {
      const initialRequest =
        await this.requestManager.makeRequestAsync<ValidateOrderResponse>(request);

      Sentry.addBreadcrumb({
        category: 'validate-order-debug',
        message: 'validateOrderGetQueuePathRequest',
        level: 'info',
        data: {
          orderId,
          initialRequest,
        },
      });

      return { request, validateOrderResponse: initialRequest };
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  async validateOrder(orderId: string): Promise<{ order: Order }> {
    Log.debug('will validate order');

    if (window.offlineDemoMode || !this.requestManager) {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(null);
        }, 500);
      });

      const orderPayload = gcn.orderManager.getOrderPayload();
      const orderCopy = getValidateOrderCopy(orderPayload.order);

      return { order: orderCopy };
    }

    const validateStartAt = Date.now();

    const { request, validateOrderResponse } = await this.validateOrderGetQueuePathRequest(orderId);
    Sentry.addBreadcrumb({
      category: 'validate-order-debug',
      message: 'validateOrder request',
      level: 'info',
    });

    try {
      const response = await this.requestManager.waitForQueuedJob<ValidateOrderStatusResponse>(
        request.apiService,
        validateOrderResponse.queuePath,
        {
          timeout: gcn.location.get('orderValidateTimeout'),
          message: 'Validate order UI Timeout',
          code: ErrorCode.ValidateOrderUITimeout,
          startedAt: Date.now(),
        },
      );
      Sentry.addBreadcrumb({
        category: 'validate-order-debug',
        message: 'validateOrder waitForOrderToValidate response',
        level: 'info',
        data: {
          response,
        },
      });
      const { order: _order } = response;
      return { order: _order };
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'validate-order-debug',
        message: 'validateOrder error block',
        level: 'info',
        data: {
          err,
        },
      });
      // the job may have completed and has been removed from the queue
      if (err.code !== ErrorCode.ApiJobNotFound) {
        throw err;
      }

      const { order: _order } = await gcn.maitred.getOrderRequest(gcn.orderManager.getOrderId());

      if ((_order.wasValidated || 0) < validateStartAt) {
        // if an order is not in the queue, check to make sure it's been validated sometime after
        // this call was made
        throw new Error('Order not validated');
      }
      return { order: _order };
    }
  }

  validateTip(orderPayload: any, callback: Callback): void {
    if (this.requestManager) {
      if (!gcn.location.useOrdersApiV2()) {
        const request = RequestData.newPostRequest(
          ApiService.OrdersApiV1,
          '/orders/validateTip',
          orderPayload,
        );
        request.setTimeout(30 * TimeHelper.SECOND);
        this.requestManager.makeRequest(request, callback);
      } else {
        this.updateTipRequest(callback);
      }
    } else {
      setTimeout(() => {
        const orderCopy = getValidateOrderCopy(orderPayload.order);
        // Might test with something like:
        // { message: 'Custom Fail Msg', code: ErrorCode.NetworkError }
        callback(undefined, { order: orderCopy });
      }, 1000);
    }
  }

  // **********
  // FLASH/GARCON ONLY
  // **********
  fetchMenu(
    time: number,
    fulfillmentMethod: FulfillmentMethod | undefined,
    outpostId: string | undefined,
    forcedMenuStructureId: string | undefined,
    callback: Callback,
  ): void {
    const request = RequestData.newGetRequest(ApiService.Maitred, '/menu');
    request.qs = {
      ...(time && { time }),
      ...(fulfillmentMethod && { fulfillmentMethod }),
      ...(outpostId && { outpostId }),
      ...(forcedMenuStructureId && { forcedMenuStructureId }),
    };
    this.requestManager.makeRequest(request, callback);
  }

  fetchKiosk(kioskId: string, callback: Callback): void {
    const request = RequestData.newGetRequest(ApiService.Maitred, `/api/v2/kiosks/${kioskId}`);
    this.requestManager.makeRequest(request, callback);
  }

  fetchFutureOrderSlots(
    fulfillmentMethod: FulfillmentMethod,
    outpostId: string | undefined,
    orderedItemsLeadTime: number | undefined | null,
    callback: Callback<Record<string, Slot[]>>,
  ): void {
    // TODO: apply for kiosk v2 as well once maitred accepts specific headers from gcn
    // https://getbite.atlassian.net/browse/BITE-2282
    const useV2Api = (window.isFlash || window.isGarcon) && gcn.location.get('useOrdersApiV2Flash');
    const path = useV2Api
      ? `/api/v2/locations/${gcn.location.id}/future-order-slots`
      : '/future-order-slots';
    const request = RequestData.newGetRequest(ApiService.Maitred, path);
    request.qs = {
      fulfillmentMethod,
      ...(outpostId && { outpostId }),
      ...(orderedItemsLeadTime && { orderedItemsLeadTime }),
    };
    this.requestManager.makeRequest(request, (err, response) => {
      if (err) {
        callback(err);
        return;
      }

      if (!useV2Api) {
        callback(err, response);
        return;
      }

      const slotsByDay: Record<string, Slot[]> = {};
      (response.futureOrderSlots as { calendarDay: string; slots: Slot[] }[]).forEach(
        ({ calendarDay, slots }) => {
          slotsByDay[calendarDay] = slots;
        },
      );
      callback(err, slotsByDay);
    });
  }

  fetchGuest(guestId: string, callback: Callback): void {
    const request = RequestData.newGetRequest(ApiService.Maitred, `/api/v2/guests/${guestId}`);
    this.requestManager.makeRequest(request, callback);
  }

  fetchGuestByIdentifiers({ guestPhoneNumber }: GuestIdentifiers, callback: Callback): void {
    const request = RequestData.newGetRequest(ApiService.Maitred, '/api/v2/guests/find');
    request.qs = {
      phoneNumber: guestPhoneNumber,
    };
    this.requestManager.makeRequest(request, callback);
  }

  async fetchFirstLoadRecommendations(
    menuStructureId: string,
    guestIdentifiers: GuestIdentifiers,
    cartOrderedItems: GcnOrderedItem[],
  ): Promise<FetchRecommendationsResponse> {
    const apiService = gcn.location.shouldSendRecommendationsRequestToGateway()
      ? ApiService.RecommendationsApi
      : ApiService.RecommendationsApiMaitred;
    const url = '/api/v2/recommendations/session';
    const payload = getFirstLoadRecommendationsPayload(
      menuStructureId,
      guestIdentifiers,
      cartOrderedItems,
    );
    const request = RequestData.newPutRequest(apiService, url, payload);
    return this.requestManager.makeRequestAsync(request);
  }

  async fetchModGroupRecommendations(
    menuStructureId: string,
    selectedOrderedItem: GcnMenuItem,
    modGroupId: string,
    virtualSubGroupId?: string,
  ): Promise<FetchRecommendationsResponse> {
    const apiService = gcn.location.shouldSendRecommendationsRequestToGateway()
      ? ApiService.RecommendationsApi
      : ApiService.RecommendationsApiMaitred;
    const url = '/api/v2/recommendations/mod-group';
    const payload = getModGroupRecommendationsPayload(
      menuStructureId,
      selectedOrderedItem,
      modGroupId,
      virtualSubGroupId,
    );

    const request = RequestData.newPutRequest(apiService, url, payload);
    return this.requestManager.makeRequestAsync(request);
  }

  async fetchBatchedRecommendations(
    recommendationVariationId: string,
    guestIdentifiers: GuestIdentifiers,
    selectedOrderedItem: GcnOrderedItem,
    cartOrderedItems: GcnOrderedItem[],
    modGroupIds: string[],
    virtualSubGroupIds: (string | undefined)[],
  ): Promise<FetchItemAndModGroupRecommendationsResponse> {
    const apiService = gcn.location.shouldSendRecommendationsRequestToGateway()
      ? ApiService.RecommendationsApi
      : ApiService.RecommendationsApiMaitred;
    const url = '/api/v2/recommendations/item-and-mods';
    const payload = getModGroupPluralRecommendationsPayload(
      guestIdentifiers,
      selectedOrderedItem,
      cartOrderedItems,
      modGroupIds,
      virtualSubGroupIds,
      recommendationVariationId,
    );

    const request = RequestData.newPutRequest(apiService, url, payload);
    return this.requestManager.makeRequestAsync(request);
  }

  async sendRecommendationEvent(
    payload: RecommendationEventPayload<RecommendationEventBody>,
  ): Promise<void> {
    const apiService = gcn.location.shouldSendRecommendationsRequestToGateway()
      ? ApiService.RecommendationsApi
      : ApiService.RecommendationsApiMaitred;
    const url = '/api/v2/recommendations/events';
    const request = RequestData.newPostRequest(apiService, url, payload);
    // best effort
    try {
      await this.requestManager.makeRequestAsync(request);
    } catch (err) {
      Log.error('sendRecommendationEvent err', err);
    }
  }

  async sendRecommendationEventGuestAddToCart(
    params: RecommendationEventGuestAddToCartParams,
  ): Promise<void> {
    const eventPayload = getRecommendationEventGuestAddToCartPayload(params);
    return this.sendRecommendationEvent(eventPayload);
  }

  async sendRecommendationEventDisplayRecommendations(
    displayLocationDescription: RecommendationDisplayLocationDescription,
    recommendations: Recommendation[],
    recommendationResultId: string,
    displayContext?: Record<string, any>,
  ): Promise<void> {
    const eventPayload = getRecommendationEventDisplayRecommendationsPayload(
      displayLocationDescription,
      recommendations,
      recommendationResultId,
      displayContext,
    );
    return this.sendRecommendationEvent(eventPayload);
  }

  async sendRecommendationEventOrderReference(
    recommendationResultId: string,
    orderId: string,
  ): Promise<void> {
    const eventPayload = getRecommendationEventOrderReferencePayload(
      recommendationResultId,
      orderId,
    );
    return this.sendRecommendationEvent(eventPayload);
  }

  async fetchItemRecommendations(
    guestIdentifiers: GuestIdentifiers,
    selectedOrderedItem: GcnOrderedItem,
    cartOrderedItems: GcnOrderedItem[],
    recommendationVariationId?: string,
  ): Promise<FetchRecommendationsResponse> {
    const apiService = gcn.location.shouldSendRecommendationsRequestToGateway()
      ? ApiService.RecommendationsApi
      : ApiService.RecommendationsApiMaitred;
    const url = '/api/v2/recommendations/session';
    const payload = getItemRecommendationsPayload(
      guestIdentifiers,
      selectedOrderedItem,
      cartOrderedItems,
      recommendationVariationId,
    );
    const request = RequestData.newPutRequest(apiService, url, payload);
    return this.requestManager.makeRequestAsync(request);
  }

  async fetchPreCheckoutRecommendations(
    guestIdentifiers: GuestIdentifiers,
    cartOrderedItems: GcnOrderedItem[],
    recommendationVariationId?: string,
  ): Promise<FetchRecommendationsResponse> {
    const apiService = gcn.location.shouldSendRecommendationsRequestToGateway()
      ? ApiService.RecommendationsApi
      : ApiService.RecommendationsApiMaitred;
    const url = '/api/v2/recommendations/session';
    const payload = getPreCheckoutRecommendationsPayload(
      guestIdentifiers,
      cartOrderedItems,
      recommendationVariationId,
    );
    const request = RequestData.newPutRequest(apiService, url, payload);
    request.setTimeout(5 * TimeHelper.SECOND);
    return this.requestManager.makeRequestAsync(request);
  }

  async fetchSideCartRecommendations(
    guestIdentifiers: GuestIdentifiers,
    cartOrderedItems: GcnOrderedItem[],
    recommendationVariationId?: string,
  ): Promise<FetchRecommendationsResponse> {
    const apiService = gcn.location.shouldSendRecommendationsRequestToGateway()
      ? ApiService.RecommendationsApi
      : ApiService.RecommendationsApiMaitred;
    const url = '/api/v2/recommendations/session';
    const payload = getSideCartViewRecommendationsPayload(
      guestIdentifiers,
      cartOrderedItems,
      recommendationVariationId,
    );
    const request = RequestData.newPutRequest(apiService, url, payload);
    return this.requestManager.makeRequestAsync(request);
  }

  sendLog(logJson: any): void {
    const request = RequestData.newPostRequest(ApiService.LogsApiV1, '/logs', logJson);
    this.requestManager.makeRequest(request);
  }

  createOrderRequest(callback: Callback<{ order: Order }>): void {
    const url = '/api/v2/orders';
    const payload = gcn.orderManager.getCreateOrderPayload();
    const request = RequestData.newPostRequest(ApiService.OrdersApiV2, url, payload);
    this.requestManager.makeRequest(request, (err, data) => {
      this.captureOrderContextOnKioskException(request, err);
      callback(err, data);
    });
  }

  async getOrderRequest(orderId: string): Promise<GetOrderResponse> {
    const url = `/api/v2/orders/${orderId}`;
    const request = RequestData.newGetRequest(ApiService.OrdersApiV2, url);
    try {
      return await this.requestManager.makeRequestAsync(request);
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  async getKdsOrderPayload(orderId: string): Promise<{
    kdsJobs: KdsJob[];
  }> {
    const url = `/api/v2/orders/${orderId}/fulfillment-send-payload`;
    const request = RequestData.newGetRequest(ApiService.OrdersApiV2, url);
    try {
      return await this.requestManager.makeRequestAsync(request);
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  async postFulfillmentSendAttempt(
    orderId: string,
    payload: {
      response: {
        data: string;
        address: string;
      };
      startedAt: number;
    },
  ): Promise<{}> {
    const url = `/api/v2/orders/${orderId}/fulfillment-send-attempt`;
    const request = RequestData.newPostRequest(ApiService.OrdersApiV2, url, payload);
    try {
      return await this.requestManager.makeRequestAsync(request);
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  getTermsOfService(
    orgId: string,
    cb: (err: ApiError | undefined, data: { tos: string }) => void,
  ): void {
    const url = `/api/orgs/${orgId}/kiosk-tos`;
    const request = RequestData.newGetRequest(ApiService.Maitred, url);
    this.requestManager.makeRequest(request, cb);
  }

  getOrderRequestAsync(
    orderId: string,
    cb: (err: ApiError | undefined, data: GetOrderResponse) => void,
  ): void {
    const url = `/api/v2/orders/${orderId}`;
    const request = RequestData.newGetRequest(ApiService.OrdersApiV2, url);
    request.setTimeout(15000);
    this.requestManager.makeRequest(request, (err, data) => {
      this.captureOrderContextOnKioskException(request, err);
      cb(err, data);
    });
  }

  async updateOrderRequest(payload: any): Promise<void> {
    const orderId = gcn.orderManager.getOrderId();
    const url = `/api/v2/orders/${orderId}`;
    if (!_.size(payload?.order)) {
      // if there's nothing to update on the order, then don't
      return;
    }
    const request = RequestData.newPutRequest(ApiService.OrdersApiV2, url, payload);
    try {
      await this.requestManager.makeRequestAsync(request);
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  updateTipRequest(callback: Callback<{ order: Order }>): void {
    const orderId = gcn.orderManager.getOrderId();
    const url = `/api/v2/orders/${orderId}/tip`;
    const payload = gcn.orderManager.getTipPayload();
    const request = RequestData.newPutRequest(ApiService.OrdersApiV2, url, payload);
    request.setTimeout(30 * TimeHelper.SECOND);
    this.requestManager.makeRequest(request, (err, data) => {
      this.captureOrderContextOnKioskException(request, err);
      this.processOrderOperationResponse(err, data, callback);
    });
  }

  addCouponRequest(couponCode: string, callback: Callback): void {
    const orderId = gcn.orderManager.getOrderId();
    const url = `/api/v2/orders/${orderId}/coupon`;
    const payload = this.getAddCouponPayload(couponCode);
    const request = RequestData.newPostRequest(ApiService.OrdersApiV2, url, payload);
    request.setTimeout(30 * TimeHelper.SECOND);
    this.requestManager.makeRequest(request, (err, data) => {
      this.captureOrderContextOnKioskException(request, err);
      this.processOrderOperationResponse(err, data, callback);
    });
  }

  removeCouponRequest(callback: Callback): void {
    const orderId = gcn.orderManager.getOrderId();
    const url = `/api/v2/orders/${orderId}/coupon`;
    const payload = this.getRemoveCouponPayload();
    const request = RequestData.newDeleteRequest(ApiService.OrdersApiV2, url, payload);
    request.setTimeout(30 * TimeHelper.SECOND);
    this.requestManager.makeRequest(request, (err, data) => {
      this.captureOrderContextOnKioskException(request, err);
      this.processOrderOperationResponse(err, data, callback);
    });
  }

  getRemoveCouponPayload(): RemoveCouponBody {
    return {};
  }

  getAddCouponPayload(couponCode: string): AddCouponBody {
    return {
      couponCode,
    };
  }

  getStoredValueInquirePayload(authData: AuthData): StoredValueInquireBody {
    return {
      authData,
    };
  }

  private async storedValueInquireRequest<T extends AuthData>(
    authData: T,
  ): Promise<{ authData: T; balance: number }> {
    const url = '/api/v2/payments/stored-value/inquire';
    const payload = this.getStoredValueInquirePayload(authData);
    const request = RequestData.newPutRequest(ApiService.PaymentsApiV2, url, payload);
    try {
      return await this.requestManager.makeRequestAsync(request);
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  getLoyaltyAuthPayload(loyaltyAuthCredentials: LoyaltyAuthCredentials): LoyaltyAuthBody {
    return {
      loyaltyAuthCredentials,
    };
  }

  loyaltyAuthRequest(loyaltyAuthCredentials: LoyaltyAuthCredentials, callback: Callback): void {
    const url = '/api/v2/orders/loyalty/auth';
    const payload = this.getLoyaltyAuthPayload(loyaltyAuthCredentials);
    const request = RequestData.newPostRequest(ApiService.LoyaltyApiV2, url, payload);
    this.requestManager.makeRequest(request, callback);
  }

  getLoyaltyAvailableRewardsPayload(loyaltyAuthData: LoyaltyAuthData): LoyaltyAvailableRewardsBody {
    return {
      loyaltyAuthData,
    };
  }

  loyaltyAvailableRewardsRequest(callback: Callback): void {
    const orderId = gcn.orderManager.getOrderId();
    const loyaltyAuthData = gcn.loyaltyManager.getLoyaltyAuthDataForRequest();
    const url = `/api/v2/orders/${orderId}/available-rewards`;
    const payload = this.getLoyaltyAvailableRewardsPayload(loyaltyAuthData);
    const request = RequestData.newPostRequest(ApiService.LoyaltyApiV2, url, payload);
    this.requestManager.makeRequest(request, (err, data) => {
      this.captureOrderContextOnKioskException(request, err);
      callback(err, data);
    });
  }

  loyaltyAvailableRewardsRequestPreOrder(callback: Callback): void {
    const loyaltyAuthData = gcn.loyaltyManager.getLoyaltyAuthData()!;
    const url = `/api/v2/orders/available-rewards`;
    const payload = this.getLoyaltyAvailableRewardsPayload(loyaltyAuthData);
    const request = RequestData.newPostRequest(ApiService.LoyaltyApiV2, url, payload);
    this.requestManager.makeRequest(request, callback);
  }

  private getLoyaltyAddRewardPayload(
    loyaltyAuthData: LoyaltyAuthData,
    reward: LoyaltyReward,
  ): LoyaltyAddRewardBodyV2 {
    return {
      loyaltyAuthData,
      reward,
    };
  }

  private loyaltyAddRewardRequest(reward: LoyaltyReward, callback: Callback): void {
    const orderId = gcn.orderManager.getOrderId();
    const loyaltyAuthData = gcn.loyaltyManager.getLoyaltyAuthData()!;
    const url = `/api/v2/orders/${orderId}/add-reward`;
    const payload = this.getLoyaltyAddRewardPayload(loyaltyAuthData, reward);
    const request = RequestData.newPostRequest(ApiService.LoyaltyApiV2, url, payload);
    request.setTimeout(30 * TimeHelper.SECOND);
    this.requestManager.makeRequest(request, (err, data) => {
      this.captureOrderContextOnKioskException(request, err);
      this.processOrderOperationResponse(
        err,
        data,
        (innerErr, innerData: GetOrderResponse | any) => {
          if (innerErr) {
            callback(innerErr);
            return;
          }

          let transaction = innerData.transaction;
          if (!transaction) {
            // If the original request timed-out, but we got the get-order-response, then we need to
            // find the loyalty transaction from the list of successful transactions

            // If we're adding a loyalty reward, then a loyalty i9n must exist
            const loyaltyI9n = gcn.location.getLoyaltyIntegration()!;
            const schema = I9nSchemaBySystem[loyaltyI9n.system];

            if (schema.createsTransactions) {
              // Let's save some cycles and only try to find the loyalty transaction if the i9n
              // actually creates them
              const refundedTransactionIdsSet = new Set(
                innerData.successfulTransactions
                  .filter((successfulTransaction: Transaction) => {
                    return successfulTransaction.type === TransactionType.Refund;
                  })
                  .map((successfulRefund: Transaction) => {
                    return successfulRefund.originalTransactionId;
                  }),
              );
              transaction = innerData.successfulTransactions.find(
                (successfulTransaction: Transaction) => {
                  return (
                    !refundedTransactionIdsSet.has(successfulTransaction._id) &&
                    CardSchemeIdHelper.isLoyalty(successfulTransaction.cardSchemeId)
                  );
                },
              );
            }
          }

          callback(undefined, {
            order: innerData.order,
            successfulTransactions: innerData.successfulTransactions,
            ...(transaction && { transaction }),
          });
        },
      );
    });
  }

  private getLoyaltyRemoveRewardPayload(
    loyaltyAuthData: LoyaltyAuthData,
    rewardI9nId: string,
    transactionId: string | undefined,
  ): LoyaltyRemoveRewardBody {
    return {
      loyaltyAuthData,
      rewardI9nId,
      ...(transactionId && { transactionId }),
    };
  }

  private loyaltyRemoveRewardRequest(
    rewardI9nId: string,
    transactionId: string | undefined,
    callback: Callback,
  ): void {
    const orderId = gcn.orderManager.getOrderId();
    const loyaltyAuthData = gcn.loyaltyManager.getLoyaltyAuthData()!;
    const url = `/api/v2/orders/${orderId}/remove-reward`;
    const payload = this.getLoyaltyRemoveRewardPayload(loyaltyAuthData, rewardI9nId, transactionId);
    const request = RequestData.newPostRequest(ApiService.LoyaltyApiV2, url, payload);
    request.setTimeout(30 * TimeHelper.SECOND);
    this.requestManager.makeRequest(request, (err, data) => {
      this.captureOrderContextOnKioskException(request, err);
      this.processOrderOperationResponse(err, data, callback);
    });
  }

  private getApplyCompCardPayload(compCardAuthData: CompCardAuthData): CompCardApplyBody {
    return {
      authData: compCardAuthData,
    };
  }

  private async applyCompCardRequest(
    compCardAuthData: CompCardAuthData,
  ): Promise<GetOrderResponse> {
    const orderId = gcn.orderManager.getOrderId();
    const url = `/api/v2/orders/${orderId}/apply-comp-card`;
    const payload = this.getApplyCompCardPayload(compCardAuthData);
    const request = RequestData.newPutRequest(ApiService.OrdersApiV2, url, payload);
    request.setTimeout(30 * TimeHelper.SECOND);

    try {
      return await this.requestManager.makeRequestAsync(request);
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  private async removeCompCardRequest(): Promise<GetOrderResponse> {
    const orderId = gcn.orderManager.getOrderId();
    const url = `/api/v2/orders/${orderId}/remove-comp-card`;
    const request = RequestData.newPutRequest(ApiService.OrdersApiV2, url, {});
    request.setTimeout(30 * TimeHelper.SECOND);

    try {
      return await this.requestManager.makeRequestAsync(request);
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  getRefundPayload(
    amount: number,
    expectedRemainingAmount: number,
    clientId: string,
    accessCode?: string,
  ): RefundPaymentBody {
    return {
      amount,
      expectedRemainingAmount,
      clientId,
      ...(accessCode && { accessCode }),
    };
  }

  refundRequest(
    transactionId: string,
    amount: number,
    expectedRemainingAmount: number,
    clientId: string,
    accessCode: string | undefined,
    callback: Callback,
  ): void {
    const url = `/api/v2/payments/${transactionId}/refund`;
    const payload = this.getRefundPayload(amount, expectedRemainingAmount, clientId, accessCode);
    const request = RequestData.newPostRequest(ApiService.PaymentsApiV2, url, payload);

    void (async () => {
      try {
        let successfulTransactions: Transaction[];
        let order: Order;
        try {
          const response = await this.requestManager.makeQueuedRequestAsync<{
            order: Order;
            successfulTransactions: Transaction[];
          }>(request);
          order = response.order;
          successfulTransactions = response.successfulTransactions;
        } catch (err) {
          // the job may have completed and has been removed from the queue
          if (err.code !== ErrorCode.ApiJobNotFound) {
            throw err;
          }
          const getOrderResponse = await gcn.maitred.getOrderRequest(gcn.orderManager.getOrderId());
          order = getOrderResponse.order;
          successfulTransactions = getOrderResponse.successfulTransactions;
        }
        callback(undefined, {
          order,
          successfulTransactions,
        });
      } catch (err) {
        callback(err);
      }
    })();
  }

  private static stringifyStoredValuePaymentPayload(payload: Record<string, any>): string {
    const { authData, amount, orderId } = payload;
    const payloadCopy = { authData, amount, orderId };

    return JSON.stringify(payloadCopy);
  }

  async createStoredValuePaymentRequest(
    amount: number,
    authData: AuthData,
    clientId: string,
  ): Promise<any> {
    const url = '/api/v2/payments/stored-value';
    const orderId = gcn.orderManager.getOrderId();
    const payload = this.getCreateStoredValuePaymentPayload(orderId, amount, authData, clientId);
    const payloadStr = GcnMaitredClient.stringifyStoredValuePaymentPayload(payload);

    if (payloadStr === this.storedValuePaymentTimedOutPayloadStr) {
      payload.clientId = this.storedValuePaymentTimedOutTransactionClientId!;
    }
    const request = RequestData.newPostRequest(ApiService.PaymentsApiV2, url, payload);
    request.setTimeout(30 * TimeHelper.SECOND);

    try {
      const response = await this.requestManager.makeRequestAsync(request);
      this.storedValuePaymentTimedOutTransactionClientId = undefined;
      this.storedValuePaymentTimedOutPayloadStr = undefined;

      return response;
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);

      if (err.code === ErrorCode.NetworkRequestTimedOut) {
        // Just because the request timed-out doesn't mean that the transaction did not succeed
        // Retain the client ID so that we can use it later if we re-attempt the request
        this.storedValuePaymentTimedOutTransactionClientId = clientId;
        this.storedValuePaymentTimedOutPayloadStr =
          GcnMaitredClient.stringifyStoredValuePaymentPayload(payload);
      }

      throw err;
    }
  }

  async createStoredValuePaymentQueuedRequest(
    amount: number,
    authData: AuthData,
    clientId: string,
  ): Promise<any> {
    const url = '/api/v2/payments/stored-value-queue';
    const orderId = gcn.orderManager.getOrderId();
    const payload = this.getCreateStoredValuePaymentPayload(orderId, amount, authData, clientId);
    const payloadStr = GcnMaitredClient.stringifyStoredValuePaymentPayload(payload);

    if (payloadStr === this.storedValuePaymentTimedOutPayloadStr) {
      payload.clientId = this.storedValuePaymentTimedOutTransactionClientId!;
    }

    const request = RequestData.newPostRequest(ApiService.PaymentsApiV2, url, payload);
    request.setTimeout(30 * TimeHelper.SECOND);

    try {
      const response = await this.requestManager.makeQueuedRequestAsync(request, {
        timeout: 3 * TimeHelper.MINUTE,
        message: 'Stored Value Payment Session Timeout',
        code: ErrorCode.PaymentUserTimeout,
        startedAt: Date.now(),
      });
      this.storedValuePaymentTimedOutTransactionClientId = undefined;
      this.storedValuePaymentTimedOutPayloadStr = undefined;

      return response;
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);

      if (err.code === ErrorCode.NetworkRequestTimedOut) {
        // Just because the request timed-out doesn't mean that the transaction did not succeed
        // Retain the client ID so that we can use it later if we re-attempt the request
        this.storedValuePaymentTimedOutTransactionClientId = clientId;
        this.storedValuePaymentTimedOutPayloadStr =
          GcnMaitredClient.stringifyStoredValuePaymentPayload(payload);
      }

      // the job may have completed and has been removed from the queue
      // or the order is possibly closed
      if (ErrorCode.ApiJobNotFound !== err.code) {
        throw err;
      }
      const { successfulTransactions } = await gcn.maitred.getOrderRequest(
        gcn.orderManager.getOrderId(),
      );
      const storedValueTransaction = successfulTransactions.find((transaction) => {
        return transaction.clientId === clientId;
      });
      if (!storedValueTransaction) {
        // turns out that our transaction failed
        throw new Error('Transaction declined');
      }

      throw err;
    }
  }

  getCreateStoredValuePaymentPayload(
    orderId: string,
    amount: number,
    authData: AuthData | null,
    clientId: string,
  ): StoredValuePaymentBody {
    return {
      authData: Object.keys(authData || {}).length ? authData : null,
      amount,
      orderId,
      clientId,
    };
  }

  private static stringifyEcommPaymentPayload(payload: Record<string, any>): string {
    // Since the payment method property contains data out of our control, do not include it in the
    // stringified payload
    const { amount, orderId } = payload;
    const payloadCopy = { amount, orderId };

    return JSON.stringify(payloadCopy);
  }

  async createEcommPaymentRequest(): Promise<void> {
    const url = '/api/v2/payments/ecomm';
    const payload = gcn.orderManager.getEcommPaymentPayload();
    const payloadStr = GcnMaitredClient.stringifyEcommPaymentPayload(payload);
    if (payloadStr === this.ecommPaymentTimedOutPayloadStr) {
      payload.clientId = this.ecommPaymentTimedOutTransactionClientId;
    }
    const request = RequestData.newPostRequest(ApiService.PaymentsApiV2, url, payload);
    const transactionClientId = payload.clientId;

    try {
      await this.requestManager.makeQueuedRequestAsync(request);
      this.ecommPaymentTimedOutTransactionClientId = undefined;
      this.ecommPaymentTimedOutPayloadStr = undefined;
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);

      if (err.code === ErrorCode.NetworkRequestTimedOut) {
        // Just because the request timed-out doesn't mean that the transaction did not succeed
        // Retain the client ID so that we can use it later if we re-attempt the request
        this.ecommPaymentTimedOutTransactionClientId = transactionClientId;
        this.ecommPaymentTimedOutPayloadStr =
          GcnMaitredClient.stringifyEcommPaymentPayload(payload);
      }

      // the job may have completed and has been removed from the queue
      // or the order is possibly closed
      if (![ErrorCode.ApiJobNotFound, ErrorCode.OrderClosed].includes(err.code)) {
        gcn.orderManager.clearEcommPaymentMethod();
        throw err;
      }
      const { successfulTransactions } = await gcn.maitred.getOrderRequest(
        gcn.orderManager.getOrderId(),
      );
      const ecommTransaction = successfulTransactions.find((transaction) => {
        return transaction.clientId === transactionClientId;
      });
      if (!ecommTransaction) {
        // turns out that our transaction failed
        gcn.orderManager.clearEcommPaymentMethod();
        throw new Error('Transaction declined');
      }
    }
  }

  private static stringifyKioskApiPaymentPayload(payload: Record<string, any>): string {
    const { amount, orderId } = payload;
    const payloadCopy = { amount, orderId };

    return JSON.stringify(payloadCopy);
  }

  async preReadCard(): Promise<void> {
    const url = '/api/v2/payments/pre-read-card';
    const payload = gcn.orderManager.getPreReadCardPayload();
    const request = RequestData.newPostRequest(ApiService.PaymentsApiV2, url, payload);
    try {
      const initialRequest =
        await this.requestManager.makeRequestAsync<PreReadCardResponse>(request);

      if (initialRequest.queuePath && initialRequest.queuePath.includes(payload.clientId)) {
        this.preReadCardRequestClientId = payload.clientId;
      }

      const result = await this.requestManager.waitForPaymentQueuedJob<PreReadCardResponse>(
        request.apiService,
        initialRequest.queuePath!,
        {
          timeout: 5 * TimeHelper.MINUTE,
          message: 'Pre-read card session timeout',
          code: ErrorCode.PaymentUserTimeout,
          startedAt: Date.now(),
        },
      );

      this.preReadCardSuccessful = result.success;
    } catch (err) {
      this.preReadCardRequestClientId = undefined;
      this.preReadCardSuccessful = false;
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  async createKioskApiPaymentRequest(): Promise<KioskApiPaymentResponse> {
    const url = '/api/v2/payments/kiosk-api';
    const payload = gcn.orderManager.getKioskApiPaymentPayload();
    const payloadStr = GcnMaitredClient.stringifyKioskApiPaymentPayload(payload);
    if (payloadStr === this.kioskApiPaymentTimedOutPayloadStr) {
      payload.clientId = this.kioskApiPaymentTimedOutTransactionClientId;
    }
    payload.preReadCardRequestClientId = this.preReadCardRequestClientId;
    const request = RequestData.newPostRequest(ApiService.PaymentsApiV2, url, payload);
    const transactionClientId = payload.clientId;

    try {
      const initialRequest =
        await this.requestManager.makeRequestAsync<KioskApiPaymentResponse>(request);

      const response = await this.requestManager.waitForPaymentQueuedJob<KioskApiPaymentResponse>(
        request.apiService,
        initialRequest.queuePath!,
        {
          timeout: 4 * TimeHelper.MINUTE,
          message: 'Payment Session Timeout',
          code: ErrorCode.PaymentUserTimeout,
          startedAt: Date.now(),
        },
      );

      this.kioskApiPaymentTimedOutTransactionClientId = undefined;
      this.kioskApiPaymentTimedOutPayloadStr = undefined;

      return response;
    } catch (err) {
      // Clear these incase we pre read and need to try again
      this.preReadCardRequestClientId = undefined;
      this.preReadCardSuccessful = false;

      this.captureOrderContextOnKioskException(request, err);

      if (err.code === ErrorCode.NetworkRequestTimedOut) {
        // Just because the request timed-out doesn't mean that the transaction did not succeed
        // Retain the client ID so that we can use it later if we re-attempt the request
        this.kioskApiPaymentTimedOutTransactionClientId = transactionClientId;
        this.kioskApiPaymentTimedOutPayloadStr =
          GcnMaitredClient.stringifyKioskApiPaymentPayload(payload);
      }

      // the job may have completed and has been removed from the queue
      // or the order is possibly closed
      if (![ErrorCode.ApiJobNotFound, ErrorCode.OrderClosed].includes(err.code)) {
        throw err;
      }
      const { successfulTransactions } = await gcn.maitred.getOrderRequest(
        gcn.orderManager.getOrderId(),
      );
      const kioskApiTransaction = successfulTransactions.find((transaction) => {
        return transaction.clientId === transactionClientId;
      });
      if (!kioskApiTransaction) {
        // turns out that our transaction failed
        throw new Error('Transaction declined');
      }
      throw err;
    }
  }

  async cancelKioskApiPaymentRequest(): Promise<KioskApiPaymentCancelResponse> {
    const url = '/api/v2/payments/kiosk-api/cancel';
    const payload = gcn.orderManager.getKioskApiPaymentCancelPayload();
    const request = RequestData.newPostRequest(ApiService.PaymentsApiV2, url, payload);

    try {
      const response =
        await this.requestManager.makeRequestAsync<KioskApiPaymentCancelResponse>(request);
      return response;
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);
      throw err;
    }
  }

  async createKioskPaymentRequest(
    payload: any,
    timedOutRetryCount: number = 0,
    orderLockedRetryCount: number = 0,
  ): Promise<any> {
    const url = '/api/v2/payments/kiosk';
    const request = RequestData.newPostRequest(ApiService.PaymentsApiV2, url, payload);
    // allow up to 3 timed-out errors and/or 5 order-locked errors before giving up
    const shouldRetryTimedOut = timedOutRetryCount <= 2;
    const shouldRetryOrderLocked = orderLockedRetryCount <= 4;

    try {
      const createPaymentResult = await this.requestManager.makeRequestAsync(request);
      return createPaymentResult;
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);

      if (err.code === ErrorCode.NetworkRequestTimedOut && shouldRetryTimedOut) {
        return this.createKioskPaymentRequest(
          payload,
          timedOutRetryCount + 1,
          orderLockedRetryCount,
        );
      }
      if (err.code === ErrorCode.OrderBeingProcessed && shouldRetryOrderLocked) {
        // Retry after 1, 2, 4, 4, 4 seconds
        await sleep(1000 * (Math.min(4, orderLockedRetryCount * 2) || 1));
        return this.createKioskPaymentRequest(
          payload,
          timedOutRetryCount,
          orderLockedRetryCount + 1,
        );
      }
      throw err;
    }
  }

  // Strictly ASAP kiosk orders
  async closeOrderGetQueuePathRequest(
    attemptsRemaining: number,
    orderLockedRetryCount: number = 0,
  ): Promise<{
    request: RequestData;
    closeOrderResponse: CloseOrderResponse;
  }> {
    const orderId = gcn.orderManager.getOrderId();
    const url = `/api/v2/orders/${orderId}/close`;
    const payload = gcn.orderManager.getCloseOrderPayload();
    const request = RequestData.newPutRequest(ApiService.OrdersApiV2, url, payload);
    try {
      const initialRequest =
        await this.requestManager.makeRequestAsync<CloseOrderResponse>(request);

      Sentry.addBreadcrumb({
        category: 'close-order-debug',
        message: 'closeOrderGetQueuePathRequest',
        level: 'info',
        data: {
          orderId,
          initialRequest,
        },
      });

      // notify if no queue path returned
      if (!initialRequest?.queuePath) {
        const noQueuePathErr = new Error('Close order request returned no queue path');
        Sentry.captureException(noQueuePathErr);
        throw noQueuePathErr;
      }

      return { request, closeOrderResponse: initialRequest };
    } catch (err) {
      const shouldRetryOrderLocked = orderLockedRetryCount <= 4;
      if (err.code === ErrorCode.OrderBeingProcessed && shouldRetryOrderLocked) {
        // Retry after 1, 2, 4, 4, 4 seconds
        await sleep(1000 * (Math.min(4, orderLockedRetryCount * 2) || 1));
        return this.closeOrderGetQueuePathRequest(attemptsRemaining, orderLockedRetryCount + 1);
      }

      this.captureOrderContextOnKioskException(request, err);
      if (attemptsRemaining <= 1) {
        throw err;
      }

      return this.closeOrderGetQueuePathRequest(attemptsRemaining - 1);
    }
  }

  async closeAsapKioskOrder(): Promise<CloseOrderResponse> {
    const { request, closeOrderResponse } = await this.closeOrderGetQueuePathRequest(2);
    const { order, successfulTransactions, queuePath } = closeOrderResponse;
    Sentry.addBreadcrumb({
      category: 'close-order-debug',
      message: 'closeAsapKioskOrder request',
      level: 'info',
      data: {
        order,
      },
    });
    // Order object needs to be extended because it doesn't come back with the transactions
    // TODO: set transactions on orderManager and pass orderManager _transactions
    // to the print job instead of order
    // Tracked here: https://getbite.atlassian.net/browse/BITE-1980
    gcn.orderManager.setOrderFromJSON({
      ...order,
      transactions: successfulTransactions,
    });

    try {
      const response = await this.requestManager.waitForQueuedJob<CloseOrderResponse>(
        request.apiService,
        queuePath!,
        {
          timeout: 30 * TimeHelper.SECOND,
          message: 'Send order UI Timeout',
          code: successfulTransactions.length
            ? ErrorCode.SendOrderUITimeoutWithPayment
            : ErrorCode.SendOrderUITimeoutWithoutPayment,
          startedAt: Date.now(),
        },
      );

      Sentry.addBreadcrumb({
        category: 'close-order-debug',
        message: 'closeAsapKioskOrder waitForOrderToClose response',
        level: 'info',
        data: {
          response,
        },
      });
      const { order: _order, successfulTransactions: _successfulTransactions } = response;
      return { order: _order, successfulTransactions: _successfulTransactions };
    } catch (err) {
      Sentry.addBreadcrumb({
        category: 'close-order-debug',
        message: 'closeAsapKioskOrder error block',
        level: 'info',
        data: {
          err,
        },
      });
      // the job may have completed and has been removed from the queue
      // on the flip side, we may have received a timeout error and should
      // check the order and transactions status
      if (![ErrorCode.ApiJobNotFound, ErrorCode.SendOrderUITimeoutWithPayment].includes(err.code)) {
        throw err;
      }

      const { order: _order, successfulTransactions: _successfulTransactions } =
        await gcn.maitred.getOrderRequest(gcn.orderManager.getOrderId());

      if (!_order.wasSentToPos) {
        // if an order is not in the queue, check to make sure it's been sent
        throw new Error('Order not sent');
      }
      return { order: _order, successfulTransactions: _successfulTransactions };
    }
  }

  async closeOrderRequest(timeoutConfig?: TimeoutConfig): Promise<CloseOrderResponse> {
    const orderId = gcn.orderManager.getOrderId();
    const url = `/api/v2/orders/${orderId}/close`;
    const payload = gcn.orderManager.getCloseOrderPayload();
    const request = RequestData.newPutRequest(ApiService.OrdersApiV2, url, payload);
    try {
      const { order, successfulTransactions } =
        await this.requestManager.makeQueuedRequestAsync<CloseOrderResponse>(
          request,
          timeoutConfig,
        );
      if (order.guestId && order.guestId !== gcn.guestManager.getGuestId()) {
        // The guest ID may change if we found an existing guest while closing the order, so update
        // the guest manager with the correct ID
        gcn.guestManager.setGuestId(order.guestId);
      }
      return { order, successfulTransactions };
    } catch (err) {
      this.captureOrderContextOnKioskException(request, err);

      // the job may have completed and has been removed from the queue
      if (err.code !== ErrorCode.ApiJobNotFound) {
        throw err;
      }
      const { order, successfulTransactions } = await gcn.maitred.getOrderRequest(
        gcn.orderManager.getOrderId(),
      );
      if (!order.wasSentToPos) {
        // if an order is not in the queue, check to make sure it's been sent
        throw new Error('Order not sent');
      }
      return { order, successfulTransactions };
    }
  }

  // V1
  sendOrder(orderPayload: any, timeout: number): Promise<any> {
    const request = RequestData.newPostRequest(ApiService.OrdersApiV1, '/orders', orderPayload);
    request.setTimeout(timeout);
    return this.requestManager.makeRequestAsync(request);
  }

  fetchCreditCardEntryIframe(query: any, callback: Callback): void {
    const queryParamString = GcnHelper.queryString(query);
    const request = RequestData.newGetRequest(
      ApiService.Maitred,
      `/integrations/${gcn.location.get('paymentI9nId')}/iframe${queryParamString}`,
    );
    this.requestManager.makeRequest(request, callback);
  }

  private static handleCustomerResponse(callback: Callback): Callback {
    return (err: any, data?: any) => {
      switch (err?.code) {
        case ErrorCode.AuthInvalidCustomerToken:
        case ErrorCode.AuthCustomerAccountNotVerified:
        case ErrorCode.AuthCustomerAccountDisabled:
        case ErrorCode.AuthCustomerAccountDeleted:
          LocalStorage.removeItem(`${window.customerScope}:token`);
          if (gcn.menuView) {
            gcn.menuView.showSimpleAlert(err.message);
          } else {
            gcn.delayedErrorMessage = err.message;
          }
          break;
      }
      callback(err, data);
    };
  }

  getCustomerResource(resource: string, query: any, callback: Callback): void {
    const queryParamString = GcnHelper.queryString(query);
    const url = ['/api/v2/customer', resource]
      .filter((val) => {
        return val;
      })
      .join('/');
    const request = RequestData.newGetRequest(ApiService.Maitred, `${url}${queryParamString}`);
    this.requestManager.makeRequest(request, GcnMaitredClient.handleCustomerResponse(callback));
  }

  postCustomerResource(resource: string, payload: any, callback: Callback): void {
    const url = ['/api/v2/customer', resource]
      .filter((val) => {
        return val;
      })
      .join('/');
    const request = RequestData.newPostRequest(ApiService.Maitred, url, payload);
    this.requestManager.makeRequest(request, GcnMaitredClient.handleCustomerResponse(callback));
  }

  postLocationsResource(resource: string, payload: any, callback: Callback): void {
    const url = ['/api/v2/locations', resource]
      .filter((val) => {
        return val;
      })
      .join('/');
    const request = RequestData.newPostRequest(ApiService.Maitred, url, payload);
    this.requestManager.makeRequest(request, callback);
  }

  isMaitredApiError(err: Error | MaitredApiError): err is MaitredApiError {
    // make a fake maitred error so we can check code
    // a type guard typically has a property like errorType = 'normalErr' | 'maitredError'
    // but we are checking for a property that doesn't exist on Error instance
    const fakeMaitredApiError = err as MaitredApiError;
    return !!fakeMaitredApiError.code;
  }
}
