import Cookies from 'js-cookie';

import { Log } from '@biteinc/common';

type Key =
  | 'guestId'
  | 'guestEmail'
  | 'guestName'
  | 'lastLocationId'
  | 'orderedItems'
  | 'orderExpires'
  | 'fulfillmentInfo'
  | 'deliveryAddress';

export default abstract class LocalStorage {
  static canStoreJson(): boolean {
    return !!window.localStorage;
  }

  static setItem(key: Key, value: string): void {
    if (!window.isFlash) {
      return;
    }

    if (!value) {
      this.removeItem(key);
      return;
    }

    if (!localStorage) {
      Cookies.set(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  }

  static setJsonItem<T>(key: Key, value: T): void {
    this.setItem(key, JSON.stringify(value));
  }

  static getItem(key: Key): string | undefined | null {
    const value = this.getCustomKeyItem(key);
    if (value) {
      return value;
    }

    // We used to store some fields like guestId and orderedItems in cookies with a prefixed key
    // Let's check for those and if found, store them properly without a prefix in localStorage.
    const prefixedKey = `_bite-${key}`;
    const oldCookieValue = Cookies.get(prefixedKey);
    if (oldCookieValue) {
      Cookies.remove(prefixedKey);
      this.setItem(key, oldCookieValue);
    }
    return oldCookieValue;
  }

  static getJsonItem<T>(key: Key): T | undefined {
    const value = this.getItem(key);
    if (value) {
      try {
        return JSON.parse(value) as T;
      } catch (err) {
        Log.error('error parsing value for key:', key, value);
        return undefined;
      }
    }
    return undefined;
  }

  static getCustomKeyItem(key: string): string | undefined | null {
    if (!window.isFlash) {
      return undefined;
    }

    return localStorage ? localStorage.getItem(key) : Cookies.get(key);
  }

  static setCustomKeyItem(key: string, value: string): void {
    if (!window.isFlash) {
      return undefined;
    }
    if (!localStorage) {
      Cookies.set(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  }

  static removeItem(key: string): void {
    if (!window.isFlash) {
      return;
    }

    if (!localStorage) {
      Cookies.remove(key);
    } else {
      localStorage.removeItem(key);
    }
  }
}
