import Backbone from 'backbone';
import _ from 'underscore';

import {
  LocationRecommendationsLevel,
  MenuItemRecommendability,
  MenuSectionRecommendability,
} from '@biteinc/enums';

export const GCNRecoManager = Backbone.Model.extend({
  getRecosForItem(orderedItem, maxRecoCount) {
    if (!maxRecoCount) {
      // eslint-disable-next-line no-param-reassign
      maxRecoCount = 2;
    }

    if (gcn.menu.settings.get('recommendationsLevel') === LocationRecommendationsLevel.Off) {
      return [];
    }

    return this._getRecos(orderedItem, maxRecoCount);
  },

  getRecosForRecommendability(recommendability, maxRecoCount) {
    return this._getRecos(null, maxRecoCount, recommendability);
  },

  _getRecos(orderedItem, maxRecoCount, targetRecommendability) {
    const PUSH = MenuItemRecommendability.Push;

    // Collect all the itemIds that have already been ordered. Include the
    // item we are currently looking it. We shouldn't recommend it either.
    const orderedItemById = {};
    if (orderedItem) {
      orderedItemById[orderedItem.id] = orderedItem;
    }
    _.each(gcn.orderManager.getOrderedItems(), (oi) => {
      orderedItemById[oi.id] = oi;
    });

    // Collect recos specified for the item. These recos will be preferred immediately.
    // Items can be in the order already, should not matter.
    const prescribedItemRecoById = {};
    if (orderedItem) {
      _.each(orderedItem.item.get('recommendedItems'), (reco) => {
        const item = gcn.menu.getMenuItemWithId(reco._id);
        if (item && item.canBeRecommended()) {
          prescribedItemRecoById[item.id] = item;
        }
      });
    }

    // Begin picking recommendations if this is per-item. That is, orderedItem !== null
    //  Rank 1: Recommended-for items, that are "Prioritized when recommended"
    //  Rank 2: Recommended-for items, that "Can be recommended"
    // Return only if we have satisfied the requested number of recommendations
    let recos = [];
    if (_.size(prescribedItemRecoById) >= 0 && !!orderedItem) {
      const prescribedRecos = _.values(prescribedItemRecoById);
      const sortedPrescribedRecos = _.sortBy(prescribedRecos, (item) => {
        let score = Math.random();
        // Prefer items that are Prioritized When Recommended
        if (item.get('recommendability') !== PUSH) {
          score += 5;
        }
        return score;
      });
      if (maxRecoCount <= _.size(sortedPrescribedRecos)) {
        return sortedPrescribedRecos.slice(0, maxRecoCount);
      }
      recos = sortedPrescribedRecos;
    }

    // At this point we may have prescribed items - Item and Guest. We have not reached the
    // requested amount, and so will iterate menu sections to pick remaining recos.
    // Important: these items are subject to the idea of no reccomendations from menu sections
    //  previously selected

    //  Rank 3: Guest Recommendations (with priority to those prioritized items)
    const prescribedGuestRecoById = {};
    gcn.guestManager.getRecoItemIds().forEach((itemId) => {
      const item = gcn.menu.getMenuItemWithId(itemId);
      if (item && item.canBeRecommended() && !orderedItemById[item.id]) {
        prescribedGuestRecoById[item.id] = item;
      }
    });

    // Remove sections with items we've already recommended from future eligible list, 'cart rule'
    const alreadyRecommendItemIds = _.union(
      _.keys(orderedItemById),
      _.map(recos, (reco) => {
        return reco.id;
      }),
    );
    const recommendableSections = _.filter(gcn.menu.sections, (section) => {
      return (
        !_.any(section.items, (item) => {
          return _.contains(alreadyRecommendItemIds, item.id);
        }) ||
        // The exception are sections that are hermits if the ordered item is from it.
        (orderedItem && section.isHermitRecommendability() && section.hasItemWithId(orderedItem.id))
      );
    });

    // Arrange prescribed GUEST recos by sectionId so that it's easier to pick
    const prescribedRecosBySectionId = {};
    _.each(recommendableSections, (section) => {
      const items = _.filter(section.items, (item) => {
        return !!prescribedGuestRecoById[item.id];
      });
      prescribedRecosBySectionId[section.id] = items;
    });

    // Find all items that are elligible for recommendation AKA
    // Rank 4: Everything else, trying to mix up items from different sections, at random.
    const possibleItemsBySectionId = {};
    _.each(recommendableSections, (section) => {
      // Only recommend Treats for CFA Meals
      if (gcn.location.isCFA()) {
        if (orderedItem) {
          if (
            orderedItem.item.displayName().match(/meal$/i) &&
            section.id !== '5b2f9f7cc5cc3f001babbf64'
          ) {
            // Treats
            return;
          }
        }
      }

      const recommendability = section.get('recommendability');
      switch (recommendability) {
        case MenuSectionRecommendability.Exclude:
          return;
        case MenuSectionRecommendability.Hermit:
          // Items from a hermit section can only be recommended to other
          // items from that section.
          if (orderedItem && section.id !== orderedItem.section.id) {
            return;
          }
        /* falls through */
        default: {
          // Check that this section has any items that haven't been ordered
          const items = _.filter(section.items, (item) => {
            if (!item.canBeRecommended() || orderedItemById[item.id]) {
              return false;
            }
            if (targetRecommendability) {
              if (
                item.get('recommendability') !== PUSH &&
                !prescribedGuestRecoById[item.id] &&
                targetRecommendability !== recommendability
              ) {
                return false;
              }
            }
            return true;
          });
          if (items.length) {
            possibleItemsBySectionId[section.id] = items;
          }
        }
      }
    });

    // Sort the sections; first ones should be picked for recos
    const availableSectionIds = _.keys(possibleItemsBySectionId);
    const sortedSectionIds = _.sortBy(availableSectionIds, (sectionId) => {
      const section = gcn.menu.getMenuSectionWithId(sectionId);
      const recommendability = section.get('recommendability');
      let score = 10;

      // If we have a desired recommendability (then we are in checkout workflow),
      // we then prefer Guest Recommendations, then matching recommendability over everything else
      const items = possibleItemsBySectionId[sectionId];
      const hasGuestItems = _.any(items, (item) => {
        return prescribedGuestRecoById[item.id];
      });
      const hasPushItems = _.any(items, (item) => {
        return item.get('recommendability') === PUSH;
      });
      // Split the workflows for CHECKOUT_UPSELL and Per-Item
      if (targetRecommendability) {
        if (targetRecommendability === recommendability) {
          score = 1;
        }
        // Guest recommendations are priority rank 3, should come first if section is available
        if (hasGuestItems) {
          score = 0;
        } else if (!hasPushItems) {
          score += 5;
        }
      } else {
        // We are not in the checkout workflow here, so we prioritize push-able items over guest
        // recommendations
        if (hasPushItems) {
          score = 2;
        }
        if (!hasGuestItems) {
          score += 3;
        }
      }

      // If this section is a hermit, prefer items from this section
      if (
        orderedItem &&
        orderedItem.section.id === sectionId &&
        orderedItem.section.isHermitRecommendability()
      ) {
        score = 2;
      }

      // Use the number of prescribed recos to break the tie if sections have
      // the same ordered unit count.
      // Inverse the count to reward sections that have more prescribed items.
      score += 10 - prescribedRecosBySectionId[sectionId].length;
      // Use a random fraction to randomize equally ranked sections
      score += Math.random();
      return score;
    });

    // Each section should get about half of the total possible amount so that
    // not all recos come from one section.
    const maxRecoCountFromSection = Math.max(1, Math.floor(maxRecoCount / 2));
    const recoById = {};
    let loopCount = 0;
    const maxLoops = 5;
    // Loop over sections until we can bail when we have enough recommendations
    // or otherwise max out after maxLoops run throughs
    while (recos.length < maxRecoCount && loopCount < maxLoops) {
      _.each(sortedSectionIds, (sectionId) => {
        const possibleItems = possibleItemsBySectionId[sectionId];
        const sortedPossibleItems = _.sortBy(possibleItems, (item) => {
          // Prefer items that are supposed to be pushed
          let score = 2;
          const recommendability = item.get('recommendability');
          if (recommendability === PUSH) {
            score = 1;
          }

          // Prefer items that are prescribed to guest
          if (prescribedGuestRecoById[item.id]) {
            return Math.random();
          }
          return score + 10 + Math.random();
        });

        const maxSelectionCount = Math.min(sortedPossibleItems.length, maxRecoCountFromSection);
        for (let k = 0; k < maxSelectionCount; k++) {
          const item = sortedPossibleItems[k];
          if (recos.length < maxRecoCount && !recoById[item.id]) {
            recos.push(item);
            recoById[item.id] = item;
          }
        }
      });
      loopCount++;
    }

    return recos;
  },
});

GCNRecoManager.trackingLabel = function trackingLabel(upsellScreen, position, item, recoType) {
  return [
    upsellScreen,
    ...(recoType ? [`type-${recoType}`] : []),
    `position-${position}`,
    `img-${item.hasImages() ? 1 : 0}`,
  ].join(':');
};
