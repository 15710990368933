import { Strings } from '@biteinc/common';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { GCNPhoneNumberEntryView } from './gcn_phone_number_entry_view';

export const GCNLoyaltyPhoneNumberView = GCNPhoneNumberEntryView.extend({
  initialize(options, ...args) {
    // If flash client, then we bring up the keyboard entry for phone number instead of keypad
    options.useNativeInput = !!window.isFlash;
    GCNPhoneNumberEntryView.prototype.initialize.apply(this, [options, ...args]);

    this._lookupUser = options.lookupUserMethod;

    this.listenTo(gcn.loyaltyManager, BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest, () => {
      if (this._callback) {
        this._callback();
      }
      gcn.menuView.dismissStablePopup();
    });

    this.listenTo(
      gcn.loyaltyManager,
      BackboneEvents.GCNLoyaltyManager.DidFailAuth,
      this._updateLabelWithError,
    );
  },

  _updateLabelWithError(errorMsg) {
    this.__$label.htmlOrText(errorMsg);
    this.__$label.toggleClass('error', true);
  },

  render(...args) {
    GCNPhoneNumberEntryView.prototype.render.apply(this, args);

    this.__$label.htmlOrText(localizeStr(Strings.LOYALTY_APP_AUTH_PHONE_FALLBACK_LABEL));

    this.setDoneButtonText(localizeStr(Strings.SEARCH));
    this.__$doneButton.onButtonTapOrHold('lpnvDone', () => {
      if (!this.__$doneButton.hasClass('disabled')) {
        this._lookupUser(this.__userNumber);
      }
    });

    this.__$icon.hide();
    this.__$skipButton.hide();

    return this;
  },
});
