import { IonButton, IonButtons, IonHeader, IonToolbar } from '@ionic/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { Log, Strings } from '@biteinc/common';
import type { DeliveryAddress } from '@biteinc/core-react';
import { addressSchema } from '@biteinc/core-react';

import { useStore } from '~/stores';

import { GenericForm } from '../../../components';
import { str } from '../localization/localization';
import LocalStorage from '../utils/local_storage';
import GcnView from './gcn_view_ts';

class TypedGenericForm extends GenericForm<DeliveryAddress> {}

export default class GcnDeliveryAddressView extends GcnView {
  className = 'order-destination-view';

  root: ReactDOM.Root;

  static Events = {
    DidEnterDeliveryAddress: 'didEnterDeliveryAddress',
    BackedOut: 'backedOut',
  };

  private validateAddress(deliveryAddress: DeliveryAddress): Promise<boolean> {
    const path = `${gcn.location.id}/validate-delivery-address`;
    const payload = {
      address: deliveryAddress,
      fulfillmentMethod: gcn.orderManager.getFulfillmentMethod(),
    };
    return new Promise((resolve) => {
      gcn.maitred.postLocationsResource(path, payload, (err) => {
        if (err) {
          Log.error('Err', err);
          gcn.menuView.showSimpleAlert(err.message, () => {
            gcn.orderManager.clearDeliveryAddress();
            this.trigger(GcnDeliveryAddressView.Events.BackedOut);
          });
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }

  destroy(): void {
    super.destroy();

    this.root.unmount();
  }

  render(): this {
    const deliveryInstructions = str(Strings.DELIVERY_INSTRUCTIONS);

    this.root = ReactDOM.createRoot(this.$el[0]);
    this.root.render(
      React.createElement(
        'div',
        { className: 'delivery-address-view' },
        React.createElement(
          IonHeader,
          null,
          React.createElement(
            IonToolbar,
            null,
            React.createElement(
              IonButtons,
              { slot: 'start' },
              React.createElement(
                IonButton,
                {
                  color: 'primary',
                  onClick: () => {
                    this.trigger(GcnDeliveryAddressView.Events.BackedOut);
                  },
                },
                str(Strings.BACK),
              ),
            ),
          ),
        ),
        deliveryInstructions &&
          React.createElement('div', { className: 'delivery-instructions' }, deliveryInstructions),
        React.createElement(TypedGenericForm, {
          lang: useStore.getState().config.language,
          customStrings: useStore.getState().bridge.menu.settings.customStrings,
          schema: addressSchema,
          data:
            gcn.orderManager.getDeliveryAddress() ??
            LocalStorage.getJsonItem('deliveryAddress') ??
            undefined,
          submitButtonText: str(Strings.DONE),
          submit: async (deliveryAddress: DeliveryAddress) => {
            const isValid = await this.validateAddress(deliveryAddress);
            if (isValid) {
              gcn.orderManager.setDeliveryAddress(deliveryAddress);
              this.trigger(GcnDeliveryAddressView.Events.DidEnterDeliveryAddress);
            }
          },
        }),
      ),
    );
    return this;
  }
}
