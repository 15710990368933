import { IntegrationSystem } from '@biteinc/enums';

module StoredValueHelper {
  // Returns true if the gift card is actually some sort of secondary payment method, like a
  // student card or some other stored payment method.
  export function isStoredValueSecondaryPayment(): boolean {
    return !!gcn.location.getStoredValueIntegration()?.useAsSecondaryPayment;
  }

  export function requiresNativeForStoredValue(): boolean {
    switch (gcn.location.get('giftCardI9n')) {
      case IntegrationSystem.WorldpayStoredValue:
      case IntegrationSystem.Worldpay:
      case IntegrationSystem.Archer:
      case IntegrationSystem.FreedomPayKiosk:
      case IntegrationSystem.EigenStoredValue:
        return true;
      default:
        if (gcn.kiosk?.get('hasMsr')) {
          return true;
        }
        return false;
    }
  }
}

export default StoredValueHelper;
