import { IonButton, IonCol, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';
import * as React from 'react';

import { LocalizationHelper } from '@biteinc/core-react';
import { FulfillmentMethodHelper } from '@biteinc/enums';

import { useLocalize } from '~/app/js/localization/localization';

import { GcnFulfillmentHelper } from '../../helpers';
import { useLocation, useStore } from '../../stores';
import { CheckInInstructions } from './checkin-instructions';
import { DeliveryInstructions } from './delivery-instructions';
import { FulfillmentInstructions } from './fulfillment-instructions';
import { OrderDetailsAddress } from './order-details-address';
import { OrderDetailsUtils } from './order-details.utils';

export const OrderDetails = function OrderDetails(
  props: OrderDetailsUtils.OrderDetailsProps,
): JSX.Element {
  const localizeStr = useLocalize();
  const order = useStore((state) => state.checkout.order);
  const orderDetails = OrderDetailsUtils.getOrderDetails(order);
  const futureTimeStr = OrderDetailsUtils.calculateFutureTimeStr(orderDetails);
  const location = useLocation();
  const address = GcnFulfillmentHelper.addressToString(location.fullAddress, true);
  const diningOption = location.getDiningOptionForFulfillmentMethod(orderDetails.fulfillmentMethod);
  const diningOptionName =
    diningOption.name ??
    localizeStr(LocalizationHelper.localizeEnum.FulfillmentMethod(orderDetails.fulfillmentMethod));
  const outpostName = orderDetails.outpost?.roomNumber
    ? `${orderDetails.outpost?.name} ` + orderDetails.outpost.roomNumber
    : orderDetails.outpost?.name;
  return (
    <div className="tw-flex tw-flex-col tw-space-y-2">
      {orderDetails.checkinUrl && orderDetails.orderIsClosed ? (
        <CheckInInstructions
          futureTimeStr={futureTimeStr}
          checkinUrl={orderDetails.checkinUrl}
        />
      ) : null}
      <IonList className="order-details-list">
        <IonItem
          lines="none"
          className="order-details-header"
        >
          <IonLabel className="title">
            <h3>Order Details</h3>
          </IonLabel>
          {orderDetails.wasValidated && !orderDetails.orderIsClosed && (
            <IonButton
              className="edit-order-details-button"
              fill="clear"
              onClick={() => {
                props.onEdit();
              }}
            >
              Edit
            </IonButton>
          )}
        </IonItem>
        {orderDetails.wasValidated && (
          <>
            <IonItem
              lines="none"
              className="fulfillment-method"
            >
              <span className="fulfillment-method-label">{diningOptionName}</span>
              <span className="pickup-time">{futureTimeStr}</span>
            </IonItem>
            <OrderDetailsAddress
              header={location.name}
              address={GcnFulfillmentHelper.addressAsMultipleLines(location.fullAddress)}
            />
            {FulfillmentMethodHelper.isDelivery(orderDetails.fulfillmentMethod) ? (
              <OrderDetailsAddress
                header="Delivery to:"
                address={GcnFulfillmentHelper.addressAsMultipleLines(orderDetails.deliveryAddress)}
              />
            ) : null}
            {FulfillmentMethodHelper.isDelivery(orderDetails.fulfillmentMethod) &&
            orderDetails.deliveryInstructions ? (
              <DeliveryInstructions
                deliveryInstructions={orderDetails.deliveryInstructions}
                orderIsClosed={orderDetails.orderIsClosed}
              />
            ) : null}
            {orderDetails.orderIsClosed ? (
              <>
                <IonItem
                  lines="none"
                  className="location-buttons"
                >
                  <IonRow className="locations-buttons-row">
                    <IonCol>
                      <IonButton
                        className="call-button"
                        fill="outline"
                        size="default"
                        expand="block"
                        href={`tel:${location.phoneNumber}`}
                      >
                        Call
                      </IonButton>
                    </IonCol>
                    {!FulfillmentMethodHelper.isDelivery(orderDetails.fulfillmentMethod) && (
                      <IonCol>
                        <IonButton
                          className="cta-button directions-button"
                          color="primary"
                          size="default"
                          expand="block"
                          target="_blank"
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
                            address,
                          )}`}
                        >
                          Get Directions
                        </IonButton>
                      </IonCol>
                    )}
                  </IonRow>
                </IonItem>
                <IonItem
                  lines="none"
                  className="fulfillment-instructions"
                >
                  <IonLabel>
                    <FulfillmentInstructions
                      fulfillmentMethod={orderDetails.fulfillmentMethod}
                      guestName={orderDetails.guestName}
                      orderNumber={orderDetails.orderNumber}
                      outpostName={outpostName}
                    />
                  </IonLabel>
                </IonItem>
              </>
            ) : null}
          </>
        )}
      </IonList>
    </div>
  );
};
