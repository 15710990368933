import _ from 'underscore';

import {
  MenuItemCategory,
  MenuSectionPriceDisplay,
  MenuSectionRecommendability,
} from '@biteinc/enums';

import { str } from '~/app/js/localization/localization';

import { GCNModel } from './gcn_model';

export const GCNMenuSection = GCNModel.extend({
  initialize() {
    this.items = [];
    this._itemsById = {};
  },

  addItem(item) {
    this.items.push(item);
    this._itemsById[item.id] = item;
  },

  defaults() {
    return {
      recommendability: MenuSectionRecommendability.Default,
    };
  },

  hasImages() {
    return this.hasArr('images');
  },

  hasItemWithId(itemId) {
    return !!this._itemsById[itemId];
  },

  isHermitRecommendability() {
    return MenuSectionRecommendability.Hermit === this.get('recommendability');
  },

  isPromoSection() {
    return !!this.get('isPromoSection');
  },

  canBeFiltered() {
    if (this.isPromoSection()) {
      return false;
    }

    return _.all(this.items, (item) => {
      return item.get('category') === MenuItemCategory.Food;
    });
  },
});

GCNMenuSection.BITE_LIFT_SECTION_ID = '500000000000000000444444';
GCNMenuSection.newPromoSection = function newPromoSection(
  sectionId,
  items,
  menu,
  title,
  description,
) {
  const sectionJSON = {
    _id: sectionId,
    recommendability: MenuSectionRecommendability.Exclude,
    images: gcn.menu.settings.get('recommendationsFirstLoadHeaderImages')?.length
      ? gcn.menu.settings.get('recommendationsFirstLoadHeaderImages')
      : menu.getDefaultSectionHeaderImages(),
    items: _.map(items, (item) => {
      return { _id: item.id };
    }),
    showAllPhotoPreviews: true,
    priceDisplay: MenuSectionPriceDisplay.HidePriceOptions,
    isPromoSection: true,
  };
  const languages = gcn.menu.settings.get('supportedLanguages');
  for (let i = 0; i < languages.length; i++) {
    const lang = languages[i];
    const name = str(title, [], lang);
    sectionJSON[`name${i > 0 ? `_${lang}` : ''}`] = name;
    if (description) {
      const localizedDescription = `${str(description, [], lang)}`;
      sectionJSON[`description${i > 0 ? `_${lang}` : ''}`] = localizedDescription;
    }
  }

  const promoSection = new GCNMenuSection(sectionJSON);
  promoSection.items = items;
  return promoSection;
};
