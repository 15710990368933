/* prettier-ignore */
import 'jquery-migrate';
import 'jquery.easing';
import './gcn_extensions';
import './globals';
import './polyfills';

import { setupIonicReact } from '@ionic/react';
import * as Sentry from '@sentry/browser';
import async from 'async';
import $ from 'jquery';
import _ from 'underscore';

import { Log, Strings } from '@biteinc/common';
import { ModalService } from '@biteinc/core-react';
import {
  ApiHeader,
  BiteLogDeviceEvent,
  BitePlatform,
  CardEntryMethod,
  Environment,
  FlashBridgeMessage,
  MenuCoverPromoLinkType,
  MenuItemSaleUnit,
  OrderClientEventName,
  OrderPaymentDestination,
  PaymentsApiVersion,
} from '@biteinc/enums';

import { localizeStr, str } from '~/app/js/localization/localization';
import { useStore } from '~/stores';

import { GcnCustomerAccountHelper } from '../../helpers';
import MobileAppMessageType from '../../types/mobile_app_message_type';
import { BackboneEvents } from './backbone-events';
import { GCNAppView } from './gcn_app_view';
import GcnFlashBridge from './gcn_flash_bridge';
import GcnFlashMaitredClient from './gcn_flash_maitred_client';
import GcnGarconBridge from './gcn_garcon_bridge';
import { GCNLoyaltyManager } from './gcn_loyalty_manager';
import GcnMaitredClient from './gcn_maitred_client';
import OrderHelper from './gcn_order_helper';
import { GCNOrderManager } from './gcn_order_manager';
import { GCNRecoManager } from './gcn_reco_manager';
import GcnRecoTracker from './gcn_reco_tracker';
import GuestManager from './guest_manager';
import { getKioskPreviewBridge } from './kiosk-preview-bridge';
import GcnKiosk from './models/gcn_kiosk';
import GcnLocation from './models/gcn_location';
import { GCNMenu } from './models/gcn_menu';
import { GCNModel } from './models/gcn_model';
import { GCNOrderedItem } from './models/gcn_ordered_item';
import { GCNTransaction } from './models/gcn_transaction';
import OrderSender from './order_sender';
import Analytics from './utils/analytics';
import CartCoordinator from './utils/cart_coordinator';
import Errors from './utils/errors';
import getQueryParam from './utils/get_query_param';
import LocalStorage from './utils/local_storage';
import { GCNAdminView } from './views/gcn_admin_view';
import { GCNAlertView } from './views/gcn_alert_view';
import GcnGiftCardBarcodeView from './views/gcn_gift_card_barcode_view';
import { GCNLoyaltyBarcodeView } from './views/gcn_loyalty_barcode_view';
import { GCNMenuView } from './views/gcn_menu_view';

// this mutes the jquery migrate warnings - which get overwhelming in the console
$.migrateMute = true;

$.migrateEnablePatches('self-closed-tags');

setupIonicReact({
  mode: 'ios',
});

export const GCNMenuAppView = GCNAppView.extend({
  initialize(options, data, gcnBuildName, flashSessionId, authToken, ...args) {
    if (window.isFlash) {
      $.ajaxSetup({
        headers: {
          [ApiHeader.GcnBuildName]: gcnBuildName,
          [ApiHeader.FlashSessionId]: flashSessionId,
          Authorization: `Bearer ${authToken}`,
        },
      });
    } else if (window.isGarcon) {
      $.ajaxSetup({
        headers: {
          [ApiHeader.GcnBuildName]: gcnBuildName,
          [ApiHeader.GarconBuildName]: data.garconBuildName,
          [ApiHeader.KioskBuildName]: data.kioskBuildName,
          [ApiHeader.KioskId]: data.kioskId,
          Authorization: `Bearer ${authToken}`,
        },
      });
    }
    GCNAppView.prototype.initialize.apply(this, [
      options,
      data,
      gcnBuildName,
      flashSessionId,
      authToken,
      ...args,
    ]);

    this.guestManager = new GuestManager();
    this.orderManager = new GCNOrderManager();
    this.recoManager = new GCNRecoManager();
    this.loyaltyManager = new GCNLoyaltyManager();
    this.maitred = new GcnMaitredClient();

    this.touchCalibrationConfig = {
      version: 2,
      minTouchDurationThresholdForClick: 0,
      minTouchXYMovementThresholdForClick: 0,
      maxHoldToClickTimeMs: 120,
      maxFlingDuration: 1000,
      scrollResponseBoundary: 10,
      scrollBoundary: 20,
    };

    this.customScroller = null;

    this._requests = [];
    this.screenReaderIsActive = false;

    this._scannerHandlers = [];
    this.pushScannerHandler(this);

    if (data && window.isFlash) {
      this.apiHost = data.apiHost;
      this.flashResolvedHost = data.flashResolvedHost;
      this.org = data.org;

      this.ecommI9n = data.ecommI9n;

      // This is so the FE of the react app knows which location to send the user back to
      LocalStorage.setItem(`${window.customerScope}:urlSlug`, data.location.urlSlug);

      this._setViewHeightProperty();

      $(window).on('resize', this._setViewHeightProperty.bind(this));

      /**
       * We need a class which allows us to target elements that need to replicate the react
       * app. E.g. Header and Footer
       */
      if (window.webEnabled) {
        $(document.body).addClass('bite-web');
      }

      async.setImmediate(() => {
        this.setLocation(data.location);
        this.setBridge(
          new GcnFlashBridge(
            gcn.maitred,
            data.apiHost,
            gcn.location.get('orgId'),
            data.orderChannel,
            gcn.location.id,
          ),
        );
        async.auto(
          {
            fetchMenu: (cb) => {
              gcn.maitred.fetchMenu(
                0,
                undefined,
                undefined,
                getQueryParam('forcedMenuStructureId'),
                cb,
              );
            },
            customerJson: [
              'fetchMenu',
              ({ fetchMenu }, cb) => {
                // We need to set the menu first so that the state has all the data we need
                this.setMenu(fetchMenu.menu);
                // Failing is fine - it just means we aren't logged in
                if (
                  GcnCustomerAccountHelper.customerAccountsAreEnabled() &&
                  GcnFlashMaitredClient.getCustomerToken()
                ) {
                  gcn.maitred.getCustomerResource(null, null, (err, customerData) => {
                    if (err) {
                      cb();
                    } else {
                      cb(null, customerData);
                    }
                  });
                } else {
                  cb();
                }
              },
            ],
            reorderJson: (cb) => {
              const orderId = LocalStorage.getItem(`${gcn.location.get('urlSlug')}:orderId`);
              if (orderId) {
                (async () => {
                  try {
                    const { order } = await gcn.maitred.getOrderRequest(orderId);

                    const expiryDate = new Date(new Date().getTime() + 86400 * 1000); // valid for 24h
                    LocalStorage.setItem('orderExpires', expiryDate.getTime());
                    LocalStorage.setItem('lastLocationId', gcn.location.id);
                    LocalStorage.setItem('orderedItems', JSON.stringify(order.clientOrderedItems));
                    // Cleanup
                    LocalStorage.removeItem(`${gcn.location.get('urlSlug')}:orderId`);
                    cb();
                  } catch (_err) {
                    // don't care about error
                    cb();
                  }
                })();
                return;
              }
              cb();
            },
          },
          (err, { customerJson }) => {
            if (err) {
              Log.error('fetch menu error', err);
              // TODO: show the error message with option to reload the page
              return;
            }

            this.sessionWasStartedAt = Date.now();

            if (data.sessionStoredValueCards) {
              gcn.orderManager.setStoredValueCards(
                data.sessionStoredValueCards.map((card) => {
                  return {
                    ...card,
                    cardEntryMethod: CardEntryMethod.ProvidedExternally,
                  };
                }),
              );
            }

            if (customerJson) {
              gcn.orderManager.setCustomer(customerJson.customer);
              gcn.loyaltyManager.setCustomerAuthData(customerJson.customer);
            }

            if (!gcn.location.get('useRecommendationsApiV2')) {
              gcn.guestManager.fetchGuest({
                guestPhoneNumber: gcn.orderManager.getGuestPhoneNumber(),
              });
            }

            this.setBridgeIsReady();

            setTimeout(() => {
              this.handleBridgeMessage({ flashSessionStart: true });
            }, 1);
          },
        );
      });
    } else if (data && window.isGarcon) {
      this.apiHost = data.apiHost;
      this.flashResolvedHost = data.flashResolvedHost;
      this.org = data.org;

      async.setImmediate(() => {
        this.setLocation(data.location);
        this.setBridge(
          new GcnGarconBridge(
            data.apiHost,
            gcn.location.get('orgId'),
            data.orderChannel,
            gcn.location.id,
          ),
        );
        async.auto(
          {
            fetchMenu: (cb) => {
              gcn.maitred.fetchMenu(0, undefined, undefined, undefined, cb);
            },
            fetchKiosk: (cb) => {
              gcn.maitred.fetchKiosk(data.kioskId, cb);
            },
          },
          (err, { fetchMenu, fetchKiosk }) => {
            if (err) {
              Log.error('fetch menu/kiosk error', err);
              // TODO: show the error message with option to reload the page
              return;
            }

            this.setMenu(fetchMenu.menu);
            this.kiosk = new GcnKiosk(fetchKiosk.kiosk);
            this.setBridgeIsReady();
          },
        );
      });
    } else if (window.isKioskPreview) {
      async.setImmediate(() => {
        this.setLocation(data.location);

        if (authToken) {
          const bridge = getKioskPreviewBridge({
            gcnBuildName,
            authToken,
            showCover: () => this.showCover(),
          });
          this.setBridge(bridge);

          gcn.maitred.fetchMenu(0, undefined, undefined, undefined, (err, menuResponseData) => {
            if (err) {
              Log.error('fetch menu error', err);
              // TODO: show the error message with option to reload the page
              return;
            }

            this.setMenu(menuResponseData.menu);
            this.setBridgeIsReady();
            this.showCover();
          });
        } else {
          this.setMenu(data.menu);
          this.setBridgeIsReady();
          this.showCover();
        }
      });
    }

    CartCoordinator.setCartObserver(this);
  },

  setBridge(bridge, ...args) {
    GCNAppView.prototype.setBridge.apply(this, [bridge, ...args]);

    this.maitred.setRequestMaker((requestData, callback) => {
      bridge.send(
        {
          event: FlashBridgeMessage.MAITRED_REQUEST,
          request: requestData,
        },
        callback,
      );
    });
  },

  /**
   * This simulates the kiosk cover - for use only in kiosk preview
   */
  showCover() {
    if (!window.isKioskPreview) {
      return;
    }

    if (!this._$cover) {
      const location = useStore.getState().bridge.location;
      const { menuCover } = useStore.getState().bridge.menu;
      const is1080p = window.platform !== BitePlatform.KioskIos;
      const tosBarPct = is1080p ? '2.6%' : '3.6%';
      const promosPct = is1080p ? '31.25%' : '43.9%';
      const coverHeight =
        menuCover.coverImageDisplayStyle === 'with-promos'
          ? `calc(100%-${promosPct}-${tosBarPct})`
          : '100%';

      const $coverImage = $(
        menuCover.coverImage
          ? `<img src="${menuCover.coverImage.url}" style="position: absolute; top: 0px; left: 0px; width: 100%; height: ${coverHeight}; object-fit: cover; object-position: center center; cursor: pointer;" />`
          : '<p style="font-size: 24px; cursor: pointer;">This is where a menu cover would go. Click to continue.</p>',
      );
      const $promoTosBar = $(
        // prettier-ignore
        `<div id="tos-bar" style="position: absolute; bottom: 0; width: 100%; height: ${tosBarPct}; font-weight: normal; font-size: 20px; background-color: black; line-height: 1.6; display: flex; flex-direction: row; justify-content: space-between; padding: 0 8px;">` +
          '<span style="text-decoration: underline; color: #999; cursor: pointer;">Terms of Use</span>' +
        '</div>',
      );
      if (location.usesDelphi) {
        $promoTosBar.prepend(
          '<span style="cursor: pointer;">To personalize your menu we try to identify you. Opt-out here.</span>',
        );
      }

      let $languageSelectionBar;
      if (menuCover.languageSelection) {
        const { languageSelection } = menuCover;
        $languageSelectionBar = $(
          `<div id="language-selection" style="height: 3.5vh; padding: 0 20px; margin-top: 15px; text-align: center; background-color: ${languageSelection.backgroundColor}"></div>`,
        );
        const buttonWidth = Math.floor(100 / languageSelection.buttons.length) - 1;
        languageSelection.buttons.forEach(
          ({ languageCode, backgroundColor, textColor, text }, i) => {
            const marginLeft = i === 0 ? '0' : '2%';
            const $button = $(
              `<div class="language-button ${languageCode}" style="display: inline-block; margin-left: ${marginLeft}; height: 100%; width: ${buttonWidth}%; background-color: ${backgroundColor}; color: ${textColor}; cursor: pointer; line-height: 3.5vh; font-size: 30px">${text}</div>`,
            );
            $button.on('click', (event) => {
              event.preventDefault();

              $cover.slideUp();
              this.handleBridgeMessage({
                sessionStart: true,
                selectedLanguage: languageCode,
              });

              return false;
            });
            $languageSelectionBar.append($button);
          },
        );
      }

      let $coverBottom;
      switch (menuCover.promoDisplayStyle) {
        case 'full-width':
          $coverBottom = $(
            // prettier-ignore
            `<div style="position: absolute; bottom: ${tosBarPct}; width: 100%; height: ${promosPct}; overflow-x: scroll">` +
              menuCover.promos.map((promo, i) => {
                return `<img id="promo-${promo._id}" src="${promo.image.url}" style="position: absolute; width: 100%; height: 100%; top: 0; left: ${i * 100}%; object-fit: cover; object-position: center center; cursor: pointer;" />`;
              }) +
            '</div>',
          ).add($promoTosBar);
          break;
        case 'half-width':
          $coverBottom = $(
            // prettier-ignore
            `<div style="position: absolute; bottom: ${tosBarPct}; width: 100%; height: ${promosPct}; overflow-x: scroll">` +
              menuCover.promos.map((promo, i) => {
                return `<img id="promo-${promo._id}" src="${promo.image.url}" style="position: absolute; width: 50%; height: 100%; top: 0; left: ${i * 50}%; object-fit: cover; object-position: center center; cursor: pointer;" />`;
              }) +
            '</div>',
          ).add($promoTosBar);
          break;
        case 'none':
          $coverBottom = $(
            // prettier-ignore
            '<div id="cover-bottom" style="position: absolute; bottom: 1vh; width: 100%">' +
              (location.usesDelphi
                ? '<div style="">To personalize your menu we try to identify you<br/><br/>Opt-Out Here</div>'
                : '') +
              `<div style="width: 20%; margin-left: 80%; height: 1vh; color: ${menuCover.termsOfUseTextColor};">Terms of Use</div>` +
            '</div>',
          );
          if ($languageSelectionBar) {
            $coverBottom.append($languageSelectionBar);
          }
          break;
      }

      const $cover = $(
        // prettier-ignore
        '<div id="embed-menu-cover" ' +
          'style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgb(0, 0, 0); z-index: 10000; display: flex; justify-content: center; align-items: center; line-height: 1.2; font-size: 14px; font-weight: bold; text-align: center; color: white; text-shadow: 0 2px 8px #000000"" ' +
        '></div>',
      );
      $cover.append($coverImage);
      $cover.append($coverBottom);

      // Use touchstart instead of click to override the touchstart listener we have on the document
      // This would properly mimic the fact that on native kiosks, the cover does not live in gcn.
      const eventName = 'ontouchstart' in window ? 'touchstart' : 'click';

      $coverImage.on(eventName, (event) => {
        event.preventDefault();

        $cover.slideUp();
        this.handleBridgeMessage({ sessionStart: true });

        return false;
      });
      menuCover.promos.forEach((promo) => {
        $cover.find(`#promo-${promo._id}`).on(eventName, (event) => {
          event.preventDefault();

          $cover.slideUp();
          this.handleBridgeMessage({
            sessionStart: true,
            menuCoverPromoLinkType: promo.linkType,
            menuCoverPromoLinkTarget: promo.linkTarget,
          });

          return false;
        });
      });
      $cover.find('#tos-bar').on(eventName, (event) => {
        event.preventDefault();

        $cover.slideUp();
        this.handleBridgeMessage({ sessionStart: true });

        return false;
      });

      $('body').append($cover);

      this._$cover = $cover;
    } else {
      this._$cover.slideDown();
    }
  },

  updateGarconMenu() {
    this.menuView.showSpinner(localizeStr(Strings.LOADING_MENU));
    this.maitred.fetchMenu(0, undefined, undefined, undefined, (err, response) => {
      this.menuView.dismissSpinner();
      if (err) {
        Log.error('fetch menu', err);
        return;
      }

      this.setMenu(response.menu);

      // Update custom scroller dimensions after menu is updated to account for new menu height items,
      // or menu being updated to reduced height ADA mode
      if (gcn.customScroller) {
        gcn.customScroller.updateDimensions();
      }
    });
  },

  /**
   * Note on Sentry - no longer necessary to call context to wrap function
   */
  handleBridgeMessage(message, callback) {
    const self = this;
    try {
      self._handleBridgeMessage(message, callback);
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  },

  /**
   * Note on Sentry - no longer necessary to call context to wrap function
   */
  async handleMobileAppMessage(message) {
    const self = this;
    try {
      return await self._handleMobileAppMessage(message);
    } catch (err) {
      Sentry.captureException(err);
      throw err;
    }
  },

  _setViewHeightProperty() {
    /**
     * This is a hack for mobile browsers who can't calculate vh properly and make it container
     * larger than it should be :face_palm
     *
     * - First we get the viewport height and we multiple it by 1% to get a value for a vh unit
     */
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },

  _getOrderCallbackArg() {
    return {
      ...(!window.isFlash && {
        printPayload: OrderHelper.getPrintPayload(gcn.orderManager.getOrder()),
      }),
    };
  },

  _handleSendOrderFailure(message, callback, failedOnSync) {
    let analyticsEventName = failedOnSync
      ? Analytics.EventName.orderSendErrorOutOfSync
      : Analytics.EventName.OrderSendError;
    let clientEventName = failedOnSync
      ? OrderClientEventName.OrderSendErrorOutOfSync
      : OrderClientEventName.OrderSendError;

    const hadTransactions = !!gcn.orderManager.getTransactions()?.length;
    gcn.orderManager.setTransactions([]);
    gcn.loyaltyManager.removeLoyaltyTransaction();
    if (message.order && callback) {
      gcn.orderManager.setOrderFromJSON(message.order);
      callback(this._getOrderCallbackArg());
    }
    useStore.getState().checkout.onOrderCloseError();
    gcn.orderManager.eventRepo.track(clientEventName, message?.error);
    gcn.orderManager.commitEvents();

    // If there is no error message code, then show a generic error message
    const fallbackString = Strings.ERROR_SENDING_ORDER;
    let errMessage = Errors.stringFromErrorCode(message.error?.code, fallbackString);
    // If we had to use the fallback, then let's see if we need to add the bit about payment
    if (errMessage === localizeStr(fallbackString) && !failedOnSync && hadTransactions) {
      errMessage += ` ${localizeStr(Strings.PAYMENT_WAS_TAKEN)}`;
    }

    Analytics.trackEvent({
      eventName: analyticsEventName,
      eventData: {
        message: errMessage,
      },
    });
    gcn.menuView.showOrderFailedRecovery(errMessage);
  },

  _handleProcessingPaymentFailure(message, callback) {
    let analyticsEventName = Analytics.EventName.PaymentFailed;
    let clientEventName = OrderClientEventName.PaymentCreditCardError;

    if (message.order && callback) {
      gcn.orderManager.setOrderFromJSON(message.order);
      callback(this._getOrderCallbackArg());
    }

    gcn.orderManager.eventRepo.track(clientEventName, message?.error);
    gcn.orderManager.commitEvents();
    // If there is no error message code, then show a `PAYMENT_KIOSK_ERROR`
    const errMessage = Errors.stringFromErrorCode(message.error?.code, Strings.PAYMENT_KIOSK_ERROR);
    Analytics.trackEvent({
      eventName: analyticsEventName,
      eventData: {
        message: errMessage,
      },
    });
    gcn.menuView.showOrderFailedRecovery(errMessage);
  },

  _handleBridgeMessage(message, callback) {
    const Events = BackboneEvents.GCNMenuAppView;

    // Log the keys and the message so that we could see the type of message in sentry
    // The menu and the location messages, if logged in full, are too big for sentry to upload, but
    // it could still be useful in dev.
    let messageToLog = message;
    if (window.env !== Environment.DEV) {
      if (message.menu) {
        messageToLog = '<menu message: redacted for size>';
      } else if (message.location) {
        messageToLog = '<location message: redacted for size>';
      } else if (message.users) {
        messageToLog = '<user message: redacted for privacy>';
      }
    }
    Log.info(
      `got bridge msg${callback ? ' (w/ cb)' : ''}:`,
      Object.keys(message || {}).join(', '),
      messageToLog,
    );

    Object.keys(message).forEach((key) => {
      const data = message[key];
      useStore.getState().bridge.update(key, data);
    });

    if (message.menu) {
      if (this._sessionIsInProgress) {
        Log.info('Tried to set menu from bridge message while session is in progress');
        // There is a session in progress, so we can't change the menu while the user is browsing.
        // Any issues with validation will be caught when the user goes to checkout.

        // Hold onto the JSON until the session is over so that we don't lose the updates.
        this._newMenuJson = message.menu;
      } else {
        this.setMenu(message.menu);
      }

      if (this._delaySessionStartUntilWeHaveMenuAndLocation && this.location) {
        this._delaySessionStartUntilWeHaveMenuAndLocation = false;
        this.startSession(message);
      }
      return;
    }

    if (message.location) {
      this.setLocation(message.location);
      if (this._delaySessionStartUntilWeHaveMenuAndLocation && this.menu) {
        this._delaySessionStartUntilWeHaveMenuAndLocation = false;
        this.startSession(message);
      }
      return;
    }

    if (message.kiosk) {
      this.kiosk = new GcnKiosk(message.kiosk);
      return;
    }

    if (message.users) {
      this.setUsers(message.users);
      return;
    }

    if (message.menuPageId) {
      this.menuView.setMenuPageId(message.menuPageId, false);
      return;
    }

    if (message.scrollTo) {
      if (message.scrollTo.top) {
        this.menuView.scrollToTop();
      } else if (message.scrollTo.menuSectionId) {
        this.menuView.scrollToSectionWithId(message.scrollTo.menuSectionId);
      }
      return;
    }

    if (message.guest) {
      Log.info('i gotst a guest', message.guest);
      gcn.guestManager.setGuestData(message.guest.data, true);
      return;
    }

    if (
      _.has(message, 'isFirstTime') ||
      (window.isFlash && message.flashSessionStart) ||
      (window.isGarcon && message.flashSessionStart) ||
      (!window.isFlash && message.kioskSessionStart) ||
      (!window.isFlash && message.sessionStart)
    ) {
      Log.info('isFirstTime/flashSessionStart/kioskSessionStart/sessionStart');
      if (!this.menu || !this.location) {
        this._delaySessionStartUntilWeHaveMenuAndLocation = true;
        return;
      }
      this.startSession(message);
      return;
    }

    if (message.updatePaymentFlowState) {
      // Important log for debugging
      Log.info(
        'updatePaymentFlowState:',
        message.updatePaymentFlowState,
        message.order ? '(has order)' : '',
        message.step ? `(step: ${message.step})` : '',
      );
      const keepSpinner =
        message.updatePaymentFlowState === 'waiting_for_card' && gcn.maitred.cardWasPreRead();
      if (!keepSpinner) {
        gcn.menuView.dismissSpinner();
      }
      switch (message.updatePaymentFlowState) {
        case 'waiting_for_card':
          this.trigger(Events.PaymentFlowDidStart, this);
          break;
        case 'processing_payment':
          this.trigger(Events.PaymentProcessingDidStart, this);
          this.trigger(Events.AmountConfirmationWasStarted, this);
          break;
        case 'sending_order':
          this.trigger(Events.SendingOrderDidStart, this);
          break;
        case 'flash_order_failed': {
          gcn.menuView.showSimpleAlert(message.error.message || message.error);
          break;
        }

        case 'processing_payment_failed': {
          // Called when we failed to take payment
          this._handleProcessingPaymentFailure(message, callback);
          break;
        }
        case 'sending_order_failed_out_of_sync': {
          // Called when there is a critical discrepancy between the order on GCN and Maitred
          this._handleSendOrderFailure(message, callback, true);
          break;
        }
        case 'failed_to_get_order': {
          // Called when we failed to get the order from the server
          this.trigger(Events.OrderFailedToGet, this);
          break;
        }
        case 'sending_order_failed': {
          // Called when an order fails while being sent (86'd while sent falls under this)
          this._handleSendOrderFailure(message, callback, false);
          break;
        }
        case 'sending_order_success':
          Log.info(message);
          // Now that the order is finished, we can wash our hands of additional transactions.
          gcn.orderManager.setTransactions([]);
          gcn.loyaltyManager.removeLoyaltyTransaction();
          if (message.order) {
            gcn.orderManager.setOrderFromJSON(message.order);
            useStore.getState().checkout.onOrderClosed(message.order.transactions);
            // Order and recommendation references if using Orders API v1
            // v2 will create references right after validation when it gets the orderId
            if (!gcn.location.useOrdersApiV2()) {
              GcnRecoTracker.createOrderReferences(gcn.maitred, message.order._id);
            }
            gcn.orderManager.eventRepo.track(OrderClientEventName.OrderSendEnd);
            Analytics.track(Analytics.EventName.OrderSendComplete);
            gcn.orderManager.commitEvents();
            if (callback) {
              callback(this._getOrderCallbackArg());
            }
          }
          break;
        case 'local_printing':
          gcn.menuView.showSpinner(localizeStr(Strings.PRINTING_RECEIPT));
          break;
        case 'local_printing_done':
          gcn.menuView.dismissSpinner();
          break;
        case 'local_printing_failed':
          this.trigger(Events.LocalPrintDidFail);
          break;
        case 'order_complete':
          this.trigger(Events.OrderDidComplete, message.order);
          break;
        case 'transaction_cancelled':
          this.trigger(Events.TransactionDidCancel, this);
          break;
        case 'order_abandoned': {
          gcn.orderManager.eventRepo.trackSessionAbandon('kiosk-transaction-abandoned');
          Analytics.trackEvent({
            eventName: Analytics.EventName.MenuAbandoned,
            eventData: {
              reason: 'kiosk-transaction-abandoned',
            },
          });
          // TODO: remove when we have resolved goHome loop in flash
          Log.info('Go Home: order_abandoned bridge message');
          this.goHome();
          break;
        }
        case 'sending_order_after_crash':
          // Only do this after the menu has definitely loaded; otherwise,
          // badness ensues.
          setTimeout(() => {
            gcn.menuView.hideIntroSequence();
            if (message.orderPayload) {
              gcn.orderManager.setOrderFromPayload(message.orderPayload, true);
            } else {
              gcn.orderManager.setOrderFromJSON(message.order, true);
            }
            gcn.menuView.proceedToCheckout({
              fastForwardToSendOrder: true,
            });
            // wait for longer in order to let all the UI load and clear state (otherwise it could
            // clear this restored order as well)
          }, 1500);
          break;
        case 'payment_update':
          {
            const TransactionStep = GCNMenuAppView.TransactionStep;
            switch (message.step) {
              case TransactionStep.CardSwiped:
                this.trigger(Events.CardWasSwiped, this);
                break;
              case TransactionStep.SmartCardInserted:
                this.trigger(Events.SmartCardWasInserted, this);
                break;
              case TransactionStep.AmountConfirmationStarted:
                this.trigger(Events.AmountConfirmationWasStarted, this);
                break;
              case TransactionStep.AmountConfirmationCompleted:
                this.trigger(Events.PaymentProcessingDidStart, this);
                break;
              case TransactionStep.PinEntryIncorrect:
                // Do nothing.
                break;
              case TransactionStep.PinEntryCompleted:
                // Do nothing.
                break;
              case TransactionStep.ApplicationSelectionStarted:
                // TODO: do something interesting here for #1664
                break;
              case TransactionStep.OnlineAuthorization:
                this.trigger(Events.PaymentProcessingDidStart, this);
                break;
              case TransactionStep.None:
                // Do nothing.
                break;
            }
          }
          break;
      }
      return;
    }

    if (message.updateRefundFlowState) {
      // Important log for debugging
      Log.info(
        'updateRefundFlowState:',
        message.updateRefundFlowState,
        message.error ? '(has error)' : '',
      );

      switch (message.updateRefundFlowState) {
        case 'refund_failed':
          this.trigger(Events.RefundDidFail, this, message.error);
          break;
        case 'local_printing':
          this.trigger(Events.RefundDidStartPrintingReceipt, this);
          break;
        case 'local_printing_failed':
          this.trigger(Events.RefundDidFailToPrintReceipt, this);
          break;
        case 'local_printing_done':
          this.trigger(Events.RefundDidFinish, this);
          break;
      }
      return;
    }

    if (_.has(message, 'scannerData')) {
      Log.info(`scanner data: ${message.scannerData}`, this.bridge);
      gcn.orderManager.eventRepo.trackScannerMessageReceived(message.scannerData);
      gcn.sendDeviceLog(
        BiteLogDeviceEvent.ScannerData,
        0,
        message.scannerData || '[Empty Scanner Data]',
      );
      if (message.scannerData) {
        this.getScannerHandler().handleScannerData(message.scannerData);
      }
      return;
    }

    if (message.scaleData) {
      Log.info('scaleData', message.scaleData);
      this.trigger(Events.ScaleDidReadData, message.scaleData);
      // TODO: remove this hacky way to trigger the scale
      // if (this._hasStartedScale) {
      //   this._hasStartedScale = false;
      //   setTimeout(function() {
      //     self.stopReadingScale();
      //   }, 1);
      // } else {
      //   this._hasStartedScale = true;
      //   setTimeout(function() {
      //     self.startReadingScale();
      //   }, 1);
      // }
      return;
    }

    if (message.testCrash) {
      Log.error('going to crash now');
      this.location.crash();
      return;
    }

    if (_.has(message, 'showRefunder')) {
      gcn.menuView.dismissPopup();
      gcn.menuView.dismissDiningOptionModalPopup();
      gcn.menuView.hideIntroSequence();
      gcn.menuView.showStablePopup(
        new GCNAdminView({
          accessCode: message.showRefunder.passcode,
        }),
        'admin-view',
      );
      this._refunderIsShown = true;
      return;
    }

    if (_.has(message, 'screenReaderIsActive')) {
      if (this.screenReaderIsActive !== message.screenReaderIsActive) {
        this.screenReaderIsActive = !!message.screenReaderIsActive;
        useStore.getState().config.setScreenReaderIsActive(this.screenReaderIsActive);
        // Force the menu to be reconfigured for the screen reader;
        this.menu.configureMenuStructure(this.screenReaderIsActive);
        this.menuView.adjustMenuViewForScreenReader(this.screenReaderIsActive);
        this._setMenu(this.menu);

        if (this.screenReaderIsActive) {
          if (this.inactivityTimer) {
            clearTimeout(this.inactivityTimer);
            this.inactivityTimer = 0;
          }
        } else {
          this._setLastActiveAt(+new Date());
          this.goHome();
        }
      }
      return;
    }

    if (message.menuUpdate && window.isGarcon) {
      this.updateGarconMenu();
      return;
    }

    Log.error('bridge message not handled', message);
  },

  async _handleMobileAppMessage(message) {
    switch (message.type) {
      case MobileAppMessageType.OpenCart:
        Log.info('Opening cart');
        CartCoordinator.openCart();
        break;
      case MobileAppMessageType.CloseCart:
        Log.info('Closing cart');
        CartCoordinator.closeCart();
        break;
      case MobileAppMessageType.GetCartSize:
        Log.info('Getting Cart Size');
        return CartCoordinator.getCartSize();
      case MobileAppMessageType.IsCartOpen:
        Log.info('Checking if cart is open');
        return CartCoordinator.isCartOpen();
      case MobileAppMessageType.OnNavigateUp:
        Log.info('Navigating up');
        // This hook can be be used for any navigation that should happen when the user presses the
        // back button on the device. For example, if the user is on the cart screen and presses the
        // back button, we want to close the cart.
        // We don't currently have a generic way to handle this, so this doesn't do anything yet.
        return false;
      default:
        Log.error('Unhandled Mobile App message type', message.type, message);
        break;
    }
    return undefined;
  },

  cartOpened() {
    this.mobileAppBridge?.send(MobileAppMessageType.CartOpened);
  },

  cartClosed() {
    this.mobileAppBridge?.send(MobileAppMessageType.CartClosed);
  },

  cartUpdated(itemCount) {
    this.mobileAppBridge?.send(MobileAppMessageType.CartUpdated, { itemCount });
  },

  startSession(message) {
    this.sessionWasStartedAt = Date.now();
    if (message.selectedLanguage) {
      useStore.getState().config.setLanguage(message.selectedLanguage);
    }
    if (message.kioskSessionStart) {
      gcn.orderManager.setSessionStartAction(message.kioskSessionStart);
    }
    if (
      [
        MenuCoverPromoLinkType.MenuItem,
        MenuCoverPromoLinkType.MenuPage,
        MenuCoverPromoLinkType.MenuSection,
      ].includes(message.menuCoverPromoLinkType)
    ) {
      const linkType = message.menuCoverPromoLinkType;
      const linkTarget = message.menuCoverPromoLinkTarget;
      gcn.activatedMenuCoverPromo = { linkType, linkTarget };
      gcn.orderManager.eventRepo.track(OrderClientEventName.CoverPromoClicked, {
        linkType,
        linkTarget,
      });
      Analytics.trackEvent({
        eventName: Analytics.EventName.CoverPromoClicked,
        eventData: {
          linkType,
          linkTarget,
        },
      });
    }

    this._sessionIsInProgress = true;

    gcn.orderManager.eventRepo.track(OrderClientEventName.SessionStart);
    gcn.sendDeviceLog(BiteLogDeviceEvent.SessionStarted);
    Analytics.track(Analytics.EventName.SessionStart);
    if ('ontouchstart' in document.documentElement) {
      this._setLastActiveAt(this.sessionWasStartedAt);
    }
    // here we delay trigger the `CoverWasOpened` event
    // since we have `introImageView`. The event will be triggered
    // once we call the `_introImageViewDismissed`
    if (!this.menuView._introImageView) {
      this.trigger(BackboneEvents.GCNMenuAppView.CoverWasOpened, this);
    }

    if (gcn.location.get('enablePreRead')) {
      gcn.maitred.preReadCard();
    }
  },

  setLocation(locationJson) {
    useStore.getState().bridge.update('location', locationJson);
    const location = new GcnLocation(locationJson);
    this.location = location;
    if (location.get('users')?.length) {
      this.setUsers(location.get('users'));
    }
    let name = [location.get('orgName'), location.get('name'), location.get('orderChannel')].join(
      '/',
    );

    if (gcn.kiosk) {
      name += `/${gcn.kiosk.get('name')}`;
    } else if (window.deviceName) {
      name += `/${window.deviceName}`;
    }
    Sentry.setUser({
      locationId: location.id,
      locationName: name,
    });

    if (
      !gcn.touchCalibrationConfig.isAdjustedLocally &&
      location.get('touchCalibrationConfig')?.version === gcn.touchCalibrationConfig.version
    ) {
      gcn.touchCalibrationConfig = location.get('touchCalibrationConfig');
    }

    if (!this.menu) {
      return;
    }

    if (!this.menuView) {
      this.render();

      this._setMenu(this.menu);
    }

    this.trigger(BackboneEvents.GCNMenuAppView.LocationDidUpdate, this);
  },

  setMenu(menuJson) {
    useStore.getState().bridge.update('menu', menuJson);
    const menu = new GCNMenu(menuJson);
    menu.configureMenuStructure(this.screenReaderIsActive);
    this._setMenu(menu);
    useStore.getState().config.update();
  },

  _setMenu(menu) {
    const prevMenu = this.menu;

    // Apply the fonts and custom css asynchronously. Otherwise, text that
    // uses custom fonts won't render until the next render call.
    // Additionally, this setTimeout should be done before the render. That
    // way if the render call results in setTimeouts for the purposes of
    // measuring dimensions or something like that, those will pick up the
    // custom css changes and fonts.
    if (menu.appearance) {
      _.each(this.fontsInserted || [], ($font) => {
        $font.remove();
      });

      this.fontsInserted = [];
      switch (window.platform) {
        case BitePlatform.KioskIos:
        case BitePlatform.KioskAndroid:
          if (menu.appearance.get('fontCssStrings')?.length) {
            // Native clients will download fontUrls, cache them and load the font css files from disk
            this.fontsInserted = menu.appearance.get('fontCssStrings').map((fontCss) => {
              return $(`<style>${fontCss}</style>`);
            });
          }
          break;
        case BitePlatform.Flash:
        case BitePlatform.KioskSignageOsGarcon:
          if (menu.appearance.get('fontUrls')?.length) {
            // Web clients can just use the fontUrls directly
            this.fontsInserted = menu.appearance.get('fontUrls').map((fontUrl) => {
              return $(`<link href="${fontUrl}" rel="stylesheet" type="text/css">`);
            });
          }
          break;
      }

      const $head = $('head');
      _.each(this.fontsInserted, (font) => {
        $head.append(font);
      });

      const $body = $('body');
      const wallpaperImage = menu.appearance.get('wallpaperImage');
      if (_.size(wallpaperImage)) {
        const image = wallpaperImage[0];
        if (image && image.url) {
          gcn.requestImageByUrl(image.url, (err, imgPath) => {
            $body.css('background-image', `url(${imgPath})`);
          });
        }
      }
    }

    const customCssUrl = menu.getCustomCssUrl();
    if (prevMenu && prevMenu.customCssUrl !== customCssUrl && this.$customCssUrlScript) {
      this.$customCssUrlScript.remove();
      this.$customCssUrlScript = null;
    }
    if (customCssUrl.length) {
      this.$customCssUrlScript = $(
        `<link rel="stylesheet" type="text/css" href="${customCssUrl}">`,
      );
      $('head').append(this.$customCssUrlScript);
    }

    /**
     * @deprecated
     * Delete once all locations are migrated to new builds version
     */
    const customCss = menu.getCustomCss();
    if (prevMenu && prevMenu.customCss !== customCss && this.$customCssScript) {
      this.$customCssScript.remove();
      this.$customCssScript = null;
    }
    if (!customCssUrl && customCss.length) {
      this.$customCssScript = $(`<style>${customCss}</style>`);
      $('head').append(this.$customCssScript);
    }

    const promoSections = prevMenu ? prevMenu.getPromoSections() : [];
    promoSections.forEach((promoSection) => {
      menu.registerPromoSection(promoSection);
    });

    this.menu = menu;
    if (!this.location) {
      return;
    }

    if (!this.menuView) {
      this.render();
    }

    setTimeout(() => {
      // The settings manager needs the menu to get all its default values.
      if (!this.initializeSettings) {
        useStore.getState().config.reset();
        this.initializeSettings = true;
      }

      this.trigger(BackboneEvents.GCNMenuAppView.MenuDidUpdate, this);

      // At this point every view has requested all the new images
      this.cleanUnusedImagesFromCache();
    }, 1);
  },

  setUsers(usersJSON) {
    this.users = _.map(usersJSON, (userJSON) => {
      return new GCNModel(userJSON);
    });
  },

  hasUserWithAccessCode(accessCode, right) {
    if (window.isKioskPreview) {
      return accessCode === '1111';
    }
    if (this.bridge) {
      return _.any(this.users, (user) => {
        return user.get('accessCode') === accessCode && _.contains(user.get('rights'), right);
      });
    }
    return false;
  },

  _resetInactivityTimer() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }

    let inactivityDuration = 2 * 60 * 1000;
    // Sometimes this gets called too early before we have location. In that
    // case it's ok to either wait for another tap (which should come soon) or
    // for the 12 minutes instead of 2. Big deal.
    if (gcn.location) {
      inactivityDuration = gcn.menu.settings.get('menuTimeout') || 2 * 60 * 1000;
    }
    if (!this._inCriticalPeriod && !window.isFlash && !this.screenReaderIsActive) {
      this.inactivityTimer = setTimeout(() => {
        if (gcn.menu.settings.get('promptBeforeTimeout') && gcn.orderManager.getOrderSize()) {
          const alertText = localizeStr(Strings.NEED_MORE_TIME, [], function (string) {
            return string.split('\n').join('<br />');
          });
          const confirmView = new GCNAlertView({
            text: alertText,
            okCallback() {
              gcn.menuView.dismissModalPopup();
            },
            okText: localizeStr(Strings.YES),
            cancelCallback() {
              gcn.orderManager.eventRepo.trackSessionAbandon('menu-inactivity-with-prompt');
              Analytics.trackEvent({
                eventName: Analytics.EventName.MenuAbandoned,
                eventData: {
                  reason: 'menu-inactivity-with-prompt',
                },
              });
              gcn.goHome();
            },
            cancelText: localizeStr(Strings.EXIT),
            timeout: 7 * 1000,
          });
          gcn.menuView.showModalPopup(confirmView);
        } else {
          gcn.orderManager.eventRepo.trackSessionAbandon('menu-inactivity-without-prompt');
          Analytics.trackEvent({
            eventName: Analytics.EventName.MenuAbandoned,
            eventData: {
              reason: 'menu-inactivity-without-prompt',
            },
          });
          // TODO: remove when we have resolved goHome loop in flash
          Log.info('Go Home: reset inactivity timer');
          gcn.goHome();
        }
      }, inactivityDuration);
    }
  },

  _setLastActiveAt(...args) {
    GCNAppView.prototype._setLastActiveAt.apply(this, args);

    this._resetInactivityTimer();
  },

  isRefunderShown() {
    return this._refunderIsShown;
  },

  getLanguage() {
    return useStore.getState().config.language;
  },

  sendDeviceLog(status, systemCode, message, takeScreenshot) {
    if (this.bridge) {
      const params = {
        event: FlashBridgeMessage.DEVICE_GAZEBO_LOG,
        status,
        systemCode,
      };
      if ((message || '').length) {
        params.message = message;
      }
      if (takeScreenshot) {
        params.takeScreenshot = true;
      }
      this.bridge.send(params);
    }
  },

  /**
   * @param {string} message
   */
  showNativeToast(message) {
    if (this.bridge) {
      const params = {
        event: FlashBridgeMessage.SHOW_NATIVE_TOAST,
        message,
      };
      this.bridge.send(params);
    } else {
      Log.debug('Sending native toast', message);
    }
  },

  notifyUserDidEnterCriticalPeriod(criticalPeriodName) {
    if (this.bridge) {
      this.bridge.send({
        event: FlashBridgeMessage.USER_DID_ENTER_CRITICAL_PERIOD,
        name: criticalPeriodName,
      });
    }

    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
      this._inCriticalPeriod = true;
    }
  },

  notifyOrderRecoveryIsReady() {
    this._sendBridgeEvent(FlashBridgeMessage.ORDER_RECOVERY_IS_READY);
  },

  notifyUserDidLeaveCriticalPeriod(criticalPeriodName) {
    Log.info('leaving critical period', criticalPeriodName);
    if (this.bridge) {
      this.bridge.send({
        event: FlashBridgeMessage.USER_DID_LEAVE_CRITICAL_PERIOD,
        name: criticalPeriodName,
      });
    }

    this._inCriticalPeriod = false;
    this._setLastActiveAt(+new Date());
  },

  notifyUserDidEndPaymentProcess(callback) {
    this._sendBridgeEvent(FlashBridgeMessage.USER_DID_END_PAYMENT_PROCESS, callback);
  },

  notifyUserDidTapCancelPayment(callback) {
    this._sendBridgeEvent(FlashBridgeMessage.USER_DID_TAP_CANCEL_PAYMENT, callback);
  },

  notifyUserDidFocusOnTextField() {
    this._sendBridgeEvent(FlashBridgeMessage.USER_DID_FOCUS_ON_TEXT_FIELD);
  },

  notifyUserDidFocusOutOfTextField() {
    this._sendBridgeEvent(FlashBridgeMessage.USER_DID_FOCUS_OUT_OF_TEXT_FIELD);
  },

  _sendBridgeEvent(event, callback) {
    if (this.bridge) {
      this.bridge.send({ event }, callback);
    }
  },

  requestToPrintReceipt(printPayload, callback) {
    if (this.bridge) {
      this.bridge.send(
        {
          event: FlashBridgeMessage.USER_DID_REQUEST_TO_PRINT_RECEIPT,
          printPayload,
        },
        callback,
      );
    } else {
      setTimeout(() => {
        Log.info('requestToPrintReceipt', printPayload);
        callback({});
      }, 2000);
    }
  },

  handleScannerData(scannerData) {
    Log.info('GCNMenuAppView.handleScannerData', scannerData);
    const isIntroImageViewShown = this.menuView._introImageView?.isShown();
    if (
      this.menuView.isShowingPopup() ||
      !this.sessionWasStartedAt ||
      this.menuView.inFullScreen() ||
      (isIntroImageViewShown && gcn.location.getDiningOptions().length > 1)
    ) {
      return;
    }

    // If we have an intro image our scan can hide it
    if (isIntroImageViewShown) {
      Log.info('Session started but we have an intro image, hiding it.');
      this.menuView._introImageView.hide();
    }

    // Find item and price option with this data.
    const weightedBarcodeMatches = scannerData.match(/^I([0-9]+)Q([0-9]+)$/);
    const barcode = weightedBarcodeMatches ? weightedBarcodeMatches[1] : scannerData;
    const result = this.menu.getMenuItemAndPriceOptionWithBarcode(barcode);

    if (result) {
      const { item, priceOption } = result;

      const menuItemId = item.id;
      const menuItemName = item.displayName();
      gcn.orderManager.eventRepo.trackMenuItemScan(menuItemId, menuItemName);
      // Make an ordered item with ordered price option.
      const orderedItem = new GCNOrderedItem(null, { item });
      orderedItem.setPriceOption(priceOption);
      orderedItem.setBarcode(scannerData);

      // only consider this menuItem as a weighted item IFF `saleUnit` is set & `weightedBarcodeMatches`
      const isMenuItemWeighted = priceOption.attributes.saleUnit && weightedBarcodeMatches;
      if (isMenuItemWeighted) {
        const weightStrInMinorUnits = weightedBarcodeMatches[2];
        const weightDigits = weightStrInMinorUnits.split('');
        const weight =
          weightDigits.length < 3
            ? parseFloat(`0.${weightDigits.join('')}`, 10)
            : parseFloat(
                `${weightDigits.slice(0, -2).join('')}.${weightDigits.slice(-2).join('')}`,
                10,
              );
        Analytics.trackEvent({
          eventName: Analytics.EventName.MenuItemScanned,
          eventData: {
            itemName: menuItemName,
            isWeighted: true,
          },
        });
        // if this has addons - now show the menuItem detail popup else add item to order
        if (priceOption.addonSets.length) {
          const options = {
            weight,
            barcode: scannerData,
          };
          this.menuView.showItemDetails(null, item, priceOption.id, null, options);
        } else {
          // assumption that there's only one item in the barcode scanned
          orderedItem.setWeightAndQuantityOnSelectedPriceOption(weight, 1);
          gcn.orderManager.addToOrder(orderedItem, 1);
        }
      } else {
        Analytics.trackEvent({
          eventName: Analytics.EventName.MenuItemScanned,
          eventData: {
            itemName: menuItemName,
            isWeighted: false,
          },
        });
        gcn.orderManager.addToOrder(orderedItem, 1);
      }

      this.menuView.showToast(item);
      this._resetInactivityTimer();
    } else {
      gcn.menuView.showSimpleAlert('Unable to find item being scanned. Please see a staff member.');
      Log.error('No information found for :', scannerData);
    }
  },

  pushScannerHandler(handler) {
    if (handler.handleScannerData) {
      // Garcon - explicitly start scanner functionality for loyalty / gift card
      if (handler instanceof GCNLoyaltyBarcodeView || handler instanceof GcnGiftCardBarcodeView) {
        this.startScanner();
      }
      this._scannerHandlers.push(handler);
    } else {
      Log.error('Tried pushing handler without method.', handler);
    }
  },

  popScannerHandler() {
    this._scannerHandlers.pop();
  },

  getScannerHandler() {
    return _.last(this._scannerHandlers);
  },

  // Removes a specific scanner handler from the array of handlers.
  removeScannerHandler(handler) {
    // Garcon - explicitly stop scanner functionality for loyalty
    if (handler instanceof GCNLoyaltyBarcodeView) {
      this.stopScanner();
    }
    this._scannerHandlers = this._scannerHandlers.filter((scannerHandler) => {
      return scannerHandler !== handler;
    });
  },

  startScanner() {
    if (this.bridge && window.isGarcon) {
      this.bridge.send({ event: FlashBridgeMessage.START_SCANNER });
    }
  },

  stopScanner() {
    if (this.bridge && window.isGarcon) {
      this.bridge.send({ event: FlashBridgeMessage.STOP_SCANNER });
    }
  },

  startReadingScale() {
    if (this.bridge) {
      this.bridge.send({ event: 'startReadingScale' });
    } else if (!gcn.location.isDeliKioskDemo()) {
      this._startFakeScaleTimer();
    }
  },

  _startFakeScaleTimer() {
    const self = this;
    this._fakeScaleTimer = setTimeout(() => {
      if (self._fakeScaleTimer) {
        self.handleBridgeMessage({
          scaleData: {
            state: 0,
            unit: MenuItemSaleUnit.LB,
            weight: 1.2,
          },
        });
        self._startFakeScaleTimer();
      }
    }, 1000);
  },

  stopReadingScale() {
    if (this.bridge) {
      this.bridge.send({ event: 'stopReadingScale' });
    } else {
      clearTimeout(this._fakeScaleTimer);
      this._fakeScaleTimer = 0;
    }
  },

  /**
   * @param {string} sectionId
   * @param {MenuItem} item
   * @param {string | undefined} upsellScreen name of the screen from which this recommended item
   * was tapped; if the item was not recommended, leave this undefined.
   */
  notifyUserDidTapMenuItem(
    sectionId,
    item,
    upsellScreen,
    recommendationDisplayLocationDescription,
  ) {
    const priceOptionId = item.priceOptions[0].id;
    Log.info('didSelectMenuItem sectionId', sectionId, 'itemId', item.id, 'poId', priceOptionId);
    const section = this.menu.getMenuSectionWithId(sectionId);
    if (!section) {
      gcn.menuView.showSimpleAlert(localizeStr(Strings.UNAVAILABLE_ITEM));
      return;
    }
    Log.info('didSelectMenuItem section', !!section);

    if (!this._itemViewHistory) {
      this._itemViewHistory = [];
    }
    this._itemViewHistory.push(item.id);
    this.updateSessionData({
      numberOfItemsViewed: this._itemViewHistory.length,
    });

    const options = {
      recommendationDisplayLocationDescription,
    };
    this.menuView.showItemDetails(section, item, priceOptionId, upsellScreen, options);
  },

  updateSessionData(sessionData) {
    if (this.bridge) {
      const data = {
        event: FlashBridgeMessage.UPDATED_SESSION_DATA,
        sessionData,
      };
      this.bridge.send(data);
    }
  },

  sendCheckoutSession(checkoutSession) {
    Log.info('sendOrder', checkoutSession);
    gcn.orderManager.persistGuestDataAndId();
    const orderPayload = gcn.orderManager.getOrderPayload();
    const currentOrder = gcn.orderManager.getOrder().clone();
    const orderUpdatePayload = gcn.orderManager.getOrderUpdatePayload();
    const chargeTotal = gcn.orderManager.getGrandTotal();
    const hasEcommPayment = gcn.orderManager.hasEcommPaymentMethod();
    if (this.bridge) {
      (async () => {
        await OrderSender.startOrderFlow(
          orderPayload,
          orderUpdatePayload,
          currentOrder,
          hasEcommPayment,
          checkoutSession,
          chargeTotal,
        );
      })();
    } else {
      setTimeout(() => {
        this.handleBridgeMessage(
          {
            updatePaymentFlowState: 'sending_order',
          },
          () => {},
        );
      }, 1000);
      setTimeout(() => {
        const order = checkoutSession.orderPayload;
        this.handleBridgeMessage(
          {
            updatePaymentFlowState: 'sending_order_success',
            order: {
              ...order,
              clientNumber: 'J18',
              transactions:
                order.paymentDestination === OrderPaymentDestination.Cashier
                  ? []
                  : [
                      GCNTransaction.demoPayTransactionWithAmount(
                        order.total,
                        order.clientId,
                        gcn.location.useOrdersApiV2()
                          ? PaymentsApiVersion.V2
                          : PaymentsApiVersion.V1,
                        gcn.location.get('orgId'),
                        gcn.location.id,
                      ),
                    ],
            },
          },
          () => {},
        );
      }, 2500);
      setTimeout(() => {
        this.handleBridgeMessage(
          {
            updatePaymentFlowState: 'local_printing',
            order: checkoutSession.orderPayload,
          },
          () => {},
        );
      }, 3000);
      setTimeout(() => {
        this.handleBridgeMessage(
          {
            updatePaymentFlowState: 'local_printing_done',
            order: checkoutSession.orderPayload,
          },
          () => {},
        );
      }, 4500);
      setTimeout(() => {
        this.handleBridgeMessage(
          {
            updatePaymentFlowState: 'order_complete',
            order: checkoutSession.orderPayload,
          },
          () => {},
        );
      }, 5000);
    }
  },

  goHome(skipReload = false) {
    gcn.menuView.showSpinner(str(Strings.CLEARING_SESSION));
    Sentry.addBreadcrumb({
      category: 'fulfillment-method',
      message: FlashBridgeMessage.GO_HOME,
      level: 'info',
    });
    gcn.orderManager.eventRepo.track(OrderClientEventName.SessionEnd);

    Analytics.trackEvent({
      eventName: Analytics.EventName.SessionComplete,
      eventData: {
        duration: Date.now() - this.sessionWasStartedAt,
      },
    });

    const commitEventsCompleteCallback = () => {
      this.clearGcnSession(skipReload);
    };

    gcn.orderManager.commitEvents(commitEventsCompleteCallback);
  },

  clearGcnSession(skipReload = false) {
    Log.info('Clearing session');
    this._sessionIsInProgress = false;

    if (!window.isFlash) {
      // Combine all the touchVsClick maps into one so that we could send it to GA.
      // GA does not accept nested maps/objects inside eventData.
      const eventData = {};
      _.each(window.clickCountByButtonName, (clickCount, buttonName) => {
        eventData[`clickCount_${buttonName}`] = clickCount;
      });
      _.each(window.tapCountByButtonName, (tapCount, buttonName) => {
        eventData[`tapCount_${buttonName}`] = tapCount;
      });
      _.each(window.touchStartCountByButtonName, (touchStartCount, buttonName) => {
        eventData[`touchStartCount_${buttonName}`] = touchStartCount;
      });
      _.each(window.touchEndCountByButtonName, (touchEndCount, buttonName) => {
        eventData[`touchEndCount_${buttonName}`] = touchEndCount;
      });
      _.each(window.touchHoldCountByButtonName, (touchHoldCount, buttonName) => {
        eventData[`touchHoldCount_${buttonName}`] = touchHoldCount;
      });
      Analytics.trackEvent({
        eventName: Analytics.EventName.TouchVsClick,
        eventData,
      });
      window.clickCountByButtonName = {};
      window.tapCountByButtonName = {};
      window.touchStartCountByButtonName = {};
      window.touchEndCountByButtonName = {};
      window.touchHoldCountByButtonName = {};
    }

    this.maitred.clear();
    this._hasMockGuest = false;
    this._refunderIsShown = false;
    this.activatedMenuCoverPromo = null;
    this.sessionWasStartedAt = window.isFlash ? Date.now() : 0;

    useStore.getState().config.reset();

    // Reset the order after clearing config state since config state resets the language and all
    // the views that were showing something order related need to refresh
    this.orderManager.clearSession();

    GcnRecoTracker.clearRecos();

    if (window.isFlash) {
      // Reload after 100ms to let any last url requests go out
      setTimeout(() => {
        if (skipReload) {
          window.close();
          return;
        }
        window.location.reload();
      }, 100);
      return;
    }

    // hide aria on menu & nav
    this.menuView._setAriaHiddenOnMenuAndNav(true);

    this._inCriticalPeriod = false;
    if (this.screenReaderIsActive && this.menu) {
      this.screenReaderIsActive = false;
      this.menu.configureMenuStructure(this.screenReaderIsActive);
      this.menuView.adjustMenuViewForScreenReader(this.screenReaderIsActive);
      this._setMenu(this.menu);
    }
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = 0;
    }

    if (this._itemViewHistory) {
      this._itemViewHistory = [];
    }

    this.menu.deregisterPromoSections();

    if (this._newMenuJson) {
      // If we have a pending menu update, apply it now that we're no longer in a session.
      const menuJson = this._newMenuJson;
      // Null out the pending menu update first to prevent us from potentially falling into a
      // bottomless recursion where somehow setting the menu triggers going home.
      this._newMenuJson = null;
      this.setMenu(menuJson);
    }

    // Do all the UI work right after the bridge phones home and start closing the cover
    setTimeout(() => {
      this.guestManager.clear();
      this.loyaltyManager.clear(true);
      useStore.getState().config.reset();
      ModalService.closeAll();
      // destroys all popups and overlays, shows intro sequence, resets views and menu navigation
      this.menuView.clearSession();

      if (this.bridge) {
        this.bridge.send({
          event: FlashBridgeMessage.GO_HOME,
        });
      } else {
        setTimeout(() => {
          window.gcn.handleBridgeMessage({ sessionStart: true });
        });
      }
      Log.info('Session cleared and ended');
      gcn.menuView.dismissSpinner();

      // if there is no bridge, the cover will not be shown
      if (!this.bridge && window.isKioskPreview) {
        this.showCover();
      }
    }, 1);
  },

  // This is used by the web react cart ui
  startCheckoutSequence() {
    this.menuView.startCheckoutSequence();
  },

  render() {
    if (!this.menuView) {
      this.menuView = new GCNMenuView();
    }

    this.$el.html(this.menuView.render().$el);
    // Add a portal for react modal components
    this.$el.prepend('<div id="react-portal" className="tw-fixed tw-z-[100]"></div>');
  },
});

GCNMenuAppView.TransactionStep = {
  None: 0,
  CardSwiped: 3,
  SmartCardInserted: 5,
  AmountConfirmationStarted: 21,
  AmountConfirmationCompleted: 23,
  PinEntryIncorrect: 32,
  PinEntryCompleted: 33,
  ApplicationSelectionStarted: 41,
  OnlineAuthorization: 51,
};

window.GCNMenuAppView = GCNMenuAppView;
