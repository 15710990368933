import { Strings } from '@biteinc/common';

import type { GcnMenuItem } from '~/types/gcn_menu_item';

import GcnHtml from '../gcn_html';
import { GCNRecoManager } from '../gcn_reco_manager';
import { GCNRouterHelper } from '../gcn_router_helper';
import { str } from '../localization/localization';
import Analytics from '../utils/analytics';
import GcnView from './gcn_view_ts';

export default class GcnComboUpsellView extends GcnView {
  sectionId: string;
  upsellScreen?: string;
  recommendationDisplayLocationDescription?: string;
  originalModel: GcnMenuItem;
  originalSectionId: string;
  model: GcnMenuItem;
  onAccept: (() => void) | undefined;
  onDismiss: (() => void) | undefined;

  constructor(options: {
    model: GcnMenuItem;
    sectionId: string;
    upsellScreen?: string;
    recommendationDisplayLocationDescription?: string;

    originalModel: any;
    originalSectionId: string;

    onAccept?: () => void;
    onDismiss?: () => void;
  }) {
    super(options);

    this.model = options.model;
    this.sectionId = options.sectionId;
    this.upsellScreen = options.upsellScreen;
    this.recommendationDisplayLocationDescription =
      options.recommendationDisplayLocationDescription;

    this.originalModel = options.originalModel;
    this.originalSectionId = options.originalSectionId;

    this.onAccept = options.onAccept;
    this.onDismiss = options.onDismiss;
  }

  onNavToComboItem(): void {
    Analytics.track(Analytics.EventName.UpsellComboAccepted);

    if (this.onAccept) {
      this.$('.button-group').hide();
      this.$('.accepted-animation').show();
      setTimeout(() => {
        this.onAccept!();
      }, 750);
      return;
    }

    gcn.menuView.dismissStablePopup();

    GCNRouterHelper.navToItem(this.model.id, this.sectionId);
    gcn.notifyUserDidTapMenuItem(
      this.sectionId,
      this.model as unknown as GcnMenuItem,
      GCNRecoManager.trackingLabel('combo-upsell-view', 0, this.model),
      this.recommendationDisplayLocationDescription,
    );
  }

  onNavToItem(): void {
    Analytics.track(Analytics.EventName.UpsellComboDismissed);

    if (this.onDismiss) {
      this.onDismiss();
      return;
    }

    gcn.menuView.dismissStablePopup();

    GCNRouterHelper.navToItem(this.originalModel.id, this.originalSectionId);
    gcn.notifyUserDidTapMenuItem(
      this.originalSectionId,
      this.originalModel as unknown as GcnMenuItem,
      this.upsellScreen,
      this.recommendationDisplayLocationDescription,
    );
  }

  render(): this {
    const comboItemImageUrl = this.originalModel.get('comboUpsell')?.comboImage?.[0]?.url;
    const imageUrl = comboItemImageUrl ?? this.model.getImageUrlOrPlaceholder();

    let priceDifference = '';
    if (gcn.menu.settings.get('comboUpsell')?.showPriceDifference) {
      const originalPrice = this.originalModel.priceOptions[0].getPriceIncludingDefaultMods();
      const comboPrice = this.model.priceOptions[0].getPriceIncludingDefaultMods();
      const priceDifferenceText = `${GcnHtml.htmlFromPrice(comboPrice - originalPrice)}`;
      priceDifference += ` +${priceDifferenceText}`;
    }

    // prettier-ignore
    const html =
    '<div class="make-it-a-combo-view" role="dialog">' +
      `<div class="header" tabIndex="-1" aria-label="${str(Strings.MAKE_IT_A_COMBO)}">${str(Strings.MAKE_IT_A_COMBO)}</div>` +
        (imageUrl ? `<div class="image" style="background-image: url('${imageUrl}')"></div>` : '') +
      '<div class="item-details">' +
        `<div class="name">${this.model.displayName()}</div>` +
        (priceDifference ? `<div class="name">${priceDifference}</div>` : '') +
      '</div>' +
      '<div class="button-container">' +
        '<div class="button-group">' +
          `<div role="button" class="button yes" id="yes-combo-button">${str(Strings.MAKE_IT_A_COMBO_ACCEPT)}</div>` +
          `<div role="button" class="button no" id="no-combo-button">${str(Strings.MAKE_IT_A_COMBO_REJECT)}</div>` +
        '</div>' +
        '<div class="accepted-animation">' +
          '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" height="80px" width="80px">' +
            '<circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>' +
            '<polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>' +
          '</svg>' +
        '</div>' +
      '</div>' +
    '</div>';

    this.$el.html(html);

    this.$('#yes-combo-button').onButtonTapOrHold('cuvYesCombo', () => {
      this.onNavToComboItem();
    });

    this.$('.button.no').onButtonTapOrHold('cuvNoCombo', () => {
      this.onNavToItem();
    });

    this.$('.accepted-animation').hide();

    if (gcn.screenReaderIsActive) {
      this.$('.header').requestFocusAfterDelay();
    }

    return this;
  }
}
