import { Strings } from '@biteinc/common';
import { IntegrationSystem } from '@biteinc/enums';

import { localizeStr } from '~/app/js/localization/localization';

export function requiresPinEntry(): boolean {
  switch (gcn.location.get('giftCardI9n')) {
    case IntegrationSystem.Olo:
      return true;
    default:
      return false;
  }
}

export function getCssClassForCardReader(): 'ingenico' | 'msr' | 'hid' | 'verifone' | null {
  switch (gcn.location.get('giftCardI9n')) {
    case IntegrationSystem.WorldpayStoredValue:
    case IntegrationSystem.Worldpay:
    case IntegrationSystem.Archer:
    case IntegrationSystem.FreedomPayKiosk:
      return 'ingenico';

    case IntegrationSystem.EigenStoredValue:
      return 'verifone';

    case IntegrationSystem.Atrium: {
      const hasHid = gcn.kiosk?.get('hasHid');
      const hasMsr = gcn.kiosk?.get('hasMsr');
      switch (true) {
        case hasHid && hasMsr:
          return 'msr';
        case hasHid:
          return 'hid';
        case hasMsr:
          return 'msr';
        default:
          return null;
      }
    }

    default:
      if (gcn.kiosk?.get('hasMsr')) {
        return 'msr';
      }
      return null;
  }
}

export function getSecondaryCssClassForCardReader(): 'hid' | null {
  switch (gcn.location.get('giftCardI9n')) {
    case IntegrationSystem.WorldpayStoredValue:
    case IntegrationSystem.Worldpay:
    case IntegrationSystem.Archer:
    case IntegrationSystem.FreedomPayKiosk:
      return null;

    case IntegrationSystem.Atrium: {
      const hasHid = gcn.kiosk?.get('hasHid');
      const hasMsr = gcn.kiosk?.get('hasMsr');
      switch (true) {
        case hasHid && hasMsr:
          return 'hid';
        default:
          return null;
      }
    }

    default:
      return null;
  }
}

export function getNativeStoredValueInstructionText(): string {
  switch (gcn.location.get('giftCardI9n')) {
    case IntegrationSystem.Worldpay:
    case IntegrationSystem.WorldpayStoredValue:
    case IntegrationSystem.Archer:
    case IntegrationSystem.FreedomPayKiosk:
      return localizeStr(Strings.GIFT_CARD_SUBTITLE);

    case IntegrationSystem.Atrium: {
      const hasHid = gcn.kiosk?.get('hasHid');
      const hasMsr = gcn.kiosk?.get('hasMsr');
      switch (true) {
        case hasHid && hasMsr:
          return localizeStr(Strings.STUDENT_CARD_SUBTITLE_HID_MSR);
        case hasHid:
          return localizeStr(Strings.STUDENT_CARD_SUBTITLE_HID);
        case hasMsr:
          return localizeStr(Strings.STUDENT_CARD_SUBTITLE_MSR);
        default:
          return '';
      }
    }

    default:
      if (gcn.kiosk?.get('hasMsr')) {
        return localizeStr(Strings.SWIPE_YOUR_GIFT_CARD);
      }
      return '';
  }
}
