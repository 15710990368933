import Backbone from 'backbone';
import _ from 'underscore';

import { ErrorCode, Log } from '@biteinc/common';
import type { OrderedItem } from '@biteinc/core-react';
import { StringHelper } from '@biteinc/core-react';
import type { DeprecatedRecommendationSource, RecommendationSource } from '@biteinc/enums';

import type { GcnOrderedItem } from '../../types/gcn_ordered_item';
import type { GuestIdentifiers } from '../../types/recommendation';
import type { Callback } from './gcn_maitred_request_manager';
import GcnRecoTracker from './gcn_reco_tracker';
import type { GcnModelData } from './models/gcn_model_ts';
import { GCNOrderedItem } from './models/gcn_ordered_item';
import LocalStorage from './utils/local_storage';
import { asCallback } from './utils/promises';

type GuestData = GcnModelData &
  Readonly<{
    orderedItems?: OrderedItem[];
    recos?: {
      _id: string;
      type: RecommendationSource | DeprecatedRecommendationSource;
    }[];
  }>;

export default class GuestManager extends Backbone.Model {
  public static readonly Events = {
    GuestDidChange: 'guestDidChange',
  };

  private orderedItemById: Record<string, GcnOrderedItem | undefined> = {};

  private recoSourceByItemId: Record<
    string,
    RecommendationSource | DeprecatedRecommendationSource | undefined
  > = {};

  private guestId: string;

  private guestIdIsStored: boolean;

  guestRecognized: boolean;

  initialize(): void {
    this.guestRecognized = false;

    if (window.isFlash) {
      const storedGuestId = LocalStorage.getItem('guestId');
      if (storedGuestId) {
        this.guestIdIsStored = true;
      }
      this.guestId = storedGuestId || StringHelper.newMongoId();
    } else {
      this.guestId = StringHelper.newMongoId();
    }
  }

  private regenerateGuestId(skipResetGuestRecognized: boolean = false): void {
    this.guestId = StringHelper.newMongoId();
    this.guestIdIsStored = false;
    if (!skipResetGuestRecognized) {
      this.guestRecognized = false;
    }
    LocalStorage.removeItem('guestId');
  }

  private clearRecommendations(): void {
    this.orderedItemById = {};
    this.recoSourceByItemId = {};
  }

  // TODO: persist guestId at generation time once useRecommendationsApiV2 is rolled out to everyone
  persistGuestId(): void {
    if (!window.isFlash) {
      return;
    }

    this.guestIdIsStored = true;
    LocalStorage.setItem('guestId', this.guestId);
  }

  setGuestId(guestId: string): void {
    this.guestId = guestId;
    if (this.guestIdIsStored) {
      // Persist the guest ID if it is stored because the stored value may be outdated
      this.persistGuestId();
    }
  }

  getGuestId(): string {
    return this.guestId;
  }

  fetchGuestRecommendations(): void {
    // Clear all state so that we don't mix up previous guest data with the new one
    this.clearRecommendations();

    asCallback(GcnRecoTracker.getFirstLoadRecommendations(gcn.maitred), (err, recommendations) => {
      if (err) {
        Log.error('error fetching initial recommendations', err);
        return;
      }

      recommendations!.forEach((recommendation) => {
        this.recoSourceByItemId[recommendation.itemId] = recommendation.source;
        if (recommendation.orderedItem) {
          const orderedItem = new GCNOrderedItem(recommendation.orderedItem);
          this.orderedItemById[orderedItem.id] = orderedItem;
        }
      });

      this.trigger(GuestManager.Events.GuestDidChange, this);
    });
  }

  fetchGuest(guestIdentifiers?: GuestIdentifiers): void {
    const callback: Callback = (err, data) => {
      Log.info('fetched guest', err, data?.guest);
      if (err) {
        if (
          err.code === ErrorCode.UrlParamModelNotFound &&
          err.message === 'The guest you were looking for could not be found.'
        ) {
          Log.info('guest gone, removing saved guestId');
          this.regenerateGuestId();
        }
      } else {
        this.setGuestData(data.guest);
      }
    };

    if (this.guestIdIsStored) {
      gcn.maitred.fetchGuest(this.guestId, callback);
      return;
    }

    const hasGuestIdentifiers =
      guestIdentifiers &&
      Object.values(guestIdentifiers).some((guestIdentifier) => {
        return !!guestIdentifier;
      });

    if (hasGuestIdentifiers) {
      gcn.maitred.fetchGuestByIdentifiers(guestIdentifiers, callback);
    }
  }

  forgetGuest(callback: Callback): void {
    gcn.maitred.forgetGuest(this.guestId, (err) => {
      if (err) {
        callback(err);
        return;
      }

      this.clear();
      callback();
    });
  }

  guestWasRecognized(): boolean {
    return !!this.guestRecognized;
  }

  hasRecos(): boolean {
    return _.size(this.recoSourceByItemId) > 0;
  }

  hasOrderedItems(): boolean {
    return _.size(this.orderedItemById) > 0;
  }

  getOrderedItemWithId(orderedItemId: string): GcnOrderedItem | undefined {
    return this.orderedItemById[orderedItemId];
  }

  getRecoItemIds(): string[] {
    return Object.keys(this.recoSourceByItemId);
  }

  hasRecoItemId(itemId: string): boolean {
    // recoType could be 0
    return _.has(this.recoSourceByItemId, itemId);
  }

  recoSourceForRecoItemId(
    itemId: string,
  ): RecommendationSource | DeprecatedRecommendationSource | undefined {
    return this.recoSourceByItemId[itemId];
  }

  setGuestData(guestData: GuestData | null, isFacialReco?: boolean): void {
    // Ignore incoming data if it's the same guest we already have.
    // This can happen if the person looked away from the camera for 10-15 seconds and then we
    // re-recognize them, fetch the same guest and send it to gcn.
    if (guestData && guestData._id === this.guestId) {
      return;
    }

    // If we already recognized this guest, we should not overwrite the guest data
    // with the new guest data. Instead;
    // 1. If the new guest data is null, we should continue to use the old guest data
    // it's likely the guest is simply out of the field of view of the camera
    // 2. If the new guest data is not null, i.e. a new guest was recognized,
    // we should generate a new guest ID (because it's possible this new guest is
    // actually using the kiosk), and we don't know that they would opt in to delphi.
    if (this.guestWasRecognized()) {
      if (guestData) {
        Log.info('Had a guest, and gotst a new guest, generating a new guest ID');
        this.regenerateGuestId(true);
      }
      Log.info('Had a guest, and gotst an empty guest, ignoring');
      return;
    }

    // Clear all state so that we don't mix up previous guest data with the new one
    this.clearRecommendations();

    if (guestData) {
      this.guestId = guestData._id;
      this.guestRecognized = !!isFacialReco;

      if (!gcn.location.get('useRecommendationsApiV2')) {
        guestData.orderedItems?.forEach((orderedItemJson) => {
          const orderedItem = new GCNOrderedItem(orderedItemJson);
          // The item might be missing if it's no longer offered;
          // But we have to check that it's currently offered on menu; otherwise,
          // we might recommend a "lunch" item at "dinner".
          if (orderedItem.item && gcn.menu.isShowingItemWithId(orderedItem.item.id)) {
            this.orderedItemById[orderedItem.id] = orderedItem;
          }
        });

        guestData.recos?.forEach((reco) => {
          this.recoSourceByItemId[reco._id] = reco.type;
        });
        // ensure we have a fulfillment method before fetching recommendations
      } else if (gcn.orderManager.getFulfillmentMethod()) {
        this.fetchGuestRecommendations();
      }
    } else {
      this.regenerateGuestId();
    }

    this.trigger(GuestManager.Events.GuestDidChange, this);
  }

  clear(): this {
    this.regenerateGuestId();
    this.clearRecommendations();

    return this;
  }
}
