import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNView } from './gcn_view';

export const GCNScannerToastView = GCNView.extend({
  className: 'scanner-toast-view',
  template: _.template(
    // prettier-ignore
    '<div class="image"></div>' +
    '<div class="text">' +
      '<div class="name"><%= itemName %></div>' +
      '<div class="label "><%= label %></div>' +
    '</div>',
  ),

  render() {
    this.$el.html(
      this.template({
        itemName: this.model.displayName(),
        label: localizeStr(Strings.ADDED_TO_ORDER),
      }),
    );

    const $image = this.$('.image');
    const images = this.model.get('images');
    if (_.size(images)) {
      const image = images[images.length - 1];
      gcn.requestImageByUrlAndWidth(image.url, image.width, (err, imgPath) => {
        $image.css('background-image', `url(${imgPath})`);
      });
    } else {
      $image.hide();
    }

    return this;
  },
});
