import _ from 'underscore';

import { Log, Strings } from '@biteinc/common';
import { TimeHelper } from '@biteinc/core-react';
import { LoyaltyAuthEntryMethod } from '@biteinc/enums';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { GCNLoyaltyPhoneNumberView } from './gcn_loyalty_phone_number_view';
import { GCNLoyaltyProgramHeaderView } from './gcn_loyalty_program_header_view';
import { GCNPunchhRedemptionCodeView } from './gcn_punchh_redemption_code_view';
import { GCNView } from './gcn_view';

export const GCNLoyaltyBarcodeView = GCNView.extend({
  className: 'loyalty-barcode-view',

  template: _.template(`
    <div class="body">
      <div class="title"></div>
      <div class="image"></div>
      <div class="subtitle"></div>
      <div class="fallback-or-text-redemption-button"></div>
      <div class="fallback-button redemption-code-button"></div>
      <div class="fallback-or-text-phone-button"></div>
      <div class="fallback-button phone-button"></div>
    </div>
  `),

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, options ? [options, ...args] : args);

    this.listenTo(gcn.loyaltyManager, BackboneEvents.GCNLoyaltyManager.DidUpdateAuthGuest, () => {
      this.trigger(BackboneEvents.GCNLoyaltyBarcodeView.DidUpdateAuthGuest, this);
    });

    if (options) {
      this.hidePhoneFallback = options.hidePhoneFallback === true;
      this.hideRedemptionCodeFallback = options.hideRedemptionCodeFallback === true;
    }
  },

  handlePunchhAuth(data, usesAuth) {
    gcn.menuView.dismissModalPopup();
    gcn.menuView.dismissStablePopup();

    if (usesAuth) {
      gcn.menuView.showSpinner(localizeStr(Strings.LOOKING_UP_ACCOUNT));
      gcn.loyaltyManager.submitLoyaltyAuth(
        data,
        undefined,
        () => {
          gcn.menuView.dismissSpinner();
        },
        LoyaltyAuthEntryMethod.BarcodeScanned,
      );
      gcn.removeScannerHandler(this);

      return;
    }

    gcn.loyaltyManager.handlePunchhLoyaltyData(data, LoyaltyAuthEntryMethod.BarcodeScanned);
    gcn.removeScannerHandler(this);
  },

  handleScannerData(scannerData) {
    Log.info('GCNLoyaltyBarcodeView.handleScannerData', scannerData);

    const loyaltyAuthData = gcn.loyaltyManager.getLoyaltyAuthData();
    const canScanAccountAfterRedemptionOrViceVersa =
      gcn.location.getLoyaltyIntegration().allowRedemptionCodeWithReward &&
      !(loyaltyAuthData?.tokenData && loyaltyAuthData?.redemptionCode);

    // If not validated, or either scan source is already processed, ignore scans.
    if (
      !gcn.orderManager.orderHasBeenValidated() ||
      (gcn.loyaltyManager.hasLoyaltyAuthData() && !canScanAccountAfterRedemptionOrViceVersa)
    ) {
      return;
    }

    if (!gcn.loyaltyManager.isPunchhDirect() && !gcn.loyaltyManager.isPunchhOlo()) {
      gcn.menuView.dismissModalPopup();
      gcn.menuView.dismissStablePopup();
      gcn.loyaltyManager.submitLoyaltyTokenData(scannerData, LoyaltyAuthEntryMethod.BarcodeScanned);
      gcn.removeScannerHandler(this);
      return;
    }

    // If the scanner data is too long it comes in as two separate events.
    // This only really happens with Punchh but NOT always.
    // Typically with user_as_qr_code
    // We receive the first half, and then the second ~ 5ms after.
    if (!this.firstHalf) {
      this.firstHalf = scannerData;
      this.sentFirstHalf = false;
      setTimeout(() => {
        if (this.firstHalf && !this.sentFirstHalf) {
          this.handlePunchhAuth(this.firstHalf, gcn.loyaltyManager.isPunchhOlo());
        }
      }, 75);
    } else {
      this.sentFirstHalf = true;
      this.handlePunchhAuth(`${this.firstHalf}${scannerData}`, gcn.loyaltyManager.isPunchhOlo());
    }
  },

  render() {
    const self = this;

    this.$el.html(this.template());

    this.$el.prepend(new GCNLoyaltyProgramHeaderView().render().$el);

    this._$title = this.$('.title');
    this._$subtitle = this.$('.subtitle');
    this._$phoneButton = this.$('.phone-button');
    this._$redemptionCodeButton = this.$('.redemption-code-button');

    this._$title.htmlOrText(localizeStr(Strings.LOYALTY_APP_AUTH_TITLE));
    this._$subtitle.htmlOrText(localizeStr(Strings.LOYALTY_APP_AUTH_SUBTITLE));

    // Add a barcode listener in here for loyalty
    gcn.pushScannerHandler(this);

    if (
      gcn.loyaltyManager.shouldEnablePhoneNumberAsFallbackToBarcode() &&
      !this.hidePhoneFallback
    ) {
      const _$orText = this.$('.fallback-or-text-phone-button');
      _$orText.text('OR');
      _$orText.css('opacity', '0');

      this._$phoneButton.htmlOrText(localizeStr(Strings.LOYALTY_APP_AUTH_PHONE_FALLBACK));
      this._$phoneButton.css('opacity', '0');
      setTimeout(() => {
        _$orText.css('opacity', '1');
        self._$phoneButton.css('opacity', '1');
      }, TimeHelper.SECOND);

      this._$phoneButton.onButtonTapOrHold('lbvPhone', () => {
        // TODO: Clean up self-dismissal with a controller.
        gcn.menuView.dismissStablePopup();
        // Remove barcode listener
        gcn.removeScannerHandler(this);
        const loyaltyPhoneNumberView = new GCNLoyaltyPhoneNumberView({
          callback() {
            gcn.menuView.dismissStablePopup();
          },
          lookupUserMethod(phoneNumber) {
            gcn.loyaltyManager.extendLoyaltyAuthWithTokenData(
              phoneNumber,
              LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered,
            );
            // Test Level Up number.
            // gcn.loyaltyManager.submitLoyaltyTokenData(
            //   '3947855938',
            //   LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered,
            // );
          },
        });
        gcn.menuView.showStablePopup(loyaltyPhoneNumberView, 'phone-number-view');
      });
    } else {
      this._$phoneButton.remove();
    }

    if (gcn.loyaltyManager.isPunchhDirect() && !this.hideRedemptionCodeFallback) {
      const _$orText = this.$('.fallback-or-text-redemption-button');
      _$orText.text('OR');
      _$orText.css('opacity', '0');

      this.$el.addClass('supports-redemption-codes');
      this._$redemptionCodeButton.htmlOrText(
        localizeStr(Strings.LOYALTY_APP_REDEMPTION_CODE_FALLBACK),
      );
      this._$redemptionCodeButton.css('opacity', '0');
      setTimeout(() => {
        self._$redemptionCodeButton.css('opacity', '1');
        _$orText.css('opacity', '1');
      }, TimeHelper.SECOND);
    } else {
      this._$redemptionCodeButton.remove();
    }

    this._$redemptionCodeButton.onButtonTapOrHold('lbvRedemption', () => {
      // TODO: Clean up self-dismissal with a controller.
      gcn.menuView.dismissStablePopup();
      const redemptionCodeView = new GCNPunchhRedemptionCodeView({
        callback() {
          gcn.menuView.dismissStablePopup();
          gcn.removeScannerHandler(this);
        },
      });
      gcn.menuView.showStablePopup(redemptionCodeView, 'punchh-redemption-code-view');
    });

    return this;
  },
});
