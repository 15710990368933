import _ from 'underscore';

import { I9nSchemaBySystem } from '@biteinc/common';
import { IntegrationSystem, OrderPaymentDestination, OrdersApiVersion } from '@biteinc/enums';

import { GCNModel } from './gcn_model';
import { GCNOrderedItem } from './gcn_ordered_item';
import { GCNReward } from './gcn_reward';
import { GCNTransaction } from './gcn_transaction';

export const GCNOrder = GCNModel.extend({
  initialize(...args) {
    GCNModel.prototype.initialize.apply(this, args);

    this.orderedItems = [];
    _.each(this._getClientOrderedItems(), (orderedItemJSON) => {
      const orderedItem = new GCNOrderedItem(orderedItemJSON);
      if (orderedItem) {
        this.orderedItems.push(orderedItem);
      }
    });

    const allTransactions = _.map(this.get('transactions'), (transactionJSON) => {
      return new GCNTransaction(transactionJSON);
    });

    this.transactions = _.filter(allTransactions, (transaction) => {
      return transaction.isSuccessful();
    });

    this._appliedRewards = [];
    this.get('appliedRewards')?.forEach((rewardData) => {
      const reward = new GCNReward(rewardData);
      this._appliedRewards.push(reward);
    });
    this._appliedCompCards = [];
    this.get('appliedCompCards')?.forEach((rewardData) => {
      const reward = new GCNReward(rewardData);
      this._appliedCompCards.push(reward);
    });
  },

  getFailedOrderedItems() {
    // If the order was sent, then nothing failed
    if (this.get('wasSentToPos')) {
      return [];
    }

    // If this is a location without integrations, then return all the items.
    if (!_.keys(this.get('dataForVendors')).length) {
      return this.orderedItems;
    }

    const itemsByIntegrationId = _.groupBy(this.orderedItems, (oi) => {
      return oi.get('integrationId');
    });

    const i9nDataById = _.indexBy(this.get('dataForVendors'), 'integrationId');

    let failedItems = [];
    _.each(itemsByIntegrationId, (items, integrationId) => {
      const i9nData = i9nDataById[integrationId];
      if (!i9nData || !i9nData.sentAt) {
        failedItems = failedItems.concat(items);
      }
    });
    return failedItems;
  },

  _getI9nDataForId(i9nId) {
    return this.get('dataForVendors').find((i9nData) => i9nData.integrationId === i9nId) || {};
  },

  getDiscountNames() {
    return this.getDiscountNamesWithAmounts().map(({ name }) => {
      return name;
    });
  },

  getDiscountNameAndAmount(coupon, amountByI9nId, nameByI9nId, discountType) {
    const name =
      // prefer applied/i9n coupon name
      coupon.name ||
      // fallback to i9nData discount name
      nameByI9nId[coupon.i9nId] ||
      // fallback to generic string
      `${discountType} ${coupon.i9nId}`;

    // same as above, but for amount
    const amount = coupon.amount || amountByI9nId[coupon.i9nId] || 0;
    return { name, amount, i9nId: coupon.i9nId };
  },

  getDiscountNamesWithAmounts() {
    const discountNamesWithAmounts = [];

    const integrationIdSet = {};
    _.each(this._getClientOrderedItems(), (orderedItem) => {
      if (orderedItem.integrationId) {
        integrationIdSet[orderedItem.integrationId] = true;
      }
    });
    const discountNameByI9nId = {};
    const discountAmountByI9nId = {};
    const addedDiscountI9nIds = [];
    Object.keys(integrationIdSet)?.forEach((integrationId) => {
      const i9nData = this._getI9nDataForId(integrationId);
      i9nData.discounts?.forEach((discount) => {
        if (discount.i9nId) {
          discountNameByI9nId[discount.i9nId] = discount.name;
          discountAmountByI9nId[discount.i9nId] = discount.amount || 0;
        }
      });
    });

    const allAppliedRewards = [
      ...(this.get('appliedRewards') || []),
      ...(this.get('appliedCompCards') || []),
    ];
    allAppliedRewards.forEach((appliedReward) => {
      if (appliedReward.name && !appliedReward.shouldBeHidden) {
        discountNamesWithAmounts.push({
          name: appliedReward.name,
          amount: appliedReward.amount,
        });
        addedDiscountI9nIds.push(appliedReward.rewardI9nId || appliedReward.i9nId);
      }
    });

    this.get('appliedPosCoupons')?.forEach((coupon) => {
      discountNamesWithAmounts.push(
        this.getDiscountNameAndAmount(
          coupon,
          discountAmountByI9nId,
          discountNameByI9nId,
          'POS Coupon',
        ),
      );

      if (coupon.i9nId) {
        addedDiscountI9nIds.push(coupon.i9nId);
      }
    });

    this.get('appliedLoyaltyCoupons')?.forEach((coupon) => {
      discountNamesWithAmounts.push(
        this.getDiscountNameAndAmount(
          coupon,
          discountAmountByI9nId,
          discountNameByI9nId,
          'Loyalty Coupon',
        ),
      );

      if (coupon.i9nId) {
        addedDiscountI9nIds.push(coupon.i9nId);
      }
    });

    this.get('appliedBiteCoupons')?.forEach((coupon) => {
      discountNamesWithAmounts.push(
        this.getDiscountNameAndAmount(coupon, discountAmountByI9nId, discountNameByI9nId, 'Coupon'),
      );

      if (coupon.i9nId) {
        addedDiscountI9nIds.push(coupon.i9nId);
      }
    });

    _.each(this.get('biteDiscounts'), ({ name, amount }) => {
      discountNamesWithAmounts.push({
        name,
        amount: amount || 0,
      });
    });

    // Some 'discounts' on the i9n data are auto-applied by the POS, and digested by us
    // when validating an order, instead of being applied. We want to show these as
    // discounts as well, for receipts, etc.
    // Check if i9nData has discounts that have not been added to discountNamesWithAmounts
    const discountsUsedTotal = discountNamesWithAmounts.reduce(
      (total, { amount }) => total + amount,
      0,
    );

    const discountI9nIds = Object.keys(discountAmountByI9nId);
    if (
      discountI9nIds.length > discountNamesWithAmounts.length ||
      discountsUsedTotal < this.get('discountTotal')
    ) {
      discountI9nIds.forEach((i9nId) => {
        if (!addedDiscountI9nIds.includes(i9nId)) {
          discountNamesWithAmounts.push({
            name: discountNameByI9nId[i9nId] || `Discount ${i9nId}`,
            amount: discountAmountByI9nId[i9nId],
          });
          addedDiscountI9nIds.push(i9nId);
        }
      });

      // If we have one pos discount and the ids match update the name and amount
      // This is to handle the cause where we do not know all of the coupon information before entering it
      if (
        discountNamesWithAmounts.length === 1 &&
        discountI9nIds.length === 1 &&
        discountNamesWithAmounts[0].i9nId === discountI9nIds[0]
      ) {
        discountNamesWithAmounts[0].name = discountNameByI9nId[discountI9nIds[0]];
        discountNamesWithAmounts[0].amount = discountAmountByI9nId[discountI9nIds[0]];
      }
    }

    return discountNamesWithAmounts;
  },

  /**
   * @returns [string]
   */
  getPosCheckIds() {
    const dataIds = [];
    this.get('dataForVendors').forEach((i9nData) => {
      const i9nSchema = I9nSchemaBySystem[i9nData.system];
      if (
        i9nSchema.posCheckIdKey &&
        i9nData.sendData &&
        i9nData.sendData[i9nSchema.posCheckIdKey]
      ) {
        dataIds.push(i9nData.sendData[i9nSchema.posCheckIdKey]);
      }
    });
    return dataIds;
  },

  /**
   * @returns {import('@biteinc/core-react').OrderIntegrationData[]}
   */
  getPunchhI9nData() {
    const punchhData = this.getI9nDatasForSystem(IntegrationSystem.Punchh);
    return punchhData.length ? punchhData : this.getI9nDatasForSystem(IntegrationSystem.PunchhOlo);
  },

  /**
   * @param {IntegrationSystem} system
   * @returns {import('@biteinc/core-react').OrderIntegrationData[]}
   */
  getI9nDatasForSystem(system) {
    return this.get('dataForVendors').filter((i9nData) => {
      return i9nData.system === system;
    });
  },

  _getClientOrderedItems() {
    return this.get('clientOrderedItems') || this.get('orderedItems');
  },

  canRefundTransaction(transaction) {
    if (this.isCancellable()) {
      return false;
    }
    const details = this._getTransactionRefundDetails(transaction);
    return !details.refunds.length || transaction.getRefundableAmount() > details.refundTotal;
  },

  getUnrefundedAmountForTransaction(transaction) {
    const details = this._getTransactionRefundDetails(transaction);
    return transaction.getRefundableAmount() - details.refundTotal;
  },

  _getTransactionRefundDetails(transaction) {
    const refunds = _.filter(this.get('refunds'), (refund) => {
      return refund.transactionId === transaction.id;
    });
    const refundTotal = _.reduce(
      refunds,
      (total, refund) => {
        return total + refund.total;
      },
      0,
    );
    return { refunds, refundTotal };
  },

  _getCalculatedTotal() {
    return (
      this.get('subTotal') -
      (this.get('discountTotal') || 0) +
      this.get('taxTotal') +
      (this.get('tipTotal') || 0) +
      (this.get('serviceChargeTotal') || 0)
    );
  },

  isSentImmediately() {
    return this.isAsapOrder() || this.isI9nFutureOrder();
  },

  isAsapOrder() {
    return !this.isI9nFutureOrder() && !this.isBiteFutureOrder() && !this.isCheckinOrder();
  },

  isI9nFutureOrder() {
    return !!this.get('isI9nFutureOrder');
  },

  isBiteFutureOrder() {
    return !!this.get('isBiteFutureOrder');
  },

  isCheckinOrder() {
    if (!this.isOrdersApiV2OrV3()) {
      return !!this.get('checkinToken');
    }
    return !!this.get('isCheckinOrder');
  },

  isPaidAtCashier() {
    return this.get('paymentDestination') === OrderPaymentDestination.Cashier;
  },

  isPaidFor() {
    if (this.transactions.length) {
      return true;
    }
    // This is a case where there was a total and it was fully discounted.
    if (this.get('subTotal') > 0 && this._getCalculatedTotal() === 0) {
      return true;
    }
    return false;
  },

  isOrdersApiV2OrV3() {
    return (
      this.get('ordersApiVersion') === OrdersApiVersion.V2 ||
      this.get('ordersApiVersion') === OrdersApiVersion.V3
    );
  },

  // keep in sync between gcn, bureau, and maitred
  hasToBeSent() {
    if (this.get('wasSentToPos') || this.get('isUnfinished') || this.get('hasFailedToSendToPos')) {
      return false;
    }
    return true;
  },

  isClosed() {
    // orders api v2
    return !!this.get('isClosed');
  },

  isCancelled() {
    // orders api v2
    return !!this.get('isCancelled');
  },

  // keep in sync between gcn, bureau, and maitred
  isCancellable() {
    // orders api v2
    if (!this.isOrdersApiV2OrV3()) {
      return false;
    }

    if (this.isCancelled()) {
      // can't double cancel
      return false;
    }

    if (!this.isClosed()) {
      // no need to cancel an unclosed order
      return false;
    }

    if (!this.hasToBeSent()) {
      // too late, it's already been sent. Just refund instead
      return false;
    }

    // do not ensure this is an unsent future order because
    // this might unnecessarily couple future orders and cancel orders

    return true;
  },

  markRefunded(refund) {
    const refunds = this.get('refunds') || [];
    refunds.push(refund);
    this.set('refunds', refunds);
  },
});

// Returns a map of vendorId to orderedItems, specially filtering out items
// with key NoVendorId that should not be sorted by their vendor.
GCNOrder.groupItemsByVendorId = function groupItemsByVendorId(orderedItems) {
  return _.groupBy(orderedItems, (orderedItem) => {
    if (orderedItem.item.isRetail() || !orderedItem.has('vendor')) {
      return GCNOrder.NoVendorId;
    }
    return orderedItem.get('vendor')._id;
  });
};

GCNOrder.groupItemsByI9nComboId = function groupItemsByI9nComboId(orderedItems) {
  const itemsAffectedByI9nCombo = _.filter(orderedItems, (orderedItem) => {
    return !!orderedItem.get('i9nComboId');
  });
  return _.groupBy(itemsAffectedByI9nCombo, (orderedItem) => {
    return orderedItem.get('i9nComboId');
  });
};

GCNOrder.NoVendorId = '(NoVendor)';
