import { RewardType } from '@biteinc/enums';

import GcnHtml from '../gcn_html';
import { GCNModel } from './gcn_model';

export const GCNReward = GCNModel.extend({
  displayName(skipHtml) {
    let name = this.get('name');
    const type = this.get('type');

    if (!skipHtml && [RewardType.Wallet, RewardType.WalletAndPayment].includes(type)) {
      name += ` ${GcnHtml.htmlFromPrice(this.get('amount'), { className: 'braces' })}`;
    } else if ([RewardType.Wallet, RewardType.WalletAndPayment].includes(type)) {
      name += ` $${GcnHtml.stringFromPrice(this.get('amount'))}`;
    }
    /**
     * Como and some other loyalty services do not have the actual amount
     * At this time and provide a dummy amount. So we do not include price for them.
     * Price is returned when the reward is applied
     */
    if (!skipHtml && [RewardType.Fixed].includes(type)) {
      if (this.get('amount') > 1) {
        name = `${GcnHtml.htmlFromPrice(this.get('amount'))} - ${name}`;
      }
    } else if ([RewardType.Fixed].includes(type)) {
      if (this.get('amount') > 1) {
        name = `$${GcnHtml.stringFromPrice(this.get('amount'))} - ${name}`;
      }
    }
    /**
     * We treat free items as anything that is like a rebate
     * e.g. Free Cookie and also display the quantity a user has
     */
    if (!skipHtml && [RewardType.FreeItem].includes(type)) {
      name = `${this.get('amount')} × ${name}`;
    } else if ([RewardType.FreeItem].includes(type)) {
      name = `${this.get('amount')} × ${name}`;
    }
    return name;
  },

  appliedRewardMapKey() {
    if (gcn.loyaltyManager.isPunchhDirect()) {
      return `${this.get('originalRewardI9nId') || this.get('rewardI9nId')}-${this.get('name')}`;
    }
    return `${this.get('originalRewardI9nId') || this.get('rewardI9nId')}-${this.get(
      'type',
    )}-${this.get('name')}`;
  },
});
