import _ from 'underscore';

import { StringHelper } from '@biteinc/core-react';
import { MenuItemCategory, MenuItemDisplayPricePrecalculation } from '@biteinc/enums';

import { GCNAddonSet } from './gcn_addon_set';
import { GCNMenuItem } from './gcn_menu_item';
import { GCNMenuPage } from './gcn_menu_page';
import { GCNMenuSection } from './gcn_menu_section';
import { GCNModel } from './gcn_model';
import { GCNOrderedItem } from './gcn_ordered_item';

export const GCNMenu = GCNModel.extend({
  _promoSectionById: null,
  _sectionById: null,
  _itemById: null,
  _addonSetById: null,
  _badgeById: null,
  _vendorById: null,
  _vendorByIntegrationId: null,

  initialize(...args) {
    GCNModel.prototype.initialize.apply(this, args);

    this._sectionById = {};
    this._promoSectionById = {};
    this._addonSetById = {};
    this._itemById = {};
    this.itemByPosId = {};
    this._badgeById = {};
    this._vendorById = {};
    this._vendorByIntegrationId = {};

    if (this.get('appearance')) {
      this.appearance = new GCNModel(this.get('appearance'));
    }
    if (this.get('settings')) {
      this.settings = new GCNModel(this.get('settings'));
    }

    this.items = [];
    _.each(this.get('items'), (itemJson) => {
      const item = new GCNMenuItem(itemJson);
      this._itemById[item.id] = item;
      this.items.push(item);
    });

    _.each(this.get('addonSets'), (addonSetJson) => {
      const addonSet = new GCNAddonSet(addonSetJson);
      this._addonSetById[addonSet.id] = addonSet;
      _.each(addonSet.get('items'), (addonRef) => {
        const item = this._itemById[addonRef._id];
        if (item) {
          addonSet.items.push(item);
        }
      });
    });

    _.each(this.items, (item) => {
      _.each(item.priceOptions, (priceOption) => {
        _.each(priceOption.get('addonSetIds'), (addonSetId) => {
          const addonSet = this._addonSetById[addonSetId];
          if (addonSet) {
            priceOption.addonSets.push(addonSet);
          }
        });
      });
    });

    this.sections = [];
    _.each(this.get('sections'), (sectionJson) => {
      const section = new GCNMenuSection(sectionJson);

      _.each(section.get('items'), ({ _id: itemId }) => {
        const item = this._itemById[itemId];
        section.addItem(item);
        this.itemByPosId[item.posId()] = item;
      });

      this._sectionById[section.id] = section;
      this.sections.push(section);
    });

    this.badges = _.map(this.get('badges'), (badgeJson) => {
      const badge = new GCNModel(badgeJson);
      this._badgeById[badge.id] = badge;
      return badge;
    });

    this.vendors = _.map(this.get('vendors'), (vendorJson) => {
      const vendor = new GCNModel(vendorJson);
      this._vendorById[vendor.id] = vendor;
      return vendor;
    });
    _.each(this.get('vendorIdByIntegrationId'), (vendorId, key) => {
      this._vendorByIntegrationId[key] = this._vendorById[vendorId];
    });
  },

  configureMenuStructure(screenReaderIsActive) {
    const menuStructureJson = this.get('menuStructure');
    if (!screenReaderIsActive || menuStructureJson.pages.length > 1) {
      this.structure = new GCNMenuPage(this.get('menuStructure'));
    } else {
      this.structure = new GCNMenuPage({
        ...menuStructureJson,
        pages: menuStructureJson.pages[0].sectionIds.map((sectionId) => {
          const section = this._sectionById[sectionId];
          return {
            _id: StringHelper.newMongoId(),
            name: section.displayName(),
            pages: [],
            sectionIds: [section.id],
          };
        }),
      });
    }

    this.structure.setVendorFromMenu(this.vendors);
    this._populateMenuPageWithSections(this.structure);
  },

  _getAppearanceParam(key, isString) {
    const method = isString ? 'hasStr' : 'hasArr';
    if (this.appearance[method](key)) {
      return this.appearance.get(key);
    }
    return isString ? '' : null;
  },

  shouldDisplayPriceWithDefaultMods() {
    return (
      this.settings.get('precalculateMenuItemDisplayPrice') ===
      MenuItemDisplayPricePrecalculation.IncludeDefaultMods
    );
  },

  getBackgroundImageUrl() {
    const image = _.first(this._getAppearanceParam('menuPageBackgroundImage'));
    return image ? image.url : null;
  },

  getDiningOptionBackgroundImageUrl(isDesktop) {
    const image = isDesktop
      ? _.first(this.settings.get('fulfillmentBackgroundImageWeb'))
      : _.first(this.settings.get('fulfillmentBackgroundImage'));
    return image ? image.url : null;
  },

  getBadgeWithId(badgeId) {
    return this._badgeById[badgeId];
  },

  getVendorWithId(vendorId) {
    return this._vendorById[vendorId];
  },

  getCustomCssUrl() {
    return this.appearance.get('cssUrl') || '';
  },
  getCustomCss() {
    return this.appearance.get('customCss') || '';
  },

  getFilterableBadgeIdsWithItems() {
    const badgeIdSet = {};
    this.items.forEach((item) => {
      item.get('badgeIds')?.forEach((badgeId) => {
        if (
          this._badgeById[badgeId]?.get('enabledForFiltering') &&
          this._badgeById[badgeId]?.get('filterCategory')
        ) {
          badgeIdSet[badgeId] = true;
        }
      });
    });
    return Object.keys(badgeIdSet);
  },

  getFirstMenuPage() {
    // Return the first top level page.
    return this.structure.pages[0];
  },

  getFullScreenBackgroundImages() {
    return this.settings.get('fullScreenBackgroundImages');
  },

  getAddonSetWithId(addonSetId) {
    return this._addonSetById[addonSetId];
  },

  getMenuItemWithId(menuItemId) {
    return this._itemById[menuItemId];
  },

  getMenuItemWithPosId(posId) {
    return _.find(this.items, (item) => {
      return item.posId() === posId;
    });
  },

  getMenuItemsFromRecommendations(recommendations, { displayRecosLimit, pickSimpleItemsOnly }) {
    const recoItems = [];
    let displayRecosCount = 0;
    recommendations.forEach((recommendation) => {
      if (displayRecosCount >= displayRecosLimit) {
        return;
      }
      const recoItem = gcn.menu.getMenuItemWithId(recommendation.itemId);
      if (!recoItem) {
        return;
      }
      if (pickSimpleItemsOnly && !recoItem.isSimpleForRecommendations()) {
        return;
      }
      displayRecosCount += 1;
      recoItems.push(recoItem);
    });

    return recoItems;
  },

  getOrderedItemForMenuItem(menuItem) {
    const orderedItem = new GCNOrderedItem(
      {},
      {
        item: menuItem,
        section: null,
        upsellScreen: null,
      },
    );
    orderedItem.setPriceOption(menuItem.priceOptions[0]);
    return orderedItem;
  },

  getOrderedItemForMenuItemId(menuItemId) {
    const menuItem = gcn.menu.getMenuItemWithId(menuItemId);
    if (!menuItem) {
      return null;
    }
    return gcn.menu.getOrderedItemForMenuItem(menuItem);
  },

  // Returns an object with properties `item` and `priceOption`.
  getMenuItemAndPriceOptionWithBarcode(barcode) {
    let ret;
    _.each(this.items, (item) => {
      if (item.get('isMod')) {
        return;
      }

      const priceOption = item.getPriceOptionWithBarcode(barcode);
      if (priceOption) {
        ret = { item, priceOption };
      }
    });
    return ret;
  },

  getMenuSectionWithId(menuSectionId) {
    return this._sectionById[menuSectionId];
  },

  getMenuSectionThatContainsItemId(itemId) {
    return _.find(this._sectionById, (section) => {
      if (section.isPromoSection()) {
        return false;
      }
      return _.any(section.items, (item) => {
        return item.id === itemId;
      });
    });
  },

  getShownMenuSectionThatContainsItemId(itemId) {
    return this.structure.getMenuSectionThatContainsItemId(itemId);
  },

  getMenuPageWithId(menuPageId) {
    return this._findMenuPageWithIdInMenuPage(menuPageId, this.structure);
  },

  getMenuPageLevel(menuPageId) {
    return this._getMenuPageLevel(this.structure, menuPageId);
  },

  getMenuPageParent(menuPageId) {
    return this._getMenuPageParent(this.structure, menuPageId);
  },

  getMenuTitle() {
    return this.settings.get('menuTitle') || '';
  },

  getPageCount() {
    return this.structure.pages.length;
  },

  getDefaultSectionHeaderImages() {
    return this.settings.get('sectionImages') || [];
  },

  getTitleBarImage() {
    if (window.webEnabled) {
      return _.first(gcn.org.logo);
    }
    return _.first(this.settings.get('titleBarImage'));
  },

  getVendorForIntegrationId(integrationId) {
    return this._vendorByIntegrationId[integrationId];
  },

  isShowingItemWithId(itemId) {
    return this.structure.hasItemWithId(itemId);
  },

  menuPageContainsPageWithId(menuPage, menuPageId) {
    return this._getMenuPageLevel(menuPage, menuPageId) > 0;
  },

  registerPromoSection(promoSection) {
    this._promoSectionById[promoSection.id] = promoSection;
    this._sectionById[promoSection.id] = promoSection;
  },

  deregisterPromoSections() {
    _.each(this._promoSectionById, (promoSection) => {
      delete this._sectionById[promoSection.id];
    });
    this._promoSectionById = {};
  },

  getPromoSections() {
    return _.values(this._promoSectionById);
  },

  shouldDeemphasizeStories() {
    return this.appearance.get('deemphasizeStories');
  },

  shouldDeemphasizeCalories() {
    return this.settings.get('deemphasizeCalories');
  },

  getMenuDisclaimer() {
    return this.settings.get('menuDisclaimer');
  },

  getNextStepsImage() {
    return _.first(this._getAppearanceParam('nextStepsImage'));
  },

  getToGoBagOptInImage() {
    return _.first(this.settings.get('bagOptInImage'));
  },

  getRewardsProgramLogoImage() {
    return _.first(this.settings.get('rewardsProgramLogo'));
  },

  _getMenuPageLevel(menuPage, menuPageId) {
    for (let i = 0; i < menuPage.pages.length; i++) {
      const page = menuPage.pages[i];
      if (page.id === menuPageId) {
        return 1;
      }
      const level = this._getMenuPageLevel(page, menuPageId);
      if (level > 0) {
        return level + 1;
      }
    }
    return 0;
  },

  /*
   * Recursively through menuPage for menuPageId's page, and returns the ID of
   * the page above menuPageId's page. Returns null if it was not found.
   */
  _getMenuPageParent(parentPage, menuPageId) {
    for (let i = 0; i < parentPage.pages.length; i++) {
      const page = parentPage.pages[i];
      if (page.id === menuPageId) {
        return parentPage.id;
      }
      const id = this._getMenuPageParent(page, menuPageId);
      if (id !== null) {
        return id;
      }
    }
    return null;
  },

  _isPageRemovable(page) {
    return !page.pages.length && !page.sections.length;
  },

  _populateMenuPageWithSections(menuPage) {
    if (menuPage.pages.length) {
      _.each(menuPage.pages, (subPage) => {
        this._populateMenuPageWithSections(subPage);
      });
    }

    menuPage.sections = (menuPage.get('sectionIds') || []).map((sectionId) => {
      return this._sectionById[sectionId];
    });
  },

  _findMenuPageWithIdInMenuPage(menuPageId, menuPage) {
    if (!menuPageId) {
      return null;
    }

    let page = null;
    const subPages = menuPage.pages || [];
    let i = 0;
    while (!page && i < subPages.length) {
      const subPage = subPages[i];
      i++;
      if (subPage.id === menuPageId) {
        return subPage;
      }
      page = this._findMenuPageWithIdInMenuPage(menuPageId, subPage);
    }
    return page;
  },

  // Returns a css class that will apply a placeholder image to the element
  // that applies it, for a given item.
  placeholderImageClass(item) {
    switch (item.get('category')) {
      case MenuItemCategory.Food:
        return 'placeholder food';
      case MenuItemCategory.AlcoholicBeverage:
        return 'placeholder alc-beverage';
      case MenuItemCategory.NonalcoholicBeverage:
        return 'placeholder non-alc-beverage';
    }
    return 'placeholder other';
  },

  addonSetHasSufficientSelections(addonSet) {
    const { selectedByDefaultModCount, mustBeAlwaysSentModCount } = addonSet.get('items').reduce(
      (counts, itemRef) => {
        if (addonSet.addonIsSelectedByDefault(itemRef._id)) {
          counts.selectedByDefaultModCount++;
        }
        if (addonSet.addonMustBeAlwaysSent(itemRef._id)) {
          counts.mustBeAlwaysSentModCount++;
        }
        return counts;
      },
      { selectedByDefaultModCount: 0, mustBeAlwaysSentModCount: 0 },
    );
    const isSufficientByItself =
      selectedByDefaultModCount + mustBeAlwaysSentModCount >= (addonSet.get('minSelectable') || 0);
    if (!isSufficientByItself) {
      return false;
    }

    // Make sure that all the addons selected by default have satisfied nested mods groups
    return addonSet.get('items').every((itemRef) => {
      if (!addonSet.addonIsSelectedByDefault(itemRef._id)) {
        return true;
      }

      const addon = this.getMenuItemWithId(itemRef._id);
      return (addon.get('priceOptions')[0].addonSetIds || []).every((addonSetId) => {
        const nestedAddonSet = this.getAddonSetWithId(addonSetId);
        return this.addonSetHasSufficientSelections(nestedAddonSet);
      });
    });
  },
});
