import * as Sentry from '@sentry/browser';
import $ from 'jquery';
import _ from 'underscore';

import { Log } from '@biteinc/common';
import { FlashBridgeMessage, LanguageCode } from '@biteinc/enums';

import MobileAppMessageType from '../../types/mobile_app_message_type';
import initSentry from './sentry';
import { biteBeep } from './utils/bite-beep';
import { GCNView } from './views/gcn_view';

function safeStringify(obj, indent) {
  let cache = [];
  const retVal = JSON.stringify(
    obj,
    (key, value) =>
      typeof value === 'object' && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent,
  );
  cache = null;
  return retVal;
}

Log.init((logLevel, ...messages) => {
  const stringifiedMessages = (messages || []).map((message) => {
    if (typeof message === 'object' && message !== null && window.env === 'test') {
      return safeStringify(message, 2);
    }
    return message;
  });
  switch (logLevel) {
    case Log.LogLevel.DEBUG:
      if (['dev', 'test'].includes(window.env)) {
        // eslint-disable-next-line no-console
        console.log('DEBUG:', ...stringifiedMessages);
      }
      break;
    case Log.LogLevel.INFO:
      // eslint-disable-next-line no-console
      console.log('INFO:', ...stringifiedMessages);
      break;
    case Log.LogLevel.WARN:
      // eslint-disable-next-line no-console
      console.log('WARN:', ...stringifiedMessages);
      break;
    case Log.LogLevel.ERROR:
      // eslint-disable-next-line no-console
      console.error('ERROR:', ...stringifiedMessages);
      if (['dev', 'test'].includes(window.env)) {
        biteBeep();
      }
      break;
  }
});
$.Event.prototype.getFirstTouch = function getFirstTouch() {
  const evt = this.originalEvent;
  const touches = evt.changedTouches;
  if (touches && touches.length) {
    return touches[0];
  }
  return null;
};
$.Event.prototype.getTouchWithId = function getTouchWithId(touchId) {
  const evt = this.originalEvent;
  const touches = evt.changedTouches;
  for (let i = 0; i < _.size(touches); i++) {
    const touch = touches[i];
    if (touch.identifier === touchId) {
      return touch;
    }
  }
  return null;
};
$.Event.prototype.getCoordinate = function getCoordinate(coordKey, touchId) {
  const touch = this.getTouchWithId(touchId);
  if (touch && coordKey in touch) {
    return touch[coordKey];
  }
  const evt = this.originalEvent;
  if (coordKey in evt) {
    return evt[coordKey];
  }
  return -1;
};
$.Event.prototype.getX = function getX(touchId) {
  return this.getCoordinate('screenX', touchId);
};
$.Event.prototype.getY = function getY(touchId) {
  return this.getCoordinate('screenY', touchId);
};
export const setImage = (image, $imageEl) => {
  const ratio = image.width / image.height;
  if ((ratio < 1.48 && ratio !== 1) || (image.isCellar && !image.fitToFrame)) {
    $imageEl.toggleClass('contain', true);
  }
  gcn.requestImageByUrl(image.url, (err, imgPath) => {
    // Get the image for its metrics so we can calculate the frame.
    $imageEl.css('background-image', `url(${imgPath})`);
  });
};

export const flashElement = ($el, animation) => {
  $el.toggleClass('animated', true);
  $el.toggleClass(animation, true);
  $el.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
    $el.toggleClass('animated', false);
    $el.toggleClass(animation, false);
  });
};

export const GCNAppView = GCNView.extend({
  initialize(...args) {
    GCNView.prototype.initialize.apply(this, [...args]);

    this.lastActiveAt = 0;
    this.imageCache = {};
    this.pendingImageCallbacks = {};

    // Prevent double-tap zoom, which can cause the menu to lock up.
    let doubleTouchStartTimestamp = 0;
    const self = this;
    $(document).on('touchstart', (event) => {
      const now = +new Date();
      if (doubleTouchStartTimestamp + 500 > now) {
        event.preventDefault();
      }
      doubleTouchStartTimestamp = now;
      self._setLastActiveAt(now);
    });
  },

  setBridge(bridge) {
    this.bridge = bridge;
    if (this.bridge) {
      bridge.init(this.handleBridgeMessage.bind(this));
    }
  },

  setBridgeIsReady() {
    if (this.bridge) {
      this.bridge.send({ event: FlashBridgeMessage.READY });
      initSentry(this.bridge);
    }
  },

  setMobileAppBridge(bridge) {
    this.mobileAppBridge = bridge;
    if (bridge) {
      bridge.init(this.handleMobileAppMessage.bind(this));
      bridge.send(MobileAppMessageType.BridgeReady);
    }
  },

  handleBridgeMessage(/* message, callback */) {
    Log.error('OVERRIDE ME!!');
  },

  async handleMobileAppMessage(/* message */) {
    Log.error('OVERRIDE ME!!');
  },

  _setLastActiveAt(lastActiveAt) {
    this.lastActiveAt = lastActiveAt;
  },

  isTouchDevice() {
    return (
      'ontouchstart' in window || // works on most browsers
      navigator.maxTouchPoints
    ); // works on IE10/11 and Surface
  },

  isMobile() {
    let bodyWidth = 0;
    if (document.getElementsByTagName('body').length > 0) {
      bodyWidth = window.innerWidth || document.getElementsByTagName('body')[0].clientWidth;
      if (bodyWidth < 600 && this.isTouchDevice()) {
        return true;
      }
    }
    return false;
  },

  async requestBase64ImageByUrl(imageUrl) {
    if (!(imageUrl || '').trim().length) {
      return;
    }
    const cachedImage = this._imageForKey(imageUrl);
    if (cachedImage) {
      return cachedImage;
    }

    try {
      // best effort to download the image and convert it to base64
      const blob = await fetch(imageUrl);
      const arrayBuffer = await blob.arrayBuffer();
      const codes = new Uint8Array(arrayBuffer);
      const bin = String.fromCharCode.apply(null, codes);
      const b64 = btoa(bin);
      this._storeImageInCache(imageUrl, b64);
      return b64;
    } catch (err) {
      // we manually check what the issue with the image is
      Sentry.captureException(`Unable to download ${imageUrl}`);
      return null;
    }
  },

  requestImageByUrl(imageUrl, callback) {
    this.requestImageByUrlAndWidth(imageUrl, 600, callback);
  },

  getImageFromCache(imageUrl) {
    return this._imageForKey(imageUrl);
  },

  requestImageByUrlAndWidth(imageUrl, width, callback) {
    if (!(imageUrl || '').trim().length) {
      return;
    }
    if (window.isKioskPreview || window.isGarcon) {
      callback(null, imageUrl);
      return;
    }
    const self = this;
    const cachedImage = this._imageForKey(imageUrl);
    if (cachedImage) {
      callback(null, cachedImage);
      return;
    }

    if (this.bridge) {
      const pendingCallbacks = this.pendingImageCallbacks[imageUrl];
      if (pendingCallbacks) {
        pendingCallbacks.push(callback);
      } else {
        this.pendingImageCallbacks[imageUrl] = [callback];
        this.bridge.send(
          {
            event: FlashBridgeMessage.IMAGE,
            imageUrl,
            maxWidth: width,
          },
          (data) => {
            if (data) {
              self._storeImageInCache(imageUrl, data);
            }
            const callbacks = self.pendingImageCallbacks[imageUrl] || [];
            delete self.pendingImageCallbacks[imageUrl];
            _.each(callbacks, (cb) => {
              cb(null, data);
            });
          },
        );
      }
    } else {
      setTimeout(() => {
        if (imageUrl) {
          self._storeImageInCache(imageUrl, imageUrl);
        }
        callback(null, imageUrl);
      }, 100);
    }
  },

  getLanguage() {
    return LanguageCode.EN_US;
  },

  requestImageByName(imageName, callback) {
    if (this.bridge) {
      this.bridge.send(
        {
          event: FlashBridgeMessage.IMAGE,
          imageName,
        },
        (data) => {
          callback(null, data);
        },
      );
    } else {
      callback(null, '');
    }
  },

  _imageForKey(key) {
    const cachedObject = this.imageCache[key];
    if (cachedObject) {
      cachedObject.lastUsed = Date.now();
      return cachedObject.base64Img;
    }
    return null;
  },

  _storeImageInCache(key, base64Img) {
    let cachedObject = this.imageCache[key];
    if (!cachedObject) {
      cachedObject = {};
    }
    cachedObject.base64Img = base64Img;
    cachedObject.lastUsed = Date.now();
    this.imageCache[key] = cachedObject;
  },

  cleanUnusedImagesFromCache() {
    const now = Date.now();
    _.each(_.keys(this.imageCache), (key) => {
      const cachedObject = this.imageCache[key];
      if (now - cachedObject.lastUsed > 60 * 1000) {
        delete this.imageCache[key];
      }
    });
  },
});
