import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { localizeStr, str } from '~/app/js/localization/localization';

import { GCNView } from './gcn_view';

export const GCNItemRecipientView = GCNView.extend({
  className: 'item-recipient-view',
  template: _.template(
    // prettier-ignore
    '<label for="item-recipient"><%= specialRequestStr %>:</label>' +
    '<textarea id="item-recipient" class="item-recipient-text" type="text" placeholder="" />' +
    '<div class="max-length"></div>',
  ),

  blur() {
    this._$itemRecipientTextBox[0].blur();
    gcn.notifyUserDidFocusOutOfTextField();
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);

    this.blur();
    this._$itemRecipientTextBox.remove();
    this.$el.html('');
  },

  getValue() {
    return this._$itemRecipientTextBox.val().trim();
  },

  setValue(value) {
    if (this._$itemRecipientTextBox) {
      this._$itemRecipientTextBox.val(value);
    } else {
      this._presetValue = value;
    }
  },

  render() {
    this.$el.html(
      this.template({
        specialRequestStr: str(Strings.RECEIPT_SPECIAL_REQUEST),
      }),
    );
    this.maxLength = 32;
    this._$itemRecipientTextBox = this.$('#item-recipient');
    this._$itemRecipientTextBox.attr('maxlength', this.maxLength);

    this._$maxLength = this.$('.max-length');

    this._$itemRecipientTextBox.focus(() => {
      gcn.notifyUserDidFocusOnTextField();
    });
    this._$itemRecipientTextBox.keyup(() => {
      const length = this.getValue().length;
      this._$maxLength.htmlOrText(
        localizeStr(Strings.CHARS_LEFT, [Math.max(0, this.maxLength - length)]),
      );

      return false;
    });
    this._$itemRecipientTextBox.focus(function onFocus() {
      this.focus();
    });
    this._$itemRecipientTextBox.keydown(function onKeyDown(e) {
      const k = e.keyCode;
      if (13 === k) {
        this.blur();
        return false;
      }
      const ok =
        (k >= 65 && k <= 90) || // A-Z
        (k >= 96 && k <= 105) || // a-z
        (k >= 35 && k <= 40) || // arrows
        k === 8 || // Backspaces
        k === 32 || // Space
        (!e.shiftKey && k >= 48 && k <= 57); // 0-9

      if (!ok) {
        return false;
      }
      return true;
    });

    // Localization.
    this.$('label').htmlOrText(`${localizeStr(Strings.ITEM_RECIPIENT)}:`);
    this._$itemRecipientTextBox.attr('placeholder', str(Strings.ITEM_RECIPIENT_PLACEHOLDER));

    if (this._presetValue) {
      this._$itemRecipientTextBox.val(this._presetValue);
    }

    return this;
  },
});
