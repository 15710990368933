import { IonIcon, IonImg, IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import Backbone from 'backbone';
import { addOutline, checkmarkOutline } from 'ionicons/icons';
import React, { Component } from 'react';

import { Strings } from '@biteinc/common';

import { BackboneEvents } from '~/app/js/backbone-events';
import { GCNRecoManager } from '~/app/js/gcn_reco_manager';
import { localizeStr } from '~/app/js/localization/localization';
import { GCNOrderedItem } from '~/app/js/models/gcn_ordered_item';
import { GCNMenuItemEditView } from '~/app/js/views/gcn_menu_item_edit_view';

import GcnHtml from '../app/js/gcn_html';
import type { GcnMenuItem } from '../types/gcn_menu_item';
import type { RecommendationDisplayLocationDescription } from '../types/recommendation';

interface RecommendationProps {
  recommendedMenuItem: GcnMenuItem;
  index: number;
  displayLocationDescription: RecommendationDisplayLocationDescription;
}

// Empty - component is for display only
interface RecommendationState {
  isChecked: boolean;
}

export default class RecommendedItemComponent extends Component<
  RecommendationProps,
  RecommendationState
> {
  constructor(props: RecommendationProps) {
    super(props);
    this.state = {
      isChecked: false,
    };
  }

  private getItemPrice(): string {
    const price = this.props.recommendedMenuItem.get('priceOptions')[0].price;
    if (price !== 0) {
      return `$${GcnHtml.stringFromPrice(price)}`;
    }
    return '';
  }

  private getItemName(): string {
    return this.props.recommendedMenuItem.displayName();
  }

  private getItemImageUrl(): string {
    return this.props.recommendedMenuItem.getImageUrlOrPlaceholder();
  }

  private getItemDescription(): string {
    return this.props.recommendedMenuItem.displayDescription();
  }

  private getItemBadges(): React.ReactNode | undefined {
    return this.props.recommendedMenuItem.badges().map((badge) => {
      return (
        <IonThumbnail
          className="recommendation-badge"
          key={badge.id}
        >
          <IonImg
            src={badge.get('icons')[0].url}
            alt={badge.displayName()}
          />
        </IonThumbnail>
      );
    });
  }

  private recommendationWasSelected(): void {
    const orderedItem = new GCNOrderedItem(null, {
      item: this.props.recommendedMenuItem,
      upsellScreen: GCNRecoManager.trackingLabel(
        'side-cart',
        this.props.index,
        this.props.recommendedMenuItem,
      ),
    });
    // Simple item with no modifications
    if (this.props.recommendedMenuItem.hasOnePriceOptionNoAddons()) {
      gcn.orderManager.addToOrder(orderedItem, 1, {
        recommendationDisplayLocationDescription: this.props.displayLocationDescription,
      });
    } else {
      // Item requires a popup to select options for it.
      const editView = new GCNMenuItemEditView({
        model: orderedItem,
        recoEditor: true,
        isNested: true,
        cancelButtonText: localizeStr(Strings.REMOVE),
      });

      gcn.menuView.showPopup(editView, undefined, 'recommended-item');

      // Backbone called explicitly as this.listenTo is not recognized
      Backbone.Events.listenTo(editView, BackboneEvents.GCNMenuItemOrderView.DonePressed, () => {
        gcn.menuView.dismissPopup();
        gcn.orderManager.addToOrder(editView.model, orderedItem.orderedPO.get('quantity'), {
          recommendationDisplayLocationDescription: this.props.displayLocationDescription,
        });
      });
      Backbone.Events.listenTo(editView, BackboneEvents.GCNMenuItemOrderView.CancelPressed, () => {
        gcn.menuView.dismissPopup();
      });
      Backbone.Events.listenTo(editView, BackboneEvents.GCNMenuItemOrderView.OverlayPressed, () => {
        gcn.menuView.dismissPopup();
      });
    }
  }

  render(): React.ReactNode {
    return (
      <IonItem
        onClick={() => {
          this.recommendationWasSelected();
        }}
        // Mainly required for the checkmark
        onMouseEnter={() => {
          this.setState({
            isChecked: true,
          });
        }}
        onMouseLeave={() => {
          this.setState({
            isChecked: false,
          });
        }}
        className={this.state.isChecked ? 'recommended-item active' : 'recommended-item inactive'}
      >
        <IonThumbnail
          className="recommendation-image"
          slot="start"
        >
          <IonImg
            src={this.getItemImageUrl()}
            alt={this.getItemName()}
          />
        </IonThumbnail>
        <IonLabel className="recommendation-column">
          <p className="recommendation-name">{this.getItemName()}</p>
          <p className="recommendation-description">{this.getItemDescription()}</p>
          {this.getItemBadges()}
        </IonLabel>
        <div
          className="recommendation-column-full-height"
          slot="end"
        >
          <div>
            <p className="recommendation-price">{this.getItemPrice()}</p>
          </div>
          <div>
            <IonIcon
              className={this.state.isChecked ? 'add hidden' : 'add'}
              icon={addOutline}
            >
              +
            </IonIcon>
            <IonIcon
              className={this.state.isChecked ? 'checkmark' : 'checkmark hidden'}
              icon={checkmarkOutline}
            />
          </div>
        </div>
      </IonItem>
    );
  }
}
