import $ from 'jquery';
import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { CardEntryMethod } from '@biteinc/enums';

import { localizeStr } from '~/app/js/localization/localization';
import {
  getShouldShowPinInput,
  getValidCardNumberInputRegex,
  getValidCardNumberLengths,
  getValidPinInputRegex,
  getValidPinLengths,
  validCardNumberInputRegexIsNumericOnly,
} from '~/helpers/card_with_pin_helper';
import { GCNForcePlainKeyboard } from '~/helpers/gcn_force_plain_keyboard';

import GcnHtml from '../gcn_html';
import Analytics from '../utils/analytics';
import { GCNView } from './gcn_view';

const backspaceCharKeyCode = 8;

export const GCNCardWithPinView = GCNView.extend({
  className: 'gift-card-view',

  /**
   *
   * @param {'comp-card' | 'stored-value'} mode
   * @param {{ cardNumber?: string; cardEntryMethod?: CardEntryMethod; callback: Function }} options
   * @param  {...any} args
   */
  initialize(mode, options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this._prefilledCardNumber = options.cardNumber;
    this._cardEntryMethod = options.cardEntryMethod;
    this._callback = options.callback;
    this._mode = mode;
    this._integration =
      mode === 'stored-value'
        ? gcn.location.getStoredValueIntegration()
        : gcn.location.getCompCardIntegration();
    this._validCardNumberLengths = getValidCardNumberLengths(this._integration);
    this._validCardNumberInputRegex = getValidCardNumberInputRegex(this._integration);
    this._validPinLengths = getShouldShowPinInput(this._integration)
      ? getValidPinLengths(this._integration)
      : null;
    this._validPinInputRegex = getValidPinInputRegex(this._integration);
  },

  _displayError(errorText) {
    this.$('.gift-card-popup-title').htmlOrText(errorText).addClass('error');
    gcn.menuView.dismissSpinner();
  },

  _displayCardNumberError(errorText) {
    this.$searchButton?.removeClass('disabled');
    this._displayError(errorText);
  },

  async _submitGiftCardNumber() {
    const cardNumber = this.$giftCardNumberInput.val();
    const pin = this.$giftCardPinInput?.val();

    if (!_.contains(this._validCardNumberLengths, cardNumber.length)) {
      this._displayCardNumberError(localizeStr(Strings.ERROR_CARD_NUMBER_LENGTH));
      return;
    }

    if (this._validPinLengths && !_.contains(this._validPinLengths, pin?.length)) {
      this._displayCardNumberError(localizeStr(Strings.ERROR_PIN_LENGTH));
      return;
    }

    this.$searchButton.addClass('disabled');
    gcn.menuView.showSpinner(localizeStr(Strings.LOOKUP_CARD));

    if (this._mode === 'comp-card') {
      const { inquireError } = await gcn.loyaltyManager.applyCompCard(
        cardNumber,
        this._cardEntryMethod || CardEntryMethod.ManuallyEntered,
        pin,
      );
      if (inquireError) {
        this._displayCardNumberError(inquireError);
        return;
      }

      this._callback();
      return;
    }

    const authData = {
      cardNumber,
      cardEntryMethod: this._cardEntryMethod || CardEntryMethod.ManuallyEntered,
      ...(pin && { giftCardPin: pin }),
    };

    gcn.orderManager.addStoredValueCard(cardNumber, CardEntryMethod.ManuallyEntered);

    const { inquireError, updatedAuthData, defaultAmount } =
      await gcn.loyaltyManager.inquireGiftCard(authData, this._displayCardNumberError);

    if (inquireError) {
      this._displayCardNumberError(inquireError);
      return;
    }

    const { applyError } = await gcn.loyaltyManager.captureAppliedGiftCardAmount(
      updatedAuthData,
      defaultAmount,
      this._callback,
    );

    if (applyError) {
      this._displayCardNumberError(applyError);
    }
  },

  _validateInput() {
    const cardNumber = this.$giftCardNumberInput.val();
    const pin = this.$giftCardPinInput?.val();

    const validCardNumber = _.contains(this._validCardNumberLengths, cardNumber.length);
    const validPin = !this._validPinLengths || _.contains(this._validPinLengths, pin.length);

    if (validCardNumber && validPin) {
      this.$searchButton.removeClass('disabled');
    } else {
      this.$searchButton.addClass('disabled');
    }
  },

  _getUnusedSessionStoredValueCards() {
    if (this._mode !== 'stored-value') {
      return [];
    }
    return gcn.orderManager.getUnusedStoredValueCards().filter((card) => {
      return !!card.authSessionToken;
    });
  },

  _renderSessionStoredValueCards(unusedStoredValueCards) {
    this.$icon.hide();
    this.$giftCardPopupTitleWrapper = this.$('#gift-card-popup-title-wrapper');
    this.$giftCardPopupTitleWrapper.append(
      `<div class="gift-card-popup-title">${localizeStr(Strings.USE_GIFT_CARD)}</div>`,
    );
    const $chooseStoredValueCardList = $('<ul id="choose-stored-value-card-list"></ul>');
    unusedStoredValueCards.forEach((card) => {
      const disabledClass = card.balance === 0 ? ' disabled' : '';
      const $storedValueCardWrapper = $('<li></li>');
      const cardName = card.cardName;
      const lastFour = card.lastFour ? ` (*${card.lastFour})` : '';
      const $storedValueCard = $(
        `<div role="button" class="button select-card${disabledClass}">${cardName}${lastFour}</div>`,
      );
      $storedValueCardWrapper.append($storedValueCard);
      $storedValueCard.onButtonTapOrHold('gcvCard', async () => {
        if ($storedValueCard.hasClass('disabled')) {
          return;
        }
        const authData = {
          terminalId: gcn.location.getStoredValueIntegration().flashTerminalId || 'flash', // 'A' for dev
          sessionToken: card.authSessionToken,
          cardEntryMethod: CardEntryMethod.ProvidedExternally,
        };

        gcn.menuView.showSpinner(localizeStr(Strings.CHECK_AMOUNT));
        let inquireData;
        try {
          inquireData = await gcn.maitred.giftCardInquire(authData);
          Analytics.track(Analytics.EventName.StoredValueInquire);
        } catch (err) {
          const message = err.message || localizeStr(Strings.ERROR_CARD_CONFIRMATION);
          Analytics.trackEvent({
            eventName: Analytics.EventName.StoredValueInquireError,
            eventData: {
              message,
            },
          });
          this._displayCardNumberError(message);
          return;
        } finally {
          gcn.menuView.dismissSpinner();
        }

        const { authData: updatedAuthData, balance } = inquireData;

        gcn.orderManager.setBalanceForStoredValueCard(card.lastFour, balance);

        if (balance === 0) {
          this._displayCardNumberError(
            `${GcnHtml.htmlFromPrice(balance)} ${localizeStr(Strings.BALANCE)}`,
          );
          Analytics.track(Analytics.EventName.StoredValueNoBalance);
          this._renderSessionStoredValueCards(unusedStoredValueCards);
          return;
        }

        const defaultAmount = Math.min(gcn.orderManager.getGrandTotal(), balance);
        Analytics.track(Analytics.EventName.StoredValueSufficientBalance);
        const { applyError } = gcn.loyaltyManager.captureAppliedGiftCardAmount(
          updatedAuthData,
          defaultAmount,
          this._callback,
        );

        if (applyError) {
          this._displayCardNumberError(applyError);
        }
      });
      $chooseStoredValueCardList.append($storedValueCard);
    });
    this.$formGroup.html($chooseStoredValueCardList);
  },

  _renderStoredValueCardInput() {
    const isStoredValue = this._mode === 'stored-value';
    // gift card number form
    const useNumberInputForGiftCard = validCardNumberInputRegexIsNumericOnly(this._integration);
    this.$giftCardNumberLabel = $(
      `<label for="gift-card-number-input">${localizeStr(
        isStoredValue ? Strings.ENTER_YOUR_GIFT_CARD_NUMBER : Strings.ENTER_YOUR_COMP_CARD_NUMBER,
      )}:</label>`,
    );
    this.$giftCardNumberInput = $(
      `<input class="form-control" ${
        useNumberInputForGiftCard ? 'type="number"' : ''
      } id="gift-card-number-input" autocomplete="off" autocorrect="off"
          ${this._prefilledCardNumber ? `value="${this._prefilledCardNumber}" disabled` : ''} />`,
    );

    new GCNForcePlainKeyboard(this.$giftCardNumberInput).enableForcePlainKeyboardIfNeeded();

    this.$searchButton = $(
      `<div role="button" class="button ok" id="search-btn">${localizeStr(Strings.SEARCH)}</div>`,
    ).addClass('disabled');

    if (this._validPinLengths) {
      this.$el.css('height', 'auto');
      this.$giftCardPinLabel = $(
        `<label for="gift-card-pin-input">${localizeStr(
          isStoredValue ? Strings.ENTER_YOUR_GIFT_CARD_PIN : Strings.ENTER_YOUR_COMP_CARD_PIN,
        )}:</label>`,
      );
      this.$giftCardPinInput = $(
        '<input class="form-control" type="number" id="gift-card-pin-input" autocomplete="off" autocorrect="off" />',
      );
    }

    this.$formGroup.append(
      this.$giftCardNumberLabel,
      this.$giftCardNumberInput,
      this.$giftCardPinLabel,
      this.$giftCardPinInput,
      this.$searchButton,
    );
    this.$giftCardPopupTitleWrapper = this.$('#gift-card-popup-title-wrapper');
    this.$giftCardPopupTitleWrapper.append(
      `<div class="gift-card-popup-title">${localizeStr(isStoredValue ? Strings.USE_GIFT_CARD : Strings.USE_COMP_CARD)}</div>`,
    );

    this.$searchButton.onButtonTapOrHold('gcvSearch', () => {
      if (this.$searchButton.hasClass('disabled')) {
        return;
      }

      this._submitGiftCardNumber();
    });

    // card number validation
    this.$giftCardNumberInput.keypress((e) => {
      if (e.keyCode === backspaceCharKeyCode) {
        return;
      }

      if (!this._validCardNumberInputRegex.test(e.key)) {
        e.preventDefault();
        return;
      }

      const existingInput = $(e.target).val();
      if (existingInput.length > _.max(this._validCardNumberLengths) - 1) {
        e.preventDefault();
      }
    });

    // card pin validation
    this.$giftCardPinInput?.keypress((e) => {
      if (e.keyCode === backspaceCharKeyCode) {
        return;
      }

      if (this._validPinInputRegex && !this._validPinInputRegex.test(e.key)) {
        e.preventDefault();
        return;
      }

      const existingInput = $(e.target).val();
      if (existingInput.length > _.max(this._validPinLengths) - 1) {
        e.preventDefault();
      }
    });

    this.$giftCardNumberInput.keyup(() => {
      this._validateInput();
    });
    this.$giftCardPinInput?.keyup(() => {
      this._validateInput();
    });

    if (this._prefilledCardNumber) {
      this.$giftCardNumberInput.focus();
      this._submitGiftCardNumber();
    }
  },

  render() {
    const html =
      '<div class="gift-card-view-top">' +
      '<div class="icon"></div>' +
      '<div id="gift-card-popup-title-wrapper"></div>' +
      '</div>' +
      '<div class="gift-card-view-bottom">' +
      '<div class="form-group"></div>' +
      '</div>';
    this.$el.html(html);
    this.$icon = this.$('.icon');
    this.$formGroup = this.$('.form-group');

    const unusedSessionStoredValueCards = this._getUnusedSessionStoredValueCards();
    if (unusedSessionStoredValueCards.length) {
      this._renderSessionStoredValueCards(unusedSessionStoredValueCards);
      return this;
    }

    this._renderStoredValueCardInput();

    if (this._cardEntryMethod === CardEntryMethod.Scan) {
      this._validateInput();
    }

    this.$icon.delayedScaleIn();

    return this;
  },
});
