import { Strings } from '@biteinc/common';
import { StringHelper } from '@biteinc/core-react';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNNumberEntryView } from './gcn_number_entry_view';

// General purpose phone number input UI.
// Inheritors of this class need to specify the UI (explanation text, icon, etc) as well as
// functionality when the skip or done button are pressed.
export const GCNPhoneNumberEntryView = GCNNumberEntryView.extend({
  className: 'number-entry-view phone',
  __expectedInputLength: 10,
  __validateInputAsPhoneNumber: true,

  initialize(options = {}, ...args) {
    options.userNumber = gcn.orderManager.getGuestPhoneNumber() || '';

    GCNNumberEntryView.prototype.initialize.apply(this, [options, ...args]);
  },

  // Exact same formatting logic as in gcn_sms_promo_view but had to hotfix
  __updateDisplayValue() {
    const displayText = StringHelper.toFormattedPhoneNumber(this.__userNumber);
    this._$numberDisplay.toggleClass('error', false);
    this._$numberDisplay.text(displayText);
    this._$numberEntryInput.val(displayText);
  },

  render(...args) {
    GCNNumberEntryView.prototype.render.apply(this, args);

    this.__$footerLink.htmlOrText(`${localizeStr(Strings.DISCLAIMER_PHONE_LINK)}`);
    this.__$footerDescription.htmlOrText(`${localizeStr(Strings.DISCLAIMER_PHONE_DESCRIPTION)}`);
  },
});
