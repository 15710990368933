import { ErrorCode, ErrorMessage, Strings } from '@biteinc/common';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import Analytics from '../utils/analytics';
import { GCNLoyaltyProgramHeaderView } from './gcn_loyalty_program_header_view';
import { GCNMarketingConsentView } from './gcn_marketing_consent_view';
import { GCNPhoneNumberEntryView } from './gcn_phone_number_entry_view';

export const GCNSimpleLoyaltySmsSignupView = GCNPhoneNumberEntryView.extend({
  className: `${GCNPhoneNumberEntryView.prototype.className} simple-signup`,

  render(...args) {
    GCNPhoneNumberEntryView.prototype.render.apply(this, args);

    this.$header = new GCNLoyaltyProgramHeaderView({
      isLoyaltySignUp: true,
    }).render().$el;

    this.$el.prepend(this.$header);

    this.__$label.htmlOrText(`${localizeStr(Strings.ENTER_PHONE_LOYALTY)}:`);

    if (gcn.menu.settings.get('displayMarketingConsentCheckbox')) {
      this.marketingConsentView = new GCNMarketingConsentView();
      this.marketingConsentView.render().$el.insertBefore(this.$('.footer'));
    }

    this.setDoneButtonText(localizeStr(Strings.SEND_LOYALTY_SMS));
    this.__$doneButton.onButtonTapOrHold('slsvDone', () => {
      gcn.menuView.showSpinner('Sending SMS');

      if (gcn.menu.settings.get('displayMarketingConsentCheckbox')) {
        const guestConsentedToMarketing = this.marketingConsentView.getValue();
        gcn.orderManager.setGuestConsentedToMarketing(guestConsentedToMarketing);
      }

      gcn.maitred.sendSimpleSignUpMessage(this.__userNumber, (err) => {
        gcn.menuView.dismissSpinner();
        if (err) {
          gcn.menuView.dismissStablePopup();
          if (err.code === ErrorCode.LocationMisconfigured) {
            gcn.menuView.showSimpleAlert(err.message);
          } else {
            gcn.menuView.showSimpleAlert(ErrorMessage.GENERIC);
          }
          return;
        }

        gcn.loyaltyManager.smsInvitationWasSent();
        Analytics.track(Analytics.EventName.LoyaltySimpleSignupComplete);
        this.trigger(BackboneEvents.GCNSimpleLoyaltySignupView.SmsInvitationWasSent, this);
      });
    });
    this.__$icon.hide();
    this.__$skipButton.hide();

    return this;
  },
});
