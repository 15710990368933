import { Strings } from '@biteinc/common';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';

import { GCNPhoneNumberEntryView } from './gcn_phone_number_entry_view';

export const GCNReceiptTextPhoneNumberView = GCNPhoneNumberEntryView.extend({
  className: `${GCNPhoneNumberEntryView.prototype.className} receipt-text`,

  render(...args) {
    GCNPhoneNumberEntryView.prototype.render.apply(this, args);

    this.$el.prepend(this.$header);

    this.__$label.htmlOrText(`${localizeStr(Strings.ENTER_PHONE_RECEIPT)}:`);

    this.setDoneButtonText(localizeStr(Strings.TEXT_RECEIPT));
    this.__$doneButton.onButtonTapOrHold('slsvDone', () => {
      gcn.menuView.showSpinner('Sending Receipt');

      this.listenTo(gcn.orderManager, BackboneEvents.GCNOrderManager.TextReceiptDidSend, () => {
        gcn.menuView.dismissSpinner();
        gcn.menuView.showSimpleAlert(localizeStr(Strings.TEXT_RECEIPT_SEND_SUCCESS));
      });

      this.listenToOnce(
        gcn.orderManager,
        BackboneEvents.GCNOrderManager.TextReceiptFailedSend,
        () => {
          gcn.menuView.dismissSpinner();
          gcn.menuView.showSimpleAlert(localizeStr(Strings.TEXT_RECEIPT_SEND_FAILED));
        },
      );

      gcn.orderManager.sendOrderReceiptText(this.__userNumber);
    });
    this.__$icon.hide();
    this.__$skipButton.hide();

    return this;
  },
});
