import { ErrorCode, I9nSchemaBySystem, Strings } from '@biteinc/common';
import { IntegrationSystem } from '@biteinc/enums';

import type { AuthData } from '~/app/js/gcn_maitred_client';
import { localizeStr, str } from '~/app/js/localization/localization';
import GcnKiosk from '~/app/js/models/gcn_kiosk';
import LocalStorage from '~/app/js/utils/local_storage';
import { GCNCardWithPinView } from '~/app/js/views/gcn_card_with_pin_view';
import { GCNEmailReceiptView } from '~/app/js/views/gcn_email_receipt_view';
import GcnGiftCardBarcodeView from '~/app/js/views/gcn_gift_card_barcode_view';

import { useStore } from '../stores';

module GcnGiftCardHelper {
  async function getBalance<T extends AuthData>(
    authData: T,
  ): Promise<{ authData: T; balance: number }> {
    switch (gcn.location.get('giftCardI9n')) {
      // case IntegrationSystem.PinataStoredValue: // stub for testing
      case IntegrationSystem.Worldpay:
        // stub grandTotal because Worldpay and Archer would need 2 swipes to do a balance check
        return {
          authData,
          balance: gcn.orderManager.getGrandTotal(),
        };
      case IntegrationSystem.Archer:
        return {
          authData,
          balance: gcn.orderManager.getGrandTotal(),
        };
      case IntegrationSystem.FreedomPayKiosk:
        return {
          authData,
          balance: gcn.orderManager.getGrandTotal(),
        };
    }

    const inquireData = await gcn.maitred.giftCardInquire(authData);
    const cardNumber = authData.cardNumber!;

    gcn.orderManager.setBalanceForStoredValueCard(cardNumber, inquireData.balance);

    return inquireData;
  }

  export async function captureMaximumAmount(authData: AuthData): Promise<void> {
    gcn.menuView.showSpinner(localizeStr(Strings.LOOKUP_CARD));

    let inquireData;
    try {
      inquireData = await getBalance(authData);
    } finally {
      gcn.menuView.dismissSpinner();
    }

    const { authData: updatedAuthData, balance } = inquireData;

    if (balance <= 0) {
      const err = new Error(`0 ${str(Strings.BALANCE)}`);
      // @ts-expect-error we haven't defined a code on our errors
      err.code = ErrorCode.StoredValueCardInsufficientFunds;
      throw err;
    }

    const defaultAmount = Math.min(gcn.orderManager.getGrandTotal(), balance);
    gcn.menuView.showSpinner(localizeStr(Strings.PROCESSING_PAYMENT));

    let data;
    try {
      data = await gcn.maitred.giftCardCapture(updatedAuthData, defaultAmount);
    } finally {
      gcn.menuView.dismissSpinner();
    }

    gcn.orderManager.setOrderFromJSON(data.order);
    if (gcn.location.useOrdersApiV2()) {
      gcn.orderManager.setTransactionsFromJson(data.successfulTransactions);
    } else {
      gcn.orderManager.setTransactionsFromJson([data.transaction]);
    }
    useStore.getState().checkout.onStoredValueUpdated();
  }

  export function ensureGuestEmail(callback: Function): void {
    if (gcn.orderManager.getGuestEmail()) {
      callback();
      return;
    }
    const storedGuestEmail = LocalStorage.getItem('guestEmail');
    // Payment by token requires an email because it's generally shared with eComm.
    gcn.menuView.showModalPopup(
      new GCNEmailReceiptView({
        callback: () => {
          callback();
        },
        setEmailOnly: true,
        showRequiredMessage: true,
        ...(storedGuestEmail && { email: storedGuestEmail }),
        ...(window.prefilledGuestEmail && { email: window.prefilledGuestEmail }),
      }),
    );
  }

  function storedValueUsesBarcode(): boolean {
    const storedValueIntegration = gcn.location.getStoredValueIntegration();
    if (
      !storedValueIntegration ||
      !I9nSchemaBySystem[storedValueIntegration.system].supportsBarcode
    ) {
      return false;
    }
    // Obviously can't scan anything on the web
    if (window.isFlash) {
      return false;
    }
    // We need a scanner or at least be in preview
    if (!GcnKiosk.isScannerConnected() && !window.isKioskPreview) {
      return false;
    }
    switch (storedValueIntegration.system) {
      case IntegrationSystem.PaytronixGift:
        return !!storedValueIntegration.hasBarcodes;
      default:
        return true;
    }
  }

  export function showGiftCardView(mode: 'comp-card' | 'stored-value', callback: Function): void {
    if (mode === 'stored-value' && storedValueUsesBarcode()) {
      const giftCardBarcodeView = new GcnGiftCardBarcodeView({
        callback: () => {
          gcn.menuView.dismissStablePopup();
          callback();
        },
      });
      gcn.menuView.showStablePopup(giftCardBarcodeView, 'gift-card-barcode-view');
    } else {
      const cardWithPinView = new GCNCardWithPinView(mode, {
        callback: () => {
          gcn.menuView.dismissStablePopup();
          callback();
        },
      });
      gcn.menuView.showStablePopup(cardWithPinView, 'gift-card-view');
    }
  }

  export function showGiftCardViewWithPrefilledCardNumber(
    cardNumber: string,
    callback: Function,
  ): void {
    const giftCardView = new GCNCardWithPinView('stored-value', {
      cardNumber,
      callback: () => {
        gcn.menuView.dismissStablePopup();
        callback();
      },
    });
    gcn.menuView.showStablePopup(giftCardView, 'gift-card-view');
  }
}

export default GcnGiftCardHelper;
