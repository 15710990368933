import _ from 'underscore';

import { Strings } from '@biteinc/common';

import { localizeStr } from '~/app/js/localization/localization';

import { GCNView } from './gcn_view';

export const GCNMarketingConsentView = GCNView.extend({
  className: 'marketing-consent-view',

  template: _.template(
    // prettier-ignore
    '<input id="marketing-consent-input" class="marketing-consent-input" type="checkbox" />' +
    '<label class="marketing-consent-label" for="marketing-consent-input"></label>',
  ),

  getValue() {
    // If this view shouldn't be displayed, as a failsafe, return false.
    return (
      !!gcn.menu.settings.get('displayMarketingConsentCheckbox') &&
      this._$guestConsentedToMarketingField.is(':checked')
    );
  },

  render() {
    if (!gcn.menu.settings.get('displayMarketingConsentCheckbox')) {
      // If this view shouldn't be displayed, as a failsafe, return an empty element.
      // Worse-case scenario is that there is an empty block of padding in the DOM, but make sure
      // that this view is only rendered when needed.
      return this;
    }

    this.$el.html(this.template());

    this._$guestConsentedToMarketingField = this.$('.marketing-consent-input');
    this._$guestConsentedToMarketingField.prop(
      'checked',
      gcn.orderManager.getGuestConsentedToMarketing(),
    );

    this.$('.marketing-consent-label').htmlOrText(localizeStr(Strings.MARKETING_CONSENT));

    return this;
  },
});
