import { LoyaltyAuthMethod } from '@biteinc/enums';

export module LoyaltyUtils {
  export const validateEmail = (email?: string): boolean => {
    // validate email with regex
    const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return re.test(email || '');
  };

  export const validatePhone = (phone: string): boolean => {
    const re = /^\d{10}$/;
    return re.test(phone);
  };

  export const validateCardEntry = (cardNumber: string, validLengths: number[]): boolean => {
    return !!cardNumber.length && validLengths.includes(cardNumber.length);
  };

  export const isValidAuthInput = (
    authMethod: LoyaltyAuthMethod,
    identifier?: string,
    authValue?: string,
  ): boolean => {
    switch (authMethod) {
      case LoyaltyAuthMethod.EmailPhoneNumber: {
        const cleanedAuth = (authValue ? `${authValue}` : '').replace(/\D/g, '');
        if (!LoyaltyUtils.validatePhone(cleanedAuth)) {
          return false;
        }
        if (!LoyaltyUtils.validateEmail(identifier as string)) {
          return false;
        }
        return true;
      }
      case LoyaltyAuthMethod.PhoneNumber:
        return LoyaltyUtils.validatePhone((identifier ?? '').replace(/\D/g, '') || '');
      case LoyaltyAuthMethod.EmailPassword:
        return LoyaltyUtils.validateEmail(identifier as string);
      case LoyaltyAuthMethod.UsernamePassword:
        return !!identifier && !!authValue;
    }

    return true;
  };

  export enum LoyaltySignUpMethod {
    QR = 'qrCode',
    PHONE = 'phone',
  }
}
