export namespace GcnUtils {
  export function getImagePath(imageUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      gcn.requestImageByUrl(imageUrl, (err, imagePath) => {
        if (err) {
          reject();
        }
        resolve(imagePath);
      });
    });
  }
}
