import { I9nSchemaBySystem } from '@biteinc/common';
import type { IntegrationSystem } from '@biteinc/enums';

import type GcnLocation from '~/app/js/models/gcn_location';

type StoredValueOrCompCardI9n =
  | GcnLocation.PartialStoredValueI9n<IntegrationSystem.StoredValue>
  | GcnLocation.PartialCompCardI9n<IntegrationSystem.CompCard>;

export function getShouldShowPinInput(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): boolean {
  return !!storedValueOrCompCardI9n?.requiresPin;
}

export function getValidCardNumberLengths(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): number[] {
  return (
    (storedValueOrCompCardI9n &&
      I9nSchemaBySystem[storedValueOrCompCardI9n.system].validCardNumberLengths) || [16]
  );
}

export function getValidCardNumberInputRegex(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): RegExp {
  const regexStr =
    (storedValueOrCompCardI9n &&
      I9nSchemaBySystem[storedValueOrCompCardI9n.system].validCardNumberInputRegex) ||
    '^[0-9]$';
  return new RegExp(regexStr);
}

export function validCardNumberInputRegexIsNumericOnly(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): boolean {
  const regexStr =
    (storedValueOrCompCardI9n &&
      I9nSchemaBySystem[storedValueOrCompCardI9n.system].validCardNumberInputRegex) ||
    '^[0-9]$';
  return regexStr === '^[0-9]$';
}

export function getValidPinLengths(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): number[] | undefined {
  return (
    storedValueOrCompCardI9n && I9nSchemaBySystem[storedValueOrCompCardI9n.system].validPinLengths
  );
}

export function getValidPinInputRegex(
  storedValueOrCompCardI9n: StoredValueOrCompCardI9n | undefined,
): RegExp | undefined {
  const regexStr =
    storedValueOrCompCardI9n &&
    I9nSchemaBySystem[storedValueOrCompCardI9n.system].validPinInputRegex;
  return regexStr ? new RegExp(regexStr) : undefined;
}
