import $ from 'jquery';

import { Strings } from '@biteinc/common';

import { localizeStr } from '~/app/js/localization/localization';

import ScreenReaderHelper from '../../screen_reader_helper';
import { GCNView } from '../gcn_view';

export const GCNFullScreenFlowStepView = GCNView.extend({
  className: 'full-screen-flow-step-view',

  initialize(options, ...args) {
    GCNView.prototype.initialize.apply(this, [options, ...args]);

    this.options = options || {};
    this.parent = options.parent;

    this.addRefreshOnLanguageChangeSubscription();
  },

  destroy(...args) {
    GCNView.prototype.destroy.apply(this, args);
    this.parent = null;
  },

  setIsCurrent(isCurrent) {
    this.$el.attr('aria-hidden', !isCurrent);
    this.$el.css('visibility', isCurrent ? 'visible' : 'hidden');
  },

  setNextButtonText(text) {
    this.$nextButton.htmlOrText(text);
    this.setNextButtonAriaLabel(text);
  },

  setNextButtonAriaLabel(text) {
    this.$nextButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  setPrevButtonText(text) {
    this.$prevButton.htmlOrText(text);
    this.$prevButton.attr('aria-label', ScreenReaderHelper.prepareAriaLabel(text));
  },

  render() {
    this.$el.html('');

    this.$el.append(
      $(
        // prettier-ignore
        `<div class="step-panel">` +
          // This is where the subclasses put their stuff.
          `<div class="step-body"></div>` +
          `<div class="warning-toast"></div>` +
          `<div class="button-bar">` +
            // For JAWS, we have two type of navigation:
            // 1. Previous item, Next item
            // 2. Previous heading, Next heading
            // It has to be a heading to jump from section to section. We need to let user quickly
            // focus to the buttons, thus it becomes heading.
            `<div role="heading" aria-level="1" class="button prev">${localizeStr(Strings.BACK)}</div>` +
            `<div class="buttons-right">` +
              `<div role="heading" aria-level="1" class="button next">${localizeStr(Strings.NEXT)}</div>` +
            `</div>` +
          `</div>` +
        `</div>`,
      ),
    );

    this.$prevButton = this.$el.find('.button.prev');
    this.$nextButton = this.$el.find('.button.next');
    this.$stepBody = this.$el.find('.step-body');
    this.$warningToast = this.$el.find('.warning-toast');
    this.$stepPanel = this.$el.find('.step-panel');
    this.$buttonBar = this.$el.find('.button-bar');
    this.$buttonsRight = this.$el.find('.buttons-right');
    this.setPrevButtonText(localizeStr(Strings.BACK));
    this.setNextButtonText(localizeStr(Strings.NEXT));

    this.$prevButton.onButtonTapOrHold('fsfsvPrev', () => {
      if (this.$prevButton.hasClass('disabled')) {
        return;
      }
      if (this.parent) {
        if (this.cancelStep) {
          this.cancelStep();
        }
        this.parent.prevStep();
      }
    });
    this.$nextButton.onButtonTapOrHold('fsfsvNext', () => {
      if (this.$nextButton.hasClass('disabled')) {
        return;
      }
      // This allows subclasses to override the nextStep call and call it
      // themselves if they need to show intermediate UI.
      let confirmResult = true;
      if (this.confirmStep) {
        confirmResult = this.confirmStep();
      }
      if (confirmResult && this.parent) {
        this.parent.nextStep(this);
      }
    });

    // Adjust to fill screen.
    const bodyHeight = window.innerHeight || $('body')[0].clientHeight;
    const margin = gcn.isMobile() ? 0 : 48;

    if (gcn.location.hasBottomBarButtons()) {
      this.$stepPanel.toggleClass('with-bottom-bar', true);
    } else {
      this.$stepPanel.parent().css('height', `calc(${bodyHeight}px - ${margin}px)`);
      this.$stepPanel.css('height', `calc(100% - var(--bottom-bar-space))`);
    }

    return this;
  },
});

GCNFullScreenFlowStepView.StepType = {
  AddonSet: 'addonset',
  Confirmation: 'confirmation',
  HandlePayment: 'handlepayment',
  PaymentCompleted: 'paymentcompleted',
  PriceOption: 'priceoption',
  OrderSummary: 'ordersummary',
};
