import $ from 'jquery';
import React from 'react';
import ReactDOM from 'react-dom/client';
import _ from 'underscore';

import { Strings } from '@biteinc/common';
import { AccountPopover, ServeUpAppHelper } from '@biteinc/core-react';

import { BackboneEvents } from '~/app/js/backbone-events';
import { localizeStr } from '~/app/js/localization/localization';
import { Cart, LoyaltyAccountButton } from '~/components';
import { GcnCustomerAccountHelper } from '~/helpers';
import { AuthService } from '~/services';
import { useStore } from '~/stores';

import LocalStorage from '../utils/local_storage';
import { GCNSmsPromoView } from './gcn_sms_promo_view';
import { GCNView } from './gcn_view';

export const GCNTopNavView = GCNView.extend({
  className: 'top-nav-view',
  template: _.template(
    // prettier-ignore
    '<div class="container bg-color-spot-1"></div>' +
    '<span class="button-container home" aria-hidden="true">' +
      '<span role="button" class="button font-body home"><%= homeString %></span>' +
    '</span>' +
    '<% if (linkInTitle) { %>' +
      '<a href="/" class="title-container">' +
        '<span class="title font-title" aria-hidden="true"></span>' +
      '</a>' +
    '<% } else { %>' +
      '<span class="title-container">' +
        '<span class="title font-title" aria-hidden="true"></span>' +
      '</span>' +
    '<% } %>' +
    '<span class="button-container settings">' +
      '<span role="button" class="button font-body settings"></span>' +
      '<span role="button" class="button font-body banked-points"></span>' +
      '<span role="button" class="loyalty-account-button"></span>' +
      '<span role="button" class="cart-button"></span>' +
    '</span>' +
    '<div class="settings-menu"></div>',
  ),

  initialize(...args) {
    GCNView.prototype.initialize.apply(this, args);

    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.MenuDidUpdate, this._menuDidUpdate);
    this.listenTo(gcn, BackboneEvents.GCNMenuAppView.LocationDidUpdate, this._locationDidUpdate);
    this.listenTo(gcn.orderManager, BackboneEvents.GCNOrderManager.CustomerDoesExist, this.render);
  },

  setTitle(title) {
    this.title = title;
    this.$title.text(this.title);
  },

  setTitleBarImage(image) {
    if (image && this.titleBarImage === undefined) {
      // Save for future rerenders.
      this.titleBarImage = image;
    }
    gcn.requestImageByUrl(image.url, (err, imgPath) => {
      let margin = 8;
      let availableWidth = this.$el.outerWidth() - 40;
      const buttonMax = Math.max(
        this.$('.button-container.home').outerWidth(),
        this.$('.button-container.settings').outerWidth(),
      );
      availableWidth -= buttonMax * 2;
      let width = Math.min(image.width, Math.min(300, availableWidth));
      let height = image.height;
      if (image.width > width) {
        height = Math.floor((width / image.width) * height);
      }
      const selfHeight = this.$el.outerHeight() - margin * 2;
      const prevHeight = height;
      height = Math.min(selfHeight, prevHeight);
      if (prevHeight > selfHeight) {
        width = Math.floor((selfHeight / prevHeight) * width);
      }

      const $img = $(`<img src="${imgPath}" height="${height}" width="${width}" />`);
      if (selfHeight > height) {
        margin += Math.floor((selfHeight - height) / 2);
      }
      $img.css('margin', `${margin}px 0px`);
      this.$title.html($img);
    });
  },

  setMenuPage(menuPage) {
    this._$backButton.show();
    if (gcn.menu.getMenuPageLevel(menuPage.id) === 1) {
      if (window.isFlash) {
        this._$backButton.hide();
      }
      this._$backButton.htmlOrText(localizeStr(Strings.EXIT));
    } else {
      this._$backButton.htmlOrText(localizeStr(Strings.BACK));
    }
  },

  _menuDidUpdate() {
    if (!gcn.location.showBankedPointsDemo()) {
      this.$bankedPointsButton.hide();
      this.$settingsButton.show();
    } else {
      this.$settingsButton.hide();
    }

    this._updateTitle();
  },

  _locationDidUpdate() {
    this._updateTitle();
  },

  _updateTitle() {
    const titleBarImage = gcn.menu.getTitleBarImage();
    if (titleBarImage) {
      this.setTitleBarImage(titleBarImage);
    } else if ((gcn.menu.getMenuTitle() || '').length) {
      this.setTitle(gcn.menu.getMenuTitle());
    } else {
      this.setTitle(gcn.location.get('orgName'));
    }
  },

  _showBankedPointsButton() {
    const self = this;
    setTimeout(() => {
      self.$settingsButton.slideUp();
    }, 1500);

    setTimeout(() => {
      self.$bankedPointsButton.slideDown({
        complete() {
          const animation = 'tada';
          self.$bankedPointsButton.toggleClass('animated', true);
          self.$bankedPointsButton.toggleClass(animation, true);
          self.$bankedPointsButton.one(
            'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
            () => {
              self.$bankedPointsButton.toggleClass('animated', false);
              self.$bankedPointsButton.toggleClass(animation, false);
            },
          );
        },
      });
      self.$bankedPointsButton.parent().onButtonTapOrHold('tnvBankedPoints', () => {
        const smsPromoView = new GCNSmsPromoView();
        self.listenToOnce(smsPromoView, BackboneEvents.GCNSmsPromoView.DidCompleteLookup, () => {
          gcn.menuView.dismissStablePopup();
          self.$bankedPointsButton.slideUp({
            complete() {
              self.$settingsButton.slideDown();
              self._resetSettingsButtonAction();
            },
          });
        });
        gcn.menuView.showStablePopup(smsPromoView, 'sms-promo-view');
      });
    }, 2500);
  },

  _unmountAccountPopover() {
    this.accountPopoverRoot.unmount();
    this.$popoverContainer.remove();
    this.$popoverContainer = null;
    this.accountPopoverIsShown = false;
    document.removeEventListener('mousedown', this._handleClickOutside);
    document.removeEventListener('pointerdown', this._handleClickOutside);
  },

  _renderAccountPopover(customer, event) {
    if (!this.$popoverContainer) {
      this.$popoverContainer = $('<div class="popover-container"></div>');
      this.$settingsButton.append(this.$popoverContainer);
    }

    if (this.accountPopoverIsShown) {
      this._unmountAccountPopover();
      return;
    }

    const qrCodeString = ServeUpAppHelper.getQrCodeString(customer);
    this.accountPopoverRoot = ReactDOM.createRoot(this.$popoverContainer[0]);
    this.accountPopoverIsShown = true;
    this.wrapperRef = React.createRef();
    this._handleClickOutside = (e) => {
      if (this.$settingsButton[0].contains(e.target)) {
        document.removeEventListener('mousedown', this._handleClickOutside);
        document.removeEventListener('pointerdown', this._handleClickOutside);
        return;
      }
      if (this.wrapperRef && !this.wrapperRef.current?.contains(e.target)) {
        this._unmountAccountPopover();
      }
    };
    document.addEventListener('mousedown', this._handleClickOutside);
    document.addEventListener('pointerdown', this._handleClickOutside);
    this.accountPopoverRoot.render(
      React.createElement(AccountPopover, {
        innerRef: this.wrapperRef,
        lang: useStore.getState().config.language,
        customStrings: useStore.getState().bridge.menu.settings.customStrings,
        event,
        isVisible: this.accountPopoverIsShown,
        onDismiss: () => {
          this._unmountAccountPopover();
        },
        onSignOut: () => {
          LocalStorage.removeItem(`${window.customerScope}:token`);
          window.location.reload();
        },
        domain: `https://${gcn.location.get('orgDomain')}`,
        customerScope: window.customerScope,
        onShowQrCode:
          qrCodeString && gcn.location.loyaltySupportsShowingQrCode()
            ? () => {
                if (
                  useStore.getState().loyalty.hasFetchedRewards ||
                  !gcn.loyaltyManager.hasLoyaltyAuthData()
                ) {
                  this._showQrCodePage(qrCodeString);
                  return;
                }

                const spinnerMessage = localizeStr(Strings.LOYALTY_GETTING_REWARDS);
                this.listenToOnce(
                  gcn.loyaltyManager,
                  BackboneEvents.GCNLoyaltyManager.DidFetchRewardsNoOrder,
                  () => {
                    // Poor man's state management. Just making sure we are still waiting for rewards
                    // and not something else in the UI.
                    if (!gcn.menuView.isSpinnerShowingMessage(spinnerMessage)) {
                      return;
                    }
                    gcn.menuView.dismissSpinner();
                    this._showQrCodePage(qrCodeString);
                  },
                );
                gcn.menuView.showSpinner(spinnerMessage);
              }
            : null,
      }),
    );
  },

  _showQrCodePage(qrCodeString) {
    AuthService.showQrCode({
      addFundsUrl: ServeUpAppHelper.addFundsUrl,
      signUpUrl: ServeUpAppHelper.signUpUrl,
      loyaltyData: gcn.loyaltyManager.getStatus()
        ? {
            qrCodeString,
            status: gcn.loyaltyManager.getStatus(),
            rewards: gcn.loyaltyManager.getPrefetchedRewards(),
          }
        : null,
    });
  },

  _resetSettingsButtonAction() {
    this.$settingsButton.onButtonTapOrHold('tnvSettings', (event) => {
      if (GcnCustomerAccountHelper.customerAccountsAreEnabled()) {
        const customer = gcn.orderManager.getCustomer();
        if (customer) {
          this._renderAccountPopover(customer, event);
        } else {
          AuthService.showLogin(gcn.location.attributes);
        }
        return false;
      }

      if (gcn.org.showSignupButton && gcn.org.signupUrl) {
        window.open(gcn.org.signupUrl, '_blank');
      }
      return false;
    });
  },

  render() {
    const self = this;

    this.$el.html(
      this.template({
        homeString: localizeStr(Strings.HOME),
        linkInTitle: !!gcn.location.get('orgDomain'),
      }),
    );
    if (gcn.menuView?.menuHasSideNav()) {
      this.$el.addClass('has-side-nav');
    }
    this.$container = this.$('.container');
    this._$backButton = this.$('.button.home');
    this.$title = this.$('.title');
    this.$settingsButton = this.$('.button.settings');
    this.$bankedPointsButton = this.$('.button.banked-points');
    this.$overlay = this.$('.overlay');
    this.$loyaltyAccountButton = this.$('.loyalty-account-button');
    this.$cartButton = this.$('.cart-button');

    if (window.isFlash && GcnCustomerAccountHelper.customerAccountsAreEnabled()) {
      this.$settingsButton.addClass('customer-account');
      const buttonText = gcn.orderManager.getCustomer()
        ? localizeStr(Strings.CUSTOMER_ACCOUNT_BUTTON_LOGGED_IN)
        : localizeStr(Strings.CUSTOMER_ACCOUNT_BUTTON_LOG_IN_TOP_BAR) ||
          localizeStr(Strings.CUSTOMER_ACCOUNT_BUTTON_LOG_IN);
      this.$settingsButton.html(`<span class="button-text">${buttonText}</span>`);
    } else if (window.isFlash && gcn.org.showSignupButton && gcn.org.signupUrl) {
      this.$settingsButton.addClass('signup');
      this.$settingsButton.htmlOrText(
        `<span class="button-text">${localizeStr(Strings.SIGN_UP)}</span>`,
      );
    } else {
      this.$settingsButton.remove();
    }
    this.$bankedPointsButton.text('💎 Banked Points: 770');

    this._$backButton.parent().onButtonTapOrHold('tnvBack', () => {
      self.trigger(BackboneEvents.GCNTopNavView.DidTapBackButton, self);
      return false;
    });

    this._resetSettingsButtonAction();

    if (this.title) {
      this.$title.text(this.title);
    }

    setTimeout(() => {
      if (self.titleBarImage) {
        self.setTitleBarImage(self.titleBarImage);
      }
    }, 1);

    this.$bankedPointsButton.hide();
    if (gcn.location.showBankedPointsDemo() && !this._shownBankedPointsDemo) {
      this._shownBankedPointsDemo = true;
      this._showBankedPointsButton();
    }

    if (window.isFlash) {
      const root = ReactDOM.createRoot(this._$mobileAppCartContainer || this.$cartButton.get(0));
      root.render(React.createElement(Cart));
    } else {
      this.$cartButton.hide();
    }

    if (gcn.location.shouldShowLoyaltyInTheMenu()) {
      this.root = ReactDOM.createRoot(this.$loyaltyAccountButton.get(0));
      this.root.render(React.createElement(LoyaltyAccountButton));
    } else {
      this.$loyaltyAccountButton.hide();
    }

    return this;
  },
});
