import classnames from 'classnames';
import React from 'react';

import { ErrorCode, ErrorMessage, Strings } from '@biteinc/common';
import { LoyaltyAuthEntryMethod, LoyaltyAuthMethod } from '@biteinc/enums';
import { Button, ButtonSpinner, Input } from '@biteinc/ui';

import type { ApiError } from '~/app/js/gcn_maitred_request_manager';
import { useLocalize } from '~/app/js/localization/localization';
import GcnKiosk from '~/app/js/models/gcn_kiosk';
import Analytics from '~/app/js/utils/analytics';
import Errors from '~/app/js/utils/errors';
import { GCNForcePlainKeyboard } from '~/helpers/gcn_force_plain_keyboard';
import { useLocation, useSettings } from '~/stores';
import type { GcnReward } from '~/types/gcn_reward';

import { PhonePad } from '../phone-pad';
import { LoyaltyUtils } from './loyalty.utils';

type FormInputState =
  | {
      type: 'loading';
    }
  | {
      type: 'email-validation-error';
    }
  | {
      type: 'password-validation-error';
    }
  | {
      type: 'username-validation-error';
    }
  | {
      type: 'phone-validation-error';
    }
  | {
      type: 'card-number-validation-error';
    }
  | {
      type: 'auth-error';
      message: string;
    }
  | {
      type: 'idle';
    };

const isLoading = (formState: FormInputState['type']): boolean => {
  return formState === 'loading';
};

function submitLoyaltyAuth(
  identifier: string,
  authValue: string,
  authEntryMethod: LoyaltyAuthEntryMethod,
  setFailedAuthMessage: (errorMsg: string) => void,
  setIsLoading: (isLoading: boolean) => void,
  hasOrder: boolean = false,
): void {
  let unformattedIdentifier = identifier;
  const unformattedAuthValue = authValue;

  if (
    gcn.loyaltyManager.loyaltyUsesPhoneAuth() &&
    [
      LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered,
      LoyaltyAuthEntryMethod.PhoneNumberProvidedExternally,
    ].includes(authEntryMethod)
  ) {
    unformattedIdentifier = identifier.replace(/\D/g, '');
  }
  setIsLoading(true);
  gcn.loyaltyManager.submitLoyaltyAuth(
    unformattedIdentifier,
    unformattedAuthValue,
    authEntryMethod,
    (err: ApiError) => {
      if (err) {
        setIsLoading(false);
        setFailedAuthMessage(Errors.stringFromErrorCode(err.code));
        gcn.loyaltyManager.clearCustomerAuthData();

        return;
      }
      const rewardsFetchMethod = hasOrder ? 'fetchRewards' : 'fetchRewardsNoOrder';
      gcn.loyaltyManager[rewardsFetchMethod]((err: ApiError) => {
        setIsLoading(false);
        if (err) {
          setFailedAuthMessage(Errors.stringFromErrorCode(err.code));
        }
      });
    },
  );
}

function authWithLoyalty(
  identifier: string,
  authValue: string | undefined,
  loyaltyEntryMethod: LoyaltyAuthEntryMethod,
  setFailedAuthMessage: (errMsg: string) => void,
  setIsLoading: (isLoading: boolean) => void,
  hasOrder: boolean = false,
): void {
  Analytics.track(Analytics.EventName.LoyaltyAuthStart);

  if (gcn.loyaltyManager.loyaltyUsesTokenAuth()) {
    let unformattedIdentifier = identifier;
    if (
      gcn.loyaltyManager.loyaltyUsesPhoneAuth() &&
      [
        LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered,
        LoyaltyAuthEntryMethod.PhoneNumberProvidedExternally,
      ].includes(loyaltyEntryMethod)
    ) {
      unformattedIdentifier = identifier.replace(/\D/g, '');
    }
    gcn.loyaltyManager.submitLoyaltyTokenData(unformattedIdentifier, loyaltyEntryMethod, () => {
      const rewardsFetchMethod = hasOrder ? 'fetchRewards' : 'fetchRewardsNoOrder';
      gcn.loyaltyManager[rewardsFetchMethod]((err: ApiError) => {
        setIsLoading(false);
        if (err) {
          const errMessage = Errors.stringFromErrorCode(err.code);
          setFailedAuthMessage(errMessage);
        }
      });
    });
  } else {
    submitLoyaltyAuth(
      identifier,
      authValue!,
      loyaltyEntryMethod,
      setFailedAuthMessage,
      setIsLoading,
      hasOrder,
    );
  }
}

function LoginButton({
  emphasizeSignUp,
  loading,
}: {
  emphasizeSignUp: boolean;
  loading: boolean;
}): JSX.Element {
  const str = useLocalize();
  return (
    <Button
      className={classnames(
        'tw-rounded-md tw-opacity-100 tw-w-1/2 tw-font-bold tw-flex-auto tw-text-lg',
        { 'tw-p-7 tw-text-xl': emphasizeSignUp },
      )}
      size="xl"
      type="submit"
      disabled={loading}
    >
      {loading ? ButtonSpinner : null}
      {loading ? str(Strings.LOOKING_UP_ACCOUNT) : str(Strings.LOGIN)}
    </Button>
  );
}

export function PhoneNumberAsAuthComponent({
  setSelectedAuthMethod,
  handleDismissModal,
  onSignUp,
  emphasizeSignUp,
  hasOrder,
}: {
  setSelectedAuthMethod: (authMethod: LoyaltyAuthMethod) => void;
  handleDismissModal: () => void;
  onSignUp: () => void;
  emphasizeSignUp: boolean;
  hasOrder: boolean;
}): JSX.Element {
  const str = useLocalize();
  const location = useLocation();
  const enableBarcodeAsFallbackToPhoneNumber =
    location.getLoyaltyIntegration()!.enableBarcodeAsFallbackToPhoneNumber &&
    GcnKiosk.isScannerConnected();

  const [phoneNumber, setPhoneNumber] = React.useState<string>(
    gcn.loyaltyManager.loyaltyUsesPhoneAuth() && gcn.orderManager.getGuestPhoneNumber()
      ? gcn.orderManager.getGuestPhoneNumber()!
      : '',
  );
  const [authCredentialsError, setAuthCredentialsError] = React.useState<boolean>(false);
  const [failedAuthMessage, setFailedAuthMessage] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);

  function validateAndSubmitPhoneNumber(event: React.FormEvent): void {
    event.preventDefault();

    const cleanedAuth = `${phoneNumber ?? ''}`.replace(/\D/g, '');
    if (!LoyaltyUtils.validatePhone(cleanedAuth)) {
      setAuthCredentialsError(true);
      return;
    }
    setAuthCredentialsError(false);
    setLoading(true);

    authWithLoyalty(
      cleanedAuth,
      undefined,
      LoyaltyAuthEntryMethod.PhoneNumberManuallyEntered,
      setFailedAuthMessage,
      setLoading,
      hasOrder,
    );

    gcn.orderManager.setGuestPhoneNumber(cleanedAuth);
  }

  return (
    <form
      className="tw-flex tw-flex-col tw-h-full tw-w-full tw-flex-auto tw-justify-center"
      onSubmit={(e) => validateAndSubmitPhoneNumber(e)}
    >
      {failedAuthMessage && (
        <div className="tw-text-[#CC0000] tw-my-0 tw-mx-auto tw-py-4 tw-text-lg">
          {failedAuthMessage}
        </div>
      )}
      <PhonePad
        key={'phone-pad-auth-component'}
        credentialsError={authCredentialsError}
        value={phoneNumber}
        setValue={(value: string) => setPhoneNumber(value)}
      />
      <div
        className={classnames('tw-mt-auto tw-flex tw-content-center tw-justify-center tw-w-full', {
          'tw-flex-col': enableBarcodeAsFallbackToPhoneNumber,
        })}
      >
        <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-gap-5">
          {enableBarcodeAsFallbackToPhoneNumber ? (
            <LoginButton
              emphasizeSignUp={emphasizeSignUp}
              loading={loading}
            />
          ) : (
            <>
              <Button
                variant="outline"
                onClick={() => {
                  emphasizeSignUp ? onSignUp() : handleDismissModal();
                }}
                size="xl"
                className={classnames('tw-w-1/2 tw-flex-auto tw-text-lg', {
                  'tw-p-7 tw-text-xl ': emphasizeSignUp,
                })}
                disabled={loading}
              >
                {emphasizeSignUp ? str(Strings.SIGN_UP) : str(Strings.CONTINUE_AS_GUEST)}
              </Button>
              <LoginButton
                emphasizeSignUp={emphasizeSignUp}
                loading={loading}
              />
            </>
          )}
        </div>
        {enableBarcodeAsFallbackToPhoneNumber ? (
          <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw tw-justify-between tw-items-center tw-gap-5">
            <>
              <Button
                variant="outline"
                onClick={() => {
                  handleDismissModal();
                }}
                size="xl"
                className="tw-w-1/2 tw-flex-auto tw-text-lg tw-font-bold"
                disabled={loading}
              >
                {str(Strings.CONTINUE_AS_GUEST)}
              </Button>
              <Button
                variant="outline"
                className="tw-w-1/2 tw-flex-auto tw-text-lg tw-font-bold"
                size="xl"
                onClick={() => setSelectedAuthMethod(LoyaltyAuthMethod.Barcode)}
                disabled={loading}
              >
                {str(Strings.LOYALTY_APP_AUTH_BARCODE_FALLBACK)}
              </Button>
            </>
          </div>
        ) : null}
      </div>
    </form>
  );
}

export function EmailPasswordComponent({
  handleDismissModal,
  hasOrder,
}: {
  handleDismissModal: () => void;
  hasOrder?: boolean;
}): JSX.Element {
  const str = useLocalize();

  const [formState, setFormState] = React.useState<FormInputState>({ type: 'idle' });

  function validateAndSubmitEmailPassword(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    if (!LoyaltyUtils.validateEmail(email)) {
      setFormState({ type: 'email-validation-error' });
      return;
    }
    if (!password) {
      setFormState({ type: 'password-validation-error' });
    }

    authWithLoyalty(
      email,
      password,
      LoyaltyAuthEntryMethod.EmailAndPasswordManuallyEntered,
      (message: string) => setFormState({ type: 'auth-error', message }),
      (loading) => setFormState(loading ? { type: 'loading' } : { type: 'idle' }),
      hasOrder,
    );
  }

  return (
    <form
      className="tw-flex tw-flex-col tw-w-full tw-flex-auto tw-justify-center"
      onSubmit={(e) => validateAndSubmitEmailPassword(e)}
    >
      <div className="tw-my-0 tw-w-full tw-mx-auto tw-justify-center tw-pt-8">
        <div className={`tw-m-4 tw-flex tw-flex-col tw-justify-center`}>
          {formState.type === 'email-validation-error' && (
            <p className="tw-text-red-500">Invalid Email</p>
          )}

          <Input
            type="text"
            name="email"
            placeholder={gcn.orderManager.getGuestEmail() || 'Enter Email'}
            className="loyalty-account-text-input"
          />
        </div>
        {formState.type === 'password-validation-error' && (
          <p className="tw-text-red-500">Invalid Password</p>
        )}
        <div className={`tw-m-4 tw-flex tw-flex-col tw-justify-center`}>
          <Input
            name="password"
            type="password"
            placeholder="Enter Password"
            className="loyalty-account-text-input"
          />
        </div>
      </div>
      <div className="tw-mt-auto tw-flex tw-content-center tw-justify-center tw-w-full tw-gap-5">
        <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-gap-5">
          <Button
            variant="outline"
            onClick={() => handleDismissModal()}
            className="tw-w-1/2 tw-m-3 tw-flex-auto"
            disabled={isLoading(formState.type)}
            size="xl"
          >
            {str(Strings.CONTINUE_AS_GUEST)}
          </Button>
          <Button
            className="tw-rounded-md tw-opacity-100 tw-bg-gray-400 tw-w-1/2 tw-font-bold tw-flex-auto tw-text-lg"
            type="submit"
            disabled={isLoading(formState.type)}
            size="xl"
          >
            {str(Strings.LOGIN)}
          </Button>
        </div>
      </div>
    </form>
  );
}

export function EmailComponent({
  handleDismissModal,
  hasOrder,
}: {
  handleDismissModal: () => void;
  hasOrder: boolean;
}): JSX.Element {
  const str = useLocalize();

  const [formState, setFormState] = React.useState<FormInputState>({ type: 'idle' });

  const gcnForcePlainKeyboard = new GCNForcePlainKeyboard(null);
  const gcnShouldForcePlainKeyboard = gcnForcePlainKeyboard.shouldForcePlainKeyboard();

  function validateAndSubmitEmailPassword(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get('email') as string;

    if (!LoyaltyUtils.validateEmail(email)) {
      setFormState({ type: 'email-validation-error' });
      return;
    }

    authWithLoyalty(
      email,
      undefined,
      LoyaltyAuthEntryMethod.EmailManuallyEntered,
      (message: string) => setFormState({ type: 'auth-error', message }),
      (loading) => setFormState(loading ? { type: 'loading' } : { type: 'idle' }),
      hasOrder,
    );
  }

  return (
    <form
      className="tw-flex tw-flex-col tw-w-full tw-flex-auto tw-justify-center"
      onSubmit={(e) => validateAndSubmitEmailPassword(e)}
    >
      <div className="tw-my-0 tw-w-full tw-mx-auto tw-justify-center tw-pt-8">
        <div className={`tw-m-4 tw-flex tw-flex-col tw-justify-center`}>
          {formState.type === 'email-validation-error' && (
            <p className="tw-text-red-500">Invalid Email</p>
          )}

          <Input
            type={gcnShouldForcePlainKeyboard ? 'password' : 'text'}
            name="email"
            placeholder={gcn.orderManager.getGuestEmail() || 'Enter Email'}
            className="loyalty-account-text-input"
            onFocus={gcnShouldForcePlainKeyboard ? gcnForcePlainKeyboard.onFocus : undefined}
          />
        </div>
      </div>
      <div className="tw-mt-auto tw-flex tw-content-center tw-justify-center tw-w-full tw-gap-5">
        <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-gap-5">
          <Button
            variant="outline"
            onClick={() => handleDismissModal()}
            className="tw-w-1/2 tw-m-3 tw-flex-auto"
            disabled={isLoading(formState.type)}
            size="xl"
          >
            {str(Strings.CONTINUE_AS_GUEST)}
          </Button>
          <Button
            className="tw-rounded-md tw-opacity-100 tw-bg-gray-400 tw-w-1/2 tw-font-bold tw-flex-auto tw-text-lg"
            type="submit"
            disabled={isLoading(formState.type)}
            size="xl"
          >
            {str(Strings.LOGIN)}
          </Button>
        </div>
      </div>
    </form>
  );
}

export function EmailPhoneNumber({
  handleDismissModal,
  hasOrder,
}: {
  handleDismissModal: () => void;
  hasOrder: boolean;
}): JSX.Element {
  const str = useLocalize();

  const [formState, setFormState] = React.useState<FormInputState>({ type: 'idle' });

  const gcnForcePlainKeyboard = new GCNForcePlainKeyboard(null);
  const gcnShouldForcePlainKeyboard = gcnForcePlainKeyboard.shouldForcePlainKeyboard();

  function setIsLoading(loading: boolean): void {
    if (loading) {
      setFormState({ type: 'loading' });
    } else {
      setFormState({ type: 'idle' });
    }
  }

  function setFailedAuthMessage(message: string): void {
    setFormState({ type: 'auth-error', message });
  }

  function validateAndSubmitEmailPassword(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get('email') as string;
    const phone = formData.get('phone') as string;

    if (formState.type !== 'idle') {
      return;
    }

    if (!LoyaltyUtils.validateEmail(email)) {
      setFormState({ type: 'email-validation-error' });
      return;
    }

    if (!LoyaltyUtils.validatePhone(phone)) {
      setFormState({ type: 'phone-validation-error' });
      return;
    }

    authWithLoyalty(
      email,
      phone,
      LoyaltyAuthEntryMethod.EmailAndPhoneNumberManuallyEntered,
      setFailedAuthMessage,
      setIsLoading,
      hasOrder,
    );

    gcn.orderManager.setGuestPhoneNumber(phone);
  }

  return (
    <form
      className="tw-flex tw-flex-col tw-w-full tw-flex-auto tw-justify-center"
      onSubmit={(e) => validateAndSubmitEmailPassword(e)}
    >
      {formState.type === 'auth-error' && (
        <div className="tw-text-[#CC0000] tw-my-0 tw-mx-auto tw-py-4 tw-text-lg">
          {formState.message}
        </div>
      )}
      <div className="tw-my-0 tw-w-full tw-mx-auto tw-justify-center tw-pt-8">
        <div className={`tw-m-4 tw-flex tw-flex-col tw-justify-center`}>
          {formState.type === 'email-validation-error' && (
            <p className="tw-text-red-500">Invalid Email</p>
          )}

          <Input
            name="email"
            type={gcnShouldForcePlainKeyboard ? 'password' : 'text'}
            placeholder={gcn.orderManager.getGuestEmail() || 'Enter Email'}
            className="loyalty-account-text-input"
            onFocus={gcnShouldForcePlainKeyboard ? gcnForcePlainKeyboard.onFocus : undefined}
          />
        </div>
        <div className={`tw-m-4 tw-flex tw-flex-col tw-justify-center`}>
          {formState.type === 'phone-validation-error' && (
            <p className="tw-text-red-500">Invalid Phone</p>
          )}
          <Input
            name="phone"
            type="tel"
            placeholder="(000) 000 - 0000"
            className="loyalty-account-text-input"
          />
        </div>
      </div>
      <div className="tw-mt-auto tw-flex tw-content-center tw-justify-center tw-w-full">
        <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-text-lg tw-gap-5">
          <Button
            variant="outline"
            onClick={() => handleDismissModal()}
            className="tw-w-1/2 tw-flex-auto"
            disabled={formState.type === 'loading'}
            size="xl"
          >
            {str(Strings.CONTINUE_AS_GUEST)}
          </Button>
          <Button
            className="tw-rounded-md tw-opacity-100 tw-bg-gray-400 tw-w-1/2 tw-font-bold tw-flex-auto tw-text-lg"
            type="submit"
            disabled={formState.type === 'loading'}
            size="xl"
          >
            {str(Strings.LOGIN)}
          </Button>
        </div>
      </div>
    </form>
  );
}

export function UsernamePasswordComponent({
  handleDismissModal,
  hasOrder,
}: {
  handleDismissModal: () => void;
  hasOrder: boolean;
}): JSX.Element {
  const str = useLocalize();

  const [formState, setFormState] = React.useState<FormInputState>({ type: 'idle' });

  function validateAndSubmitUsernamePassword(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const username = formData.get('username') as string;
    const password = formData.get('password') as string;

    if (!username) {
      setFormState({ type: 'username-validation-error' });
      return;
    }
    if (!password) {
      setFormState({ type: 'password-validation-error' });
    }

    authWithLoyalty(
      username,
      password,
      LoyaltyAuthEntryMethod.UsernameAndPasswordManuallyEntered,
      (message: string) => setFormState({ type: 'auth-error', message }),
      (loading) => setFormState(loading ? { type: 'loading' } : { type: 'idle' }),
      hasOrder,
    );
  }

  return (
    <form
      className="tw-flex tw-flex-col tw-w-full tw-flex-auto tw-justify-center"
      onSubmit={(e) => validateAndSubmitUsernamePassword(e)}
    >
      {formState.type === 'auth-error' && (
        <div className="tw-text-[#CC0000] tw-my-0 tw-mx-auto tw-py-4 tw-text-lg">
          {formState.message}
        </div>
      )}
      <div className="tw-my-0 tw-w-full tw-mx-auto tw-justify-center tw-pt-8">
        <div className={`tw-m-4 tw-flex tw-flex-col tw-justify-center`}>
          {formState.type === 'username-validation-error' && (
            <p className="tw-text-red-500">Invalid Username</p>
          )}

          <Input
            type="text"
            name="username"
            placeholder="Enter Username"
            className="loyalty-account-text-input"
          />
        </div>
        {formState.type === 'password-validation-error' && (
          <p className="tw-text-red-500">Invalid Password</p>
        )}
        <div className={`tw-m-4 tw-flex tw-flex-col tw-flex-grow tw-justify-center`}>
          <Input
            type="password"
            name="password"
            placeholder="Enter Password"
            className="loyalty-account-text-input"
          />
        </div>
      </div>
      <div className="tw-mt-auto tw-flex tw-content-center tw-justify-center tw-w-full">
        <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-gap-5">
          <Button
            variant="outline"
            onClick={() => handleDismissModal()}
            className="tw-w-1/2 tw-flex-auto tw-text-lg"
            disabled={formState.type === 'loading'}
            size="xl"
          >
            {str(Strings.CONTINUE_AS_GUEST)}
          </Button>
          <Button
            className="tw-rounded-md tw-w-1/2 tw-font-bold tw-flex-auto tw-text-lg"
            type="submit"
            disabled={formState.type === 'loading'}
            size="xl"
          >
            {formState.type === 'loading' ? str(Strings.LOOKING_UP_ACCOUNT) : str(Strings.LOGIN)}
          </Button>
        </div>
      </div>
    </form>
  );
}

export function CardNumberComponent({
  handleDismissModal,
  hasOrder,
}: {
  handleDismissModal: () => void;
  hasOrder: boolean;
}): JSX.Element {
  const str = useLocalize();

  const [formState, setFormState] = React.useState<FormInputState>({ type: 'idle' });

  function validateAndSubmitCardNumber(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const identifier = formData.get('card-number') as string;

    if (!LoyaltyUtils.validateCardEntry(identifier, [16])) {
      setFormState({ type: 'username-validation-error' });
      return;
    }

    authWithLoyalty(
      identifier,
      undefined,
      LoyaltyAuthEntryMethod.CardNumberManuallyEntered,
      (message: string) => setFormState({ type: 'auth-error', message }),
      (loading) => setFormState(loading ? { type: 'loading' } : { type: 'idle' }),
      hasOrder,
    );
  }

  return (
    <form
      className="tw-flex tw-flex-col tw-w-full tw-flex-auto tw-justify-center"
      onSubmit={(e) => validateAndSubmitCardNumber(e)}
    >
      {formState.type === 'auth-error' && (
        <div className="tw-text-[#CC0000] tw-my-0 tw-mx-auto tw-py-4 tw-text-lg">
          {formState.message}
        </div>
      )}
      <div className="tw-my-0 tw-w-full tw-mx-auto tw-justify-center tw-pt-8">
        <div className={`tw-m-4 tw-flex tw-justify-center`}>
          <div className={`tw-flex tw-flex-col`}>
            <label htmlFor={'card-number-component'}>
              {str(Strings.LOYALTY_CARD_NUMBER_INSTRUCTIONS)}
            </label>
            {formState.type === 'card-number-validation-error' && (
              <p className="tw-text-red-500">Invalid Card Number</p>
            )}
            <Input
              type="text"
              name="card-number"
              placeholder="Enter Card Number"
              className="loyalty-account-text-input"
              id="card-number-component"
            />
          </div>
        </div>
      </div>
      <div className="tw-mt-auto tw-flex tw-content-center tw-justify-center tw-w-full">
        <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-gap-5">
          <Button
            variant="outline"
            onClick={() => handleDismissModal()}
            className="tw-w-1/2 tw-flex-auto tw-text-lg"
            disabled={isLoading(formState.type)}
            size="xl"
          >
            {str(Strings.CONTINUE_AS_GUEST)}
          </Button>
          <Button
            className="tw-rounded-md tw-opacity-100 tw-bg-gray-400 tw-w-1/2 tw-font-bold tw-flex-auto tw-text-lg"
            type="submit"
            disabled={isLoading(formState.type)}
            size="xl"
          >
            {str(Strings.LOGIN)}
          </Button>
        </div>
      </div>
    </form>
  );
}

export const BarcodeScanComponent = ({
  setSelectedAuthMethod,
  handleDismissModal,
  hasOrder,
}: {
  setSelectedAuthMethod: (authMethod: LoyaltyAuthMethod) => void;
  handleDismissModal: () => void;
  hasOrder: boolean;
}): JSX.Element => {
  const str = useLocalize();
  const location = useLocation();
  const { enablePhoneNumberAsFallbackToBarcode } = location.getLoyaltyIntegration()!;

  const [failedAuthMessage, setFailedAuthMessage] = React.useState<string>('');
  const [loading, setLoading] = React.useState(false);

  let scannerFirstData: string | undefined;
  let sentFirstPiece: boolean | undefined;

  const handlePunchhScannerData = (data: string): void => {
    gcn.menuView.dismissModalPopup();
    gcn.menuView.dismissStablePopup();

    if (gcn.loyaltyManager.isPunchhOlo()) {
      authWithLoyalty(
        data,
        undefined,
        LoyaltyAuthEntryMethod.BarcodeScanned,
        setFailedAuthMessage,
        setLoading,
        hasOrder,
      );

      gcn.removeScannerHandler(barcodeScannerHandler);

      return;
    }

    gcn.loyaltyManager.submitLoyaltyTokenData(data, LoyaltyAuthEntryMethod.BarcodeScanned, () => {
      gcn.menuView.dismissSpinner();
      setLoading(true);

      const rewardsFetchMethod = hasOrder ? 'fetchRewards' : 'fetchRewardsNoOrder';
      gcn.loyaltyManager[rewardsFetchMethod]((err: ApiError) => {
        setLoading(false);
        if (err) {
          setFailedAuthMessage(Errors.stringFromErrorCode(err.code));
        }
      });
    });

    gcn.removeScannerHandler(barcodeScannerHandler);
  };

  const handleBarcodeScanData = (scannerData: string): void => {
    gcn.menuView.dismissModalPopup();
    gcn.menuView.dismissStablePopup();

    if (!gcn.loyaltyManager.isPunchhDirect() && !gcn.loyaltyManager.isPunchhOlo()) {
      authWithLoyalty(
        scannerData,
        undefined,
        LoyaltyAuthEntryMethod.BarcodeScanned,
        setFailedAuthMessage,
        setLoading,
        hasOrder,
      );
      gcn.removeScannerHandler(barcodeScannerHandler);

      return;
    }

    // If the scanner data is too long it comes in as two separate events.
    // This only really happens with Punchh but NOT always.
    // Typically with user_as_qr_code
    // We receive the first half, and then the second ~ 5ms after.
    if (!scannerFirstData) {
      scannerFirstData = scannerData;
      sentFirstPiece = false;
      setTimeout(() => {
        if (scannerFirstData && !sentFirstPiece) {
          handlePunchhScannerData(scannerFirstData);
        }
      }, 75);
    } else {
      sentFirstPiece = true;
      handlePunchhScannerData(`${scannerFirstData}${scannerData}`);
    }
  };

  const barcodeScannerHandler = {
    handleScannerData: (scannerData: string) => {
      handleBarcodeScanData(scannerData);
    },
  };

  React.useEffect(() => {
    gcn.pushScannerHandler(barcodeScannerHandler);
    return () => {
      gcn.removeScannerHandler(barcodeScannerHandler);
    };
  });

  return (
    <div className={`tw-flex tw-flex-col tw-flex-grow tw-w-full tw-justify-center`}>
      <div className="tw-flex tw-flex-col tw-w-full tw-justify-center tw-items-center">
        {failedAuthMessage && (
          <div className="tw-text-[#CC0000] tw-my-0 tw-mx-auto tw-py-4 tw-text-lg">
            {failedAuthMessage}
          </div>
        )}
        <p>{str(Strings.LOYALTY_INTRO_QR_SIGN_IN)}</p>
      </div>
      <div className="tw-flex tw-flex-grow tw-justify-center tw-items-center tw-w-full">
        <div className="tw-h-[400px] tw-w-[400px] tw-mt-8">
          <img
            src={
              gcn.menu.settings.get('loyaltyScanQRCodeImage')?.[0]?.url ||
              'https://assets.getbite.com/images-default/pay-with-app@2x.png'
            }
            alt="QR Sign In Code"
            className="tw-m-auto tw-object-contain tw-h-[400px] tw-w-[400px]"
          />
        </div>
      </div>
      <div className="tw-mt-auto tw-flex tw-content-center tw-justify-center tw-w-full">
        <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-gap-5">
          <Button
            variant="outline"
            onClick={() => handleDismissModal()}
            className={`${
              enablePhoneNumberAsFallbackToBarcode ? 'tw-w-1/2' : 'tw-w-full'
            } tw-flex-auto tw-text-lg`}
            disabled={loading}
            size="xl"
          >
            {str(Strings.CONTINUE_AS_GUEST)}
          </Button>

          {enablePhoneNumberAsFallbackToBarcode && (
            <Button
              className="tw-opacity-100 tw-w-1/2 tw-font-bold tw-flex-auto tw-text-lg"
              type="button"
              disabled={loading}
              onClick={() => setSelectedAuthMethod(LoyaltyAuthMethod.PhoneNumber)}
              size="xl"
            >
              {loading ? ButtonSpinner : null}
              {loading ? str(Strings.LOOKING_UP_ACCOUNT) : str(Strings.LOGIN_WITH_PHONE)}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export const Rewards = ({
  rewards,
  preSelectedRewards,
}: {
  rewards?: GcnReward[];
  preSelectedRewards?: GcnReward[];
}): JSX.Element => {
  const str = useLocalize();

  const loyaltyI9n = useLocation().getLoyaltyIntegration();
  const canRedeemMultipleRewards = !!loyaltyI9n?.enableMultipleRewardRedemption;
  const hasPreAppliedRewards = !!preSelectedRewards?.length;

  return (
    <>
      <div className="tw-p-5 tw-text-lg">{str(Strings.TAP_TO_REDEEM)}</div>
      <div className="tw-overflow-y-auto">
        {rewards?.map((reward, idx) => {
          const rewardIsSelected = preSelectedRewards?.some((preSelectedReward: GcnReward) => {
            return preSelectedReward.appliedRewardMapKey() === reward.appliedRewardMapKey();
          });
          // disabled if:
          // 1. cannot redeem multiple rewards; and
          // 2. has already applied a reward; and
          // 3. the applied reward is not the current reward (need to be able to deselect)
          const disabled = !canRedeemMultipleRewards && hasPreAppliedRewards && !rewardIsSelected;
          return (
            <Button
              key={idx}
              className={`loyalty-account__reward_button tw-w-[330px] tw-h-[50px] tw-m-2 tw-text-2xl tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-border tw-border-solid active:tw-scale-105 tw-duration-200 ${
                rewardIsSelected
                  ? 'tw-border-transparent loyalty-account__reward_button-selected tw-text-white tw-bg-[--color-primary] hover:tw-bg-white hover:tw-text-[--color-primary] hover:tw-border-[--color-primary]'
                  : 'tw-border-[--color-primary] tw-bg-white tw-text-[--color-primary] hover:tw-bg-[--color-primary] hover:tw-text-white'
              }`}
              onClick={() => {
                if (rewardIsSelected) {
                  gcn.loyaltyManager.removePreSelectedReward(reward);
                } else {
                  gcn.loyaltyManager.preSelectReward(reward);
                }
              }}
              uncolored={true}
              disabled={disabled}
            >
              <span className="tw-px-2 tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-font-bold">
                {reward.displayName(true)}
              </span>
            </Button>
          );
        })}
      </div>
    </>
  );
};

export const SignUpQRHeaderComponent = ({
  setPhoneSignUp,
  handleDismissModal,
  onLogin,
}: {
  setPhoneSignUp?: () => void;
  handleDismissModal: () => void;
  onLogin: () => void;
}): JSX.Element => {
  const str = useLocalize();
  const settings = useSettings();

  return (
    <React.Fragment key={'qr-sign-up'}>
      <div className="tw-items-center tw-w-full">
        <div className="tw-text-black tw-text-3xl tw-text-left tw-pb-5 tw-font-normal tw-tracking-normal tw-font-title">
          {str(Strings.SIGN_UP)}
        </div>
        <div className="tw-text-black tw-text-lg tw-text-left tw-font-normal tw-tracking-normal">
          {str(Strings.LOYALTY_INTRO_COULD_NOT_FIND_ACCOUNT)}
        </div>
        <div className="tw-text-black tw-text-lg tw-text-left tw-font-normal tw-tracking-normal">
          {str(Strings.LOYALTY_INTRO_QR_SIGN_UP)}
        </div>
      </div>
      <div className="tw-justify-center tw-my-0 tw-mx-auto tw-pt-8">
        <div className="tw-flex tw-flex-col tw-justify-between tw-w-full tw-items-center">
          <div className="tw-h-[240px] tw-w-[240px]">
            {settings.loyaltySignUpQrCodeImage?.length && (
              <img
                src={settings.loyaltySignUpQrCodeImage[0].url}
                alt="QR Sign Up Code"
                className="tw-m-auto"
              />
            )}
          </div>
          {setPhoneSignUp && (
            <>
              <div className="tw-p-3">{str(Strings.OR)}</div>
              <div
                onClick={() => setPhoneSignUp()}
                className="tw-cursor-pointer tw-underline"
              >
                {str(Strings.LOYALTY_INTRO_PHONE_SIGN_UP)}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="tw-mt-auto tw-flex tw-content-center tw-justify-center tw-w-full">
        <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-gap-5">
          <Button
            className="tw-w-1/2 tw-text-lg"
            variant="outline"
            onClick={() => {
              handleDismissModal();
            }}
            size="xl"
          >
            {str(Strings.CONTINUE_AS_GUEST)}
          </Button>
          <Button
            onClick={onLogin}
            className="tw-w-1/2 tw-whitespace-nowrap tw-text-lg"
            size="xl"
          >
            {str(Strings.LOGIN)}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export function SignUpPhoneNumberComponent({
  onLogin,
  setQrSignUp,
  handleDismissModal,
}: {
  setQrSignUp?: () => void;
  onLogin: () => void;
  handleDismissModal: () => void;
}): JSX.Element {
  const str = useLocalize();
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);

  const attemptSignUp = (): void => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
    if (!LoyaltyUtils.validatePhone(cleanedPhoneNumber)) {
      setPhoneNumberError(true);
      return;
    }
    setPhoneNumberError(false);

    setLoading(true);
    gcn.maitred.sendSimpleSignUpMessage(cleanedPhoneNumber, (err) => {
      setLoading(false);
      if (err) {
        gcn.menuView.dismissStablePopup();
        if (err.code === ErrorCode.LocationMisconfigured) {
          gcn.menuView.showSimpleAlert(err.message);
        } else {
          gcn.menuView.showSimpleAlert(ErrorMessage.GENERIC);
        }
        return;
      }

      gcn.menuView.showSimpleAlert(str(Strings.LOYALTY_SIGN_UP_SMS_SENT), () => {
        handleDismissModal();
      });

      gcn.loyaltyManager.smsInvitationWasSent();
      Analytics.track(Analytics.EventName.LoyaltySimpleSignupComplete);

      gcn.orderManager.setGuestPhoneNumber(cleanedPhoneNumber);
    });
  };

  return (
    <React.Fragment key={'phone-sign-up'}>
      <div className="tw-w-full tw-items-center">
        <div className="tw-w-full tw-items-center-content">
          <div className="tw-text-black tw-text-lg tw-text-left tw-font-normal tw-tracking-normal tw-font-title">
            {str(Strings.LOYALTY_INTRO_PHONE_SIGN_UP)}
          </div>
          <div className="tw-text-black tw-text-lg tw-text-left tw-font-normal tw-tracking-normal">
            {str(Strings.LOYALTY_INTRO_PHONE_SIGN_UP_DESCRIPTION)}
          </div>
        </div>
      </div>
      <div className="tw-my-0 tw-mx-auto tw-justify-center tw-pt-8">
        <PhonePad
          credentialsError={phoneNumberError}
          value={phoneNumber as string | null}
          setValue={setPhoneNumber}
        />
      </div>
      {setQrSignUp && (
        <div className="tw-w-full tw-justify-center tw-items-center">
          <div className="tw-p-3">{str(Strings.OR)}</div>
          <div
            onClick={() => setQrSignUp()}
            className="tw-cursor-pointer tw-underline"
          >
            {str(Strings.LOYALTY_SIGN_UP_WITH_QR)}
          </div>
        </div>
      )}
      <div className="tw-mt-auto tw-flex tw-flex-col tw-content-center tw-justify-center tw-w-full">
        <div className="tw-flex tw-flex-row tw-w-full tw-pt-8 tw-pb-0 tw-justify-between tw-items-center tw-gap-5">
          <Button
            className=" tw-w-1/2 tw-text-lg"
            variant={'outline'}
            disabled={loading}
            size="xl"
            onClick={onLogin}
          >
            {str(Strings.LOGIN)}
          </Button>
          <Button
            onClick={() => {
              attemptSignUp();
            }}
            className="tw-w-1/2 tw-text-lg"
            disabled={loading}
            size="xl"
          >
            {str(Strings.SIGN_UP)}
          </Button>
        </div>
        <div
          onClick={() => handleDismissModal()}
          className="tw-cursor-pointer tw-underline"
        >
          {str(Strings.CONTINUE_AS_GUEST)}
        </div>
      </div>
    </React.Fragment>
  );
}
