import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { close, pencilSharp } from 'ionicons/icons';
import React from 'react';

import { Strings } from '@biteinc/common';
import { ModalService } from '@biteinc/core-react';
import { OrderChannel, OrderChannelHelper } from '@biteinc/enums';

import { useLocalize } from '~/app/js/localization/localization';
import { useLocation, useStore } from '~/stores';

import GcnOpeningSequenceManager from '../app/js/gcn_opening_sequence_manager';
import { GcnFulfillmentHelper } from '../helpers';
import { FulfillmentMethodInfo } from './fulfillment-method-info';
import { StoreInfo } from './store-info';

function FulfillmentHeader(): JSX.Element | null {
  const str = useLocalize();
  const location = useLocation();
  const cart = useStore((store) => store.cart);

  function showStoreInfoModal(): void {
    const hide = ModalService.showCustomModal({
      cssClass: 'store-info-modal',
      children: (
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton
                  color="light"
                  onClick={() => {
                    hide();
                  }}
                >
                  <IonIcon
                    slot="icon-only"
                    icon={close}
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="store-info-modal-title">Store Information</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="store-info-content">
            <StoreInfo location={location} />
          </IonContent>
        </>
      ),
    });
  }

  function getOtherOrderAheadChannelLink(): { url: string; text: JSX.Element | string } | null {
    const otherOrderAheadChannelUrl = location.orderAheadChannelUrls?.find(({ orderChannel }) => {
      return orderChannel !== location.orderChannel;
    });
    if (!otherOrderAheadChannelUrl) {
      return null;
    }
    switch (otherOrderAheadChannelUrl.orderChannel) {
      case OrderChannel.Catering:
        return {
          url: otherOrderAheadChannelUrl.url,
          text: str(Strings.SWITCH_TO_CATERING),
        };
      case OrderChannel.Web:
        return {
          url: otherOrderAheadChannelUrl.url,
          text: str(Strings.SWITCH_TO_WEB),
        };
    }
  }

  const { fulfillmentMethod, deliveryAddress } = cart;
  const isOrderAhead = OrderChannelHelper.isOrderAhead(location.orderChannel);
  const fullAddress = location.fullAddress;
  const addressStr = GcnFulfillmentHelper.addressToString(fullAddress);
  const otherOrderAheadChannelLink = getOtherOrderAheadChannelLink();

  return (
    <div className="fulfillment-info-container">
      {isOrderAhead ? (
        // Location name
        <IonButton
          className="location-name-button"
          fill="clear"
          size="small"
          onClick={() => {
            /**
             * This will only exist if we came from a landing page
             */
            if (window.referer) {
              window.location.href = decodeURIComponent(window.referer);
              return;
            }
            window.location.href = `https://${location.orgDomain}`;
          }}
        >
          <h3
            className="location-name"
            aria-level={1}
          >
            {location.name}
          </h3>
          <IonIcon
            icon={pencilSharp}
            color="medium"
            slot="end"
          />
        </IonButton>
      ) : null}
      {fulfillmentMethod && location.canChangeDiningOptions() ? (
        // Fulfillment method and time
        <FulfillmentMethodInfo
          fulfillmentMethod={fulfillmentMethod}
          pickupAtIso={cart.pickupAtIso}
          enableItemClick={true}
          {...(deliveryAddress && { deliveryAddress })}
          onEdit={() => {
            const openingSequenceManager = new GcnOpeningSequenceManager();
            openingSequenceManager.start();
          }}
        />
      ) : null}
      {isOrderAhead && fullAddress ? (
        <IonItem
          lines="none"
          className="location-address-container"
        >
          <IonLabel className="location-address">{addressStr}</IonLabel>
        </IonItem>
      ) : null}
      {fullAddress &&
      location.openingHoursByFulfillmentMethod?.length &&
      // show store info only on web or catering
      [OrderChannel.Web, OrderChannel.Catering].includes(location.orderChannel) ? (
        <IonButton
          className="fulfillment-secondary-link"
          color="medium"
          fill="clear"
          onClick={() => {
            showStoreInfoModal();
          }}
        >
          {str(Strings.STORE_INFORMATION)}
        </IonButton>
      ) : null}
      {otherOrderAheadChannelLink ? (
        <IonButton
          className="fulfillment-secondary-link"
          color="medium"
          fill="clear"
          onClick={() => {
            window.location.href = otherOrderAheadChannelLink.url;
          }}
        >
          {otherOrderAheadChannelLink.text}
        </IonButton>
      ) : null}
    </div>
  );
}

export default FulfillmentHeader;
